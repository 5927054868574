const initState = {
  //AuditPlan
  config: [],
  report_controlled: null,
  dropdownsData: null,
  audit_plan_table_initiator: true,
  audit_plan_table_verifier: true,
  audit_plan_table_approver: true,
  audit_plan_details: [],
  audit_plan_section_details: [],
  audit_plan_user_details: [],
  audit_plan_tableList: null,
  audit_plan_draftList: null,
  audit_plan_report_nos: null,
  audit_plan_report: null,
  audit_plan_filter: {},
  audit_plan_seq_c: [],
  audit_plan_seq_p: [],
  audit_plan_users_list: [],
  audit_plan_notification_details: [],
  audit_plan_activity_list: [],
  audit_plan_attachments: [],

  //Audit Schedule
  audit_plan_draftList: null,
  audit_schedule_tableList: null,
  auditplan_details: [],
  audit_details: [],
  auditPlan_details: [],
  auditor_details: [],
  changeSectionData: false,

  //Audit Filter
  planReq: 'audit_plan_report',
  logsReq: 'audit_logs',
};

const ammReducer = (state = initState, action) => {
  switch (action.type) {
    case 'CCM_CONFIG':
      return {
        ...state,
        config: action.data,
        report_controlled: action.data.report_controlled,
      };
    case 'SET_AMM_DROPDOWN_VALUES':
      return {
        ...state,
        dropdownsData: action.data,
      };
    case 'SET_AP_TABLE_INITIATOR':
      return {
        ...state,
        audit_plan_table_initiator: action.data,
      };
    case 'SET_AP_TABLE_VERIFIER':
      return {
        ...state,
        audit_plan_table_verifier: action.data,
      };
    case 'SET_AP_TABLE_APPROVER':
      return {
        ...state,
        audit_plan_table_approver: action.data,
      };
    case 'GET_AUDIT_PLAN_DETAILS':
      console.log(action.data);
      return {
        ...state,
        audit_plan_details: action.data?.['audit_details'],
        audit_plan_section_details: action.data?.['section_details'],
        audit_plan_user_details: action.data?.['user_details'],
        audit_plan_activity_list: action.data?.['activityDetails'],
        audit_plan_attachments: action.data?.['attachment_details'],
      };
    case 'GET_AUDIT_PLAN_TABLE_LIST':
      return {
        ...state,
        audit_plan_tableList: action.data,
      };
    case 'GET_AUDIT_PLAN_DRAFT_LIST':
      return {
        ...state,
        audit_plan_draftList: action.data,
      };
    case 'GET_AUDIT_PLAN_REPORT_AND_LOGS':
      return {
        ...state,
        audit_plan_report: action.audit_plan_report,
        audit_plan_report_nos: action.audit_plan_report_nos,
      };
    case 'GET_AUDIT_PLAN_REPORT_AND_LOGS_FILTER':
      return {
        ...state,
        audit_plan_filter: action.audit_plan_filter,
      };

    case 'AUDIT_PLAN_SEQUENCE_NO':
      return {
        ...state,
        audit_plan_filter: action.audit_plan_filter,
        audit_plan_seq_c: action.audit_plan_seq_c,
        audit_plan_seq_p: action.audit_plan_seq_p,
      };
    case 'AUDIT_PLAN_SUGGEST_USERS':
      return {
        ...state,
        audit_plan_users_list: action.data,
      };

    case 'AUDIT_PLAN_NOTIFICATION_DETAILS':
      return {
        ...state,
        audit_plan_notification_details: action.data,
      };

    // Audit Schedule
    case 'GET_SCHEDULE_TABLE_LIST':
      return {
        ...state,
        audit_schedule_tableList: action.data,
      };
    case 'GET_AUDIT_SCHEDULE_DETAILS':
      console.log(action.data);
      return {
        ...state,
        schedule_details: action.data?.['schedule_details'],
        audit_details: action.data?.['audit_details'],
        auditPlan_details: action.data?.['auditplan_details'],
      };
    case 'SET_AUDIT_SCHEDULE_AUDITOR_DETAILS':
      const { section_date } = action.data;
      return {
        ...state,
        auditor_details: state.auditor_details.map((auditor, index) => {
          if (auditor.sectionDate === action.data[0].section_date) {
            const updatedSectionData = auditor.Section_data
              ? [...auditor.Section_data, action.data[0]]
              : [action.data[0]];
            let a = 0;
            auditor.Section_data?.map((d, index) => {
              if (action.data[0].id === d.id) {
                d = action.data[0];
                a = 1;
                console.log('fff', d);
                return;
              }
            });
            if (a == 0) {
              return {
                ...auditor,
                Section_data: updatedSectionData,
                changeSectionData: !state.changeSectionData,
              };
            }
          }
          return auditor;
        }),
      };
    case 'SET_AUDIT_SECTION_DETAILS':
      return {
        ...state,
        auditor_details: [...state.auditor_details, action.data],
      };

    case 'SET_AUDIT_SCHEDULE_DELETE_AUDITOR_DETAILS':
      const sectionId = action.data;
      return {
        ...state,
        auditor_details: state.auditor_details.map((auditor, index) => {
          console.log(auditor);
          if (auditor.sectionDate == action.data[0].sectionDate) {
            console.log(action.data[0].sectionDate + ' ' + auditor.sectionDate);
            console.log(auditor.Section_data);
            // Filter out the section with the given sectionId
            const updatedSectionData = auditor.Section_data.filter(
              (section, index) => {
                console.log('FFFF', section.id, sectionId[0].id);
                return section.id !== sectionId[0].id;
              }
            );
            return {
              ...auditor,
              Section_data: updatedSectionData,
            };
          }
          return auditor;
        }),
      };

    //Audit Filter
    case 'SET_AMM_PLAN_TABLE_LIST':
      return {
        ...state,
        planReq: action.payload,
      };
    case 'SET_AMM_PLAN_TABLE_LIST_LOGS':
      return {
        ...state,
        logsReq: action.payload,
      };
    default:
      return state;
  }
};

export default ammReducer;
