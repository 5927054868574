import { MODULE } from '../../utils/constants';
import * as types from '../actions/implementationActions';

const initState = {
  data: null,
  excelData: [],
  totalCount: 0,
  etotalCount: 0,
  details: null,
  imp_mod_details: null,
  imp_details_error: false,
  imp_search: [],
  capa_monitor: [],
  capa_search: [],
  capa_retire: [],
};

const implementation = (state = initState, action) => {
  const module = localStorage.getItem('module');
  let key = null;

  switch (action.type) {
    case types.GET_IMPLEMENTATION_LIST:
      return {
        ...state,
        data: action.data,
        excelData: action.excelData,
        totalCount: action.data.length,
      };

    case 'GET_CAPA_MONITOR':
      return {
        ...state,
        capa_monitor: action.data,
      };
    case 'GET_CAPA_RETIRE':
      return {
        ...state,
        capa_retire: action.data,
      };
    case types.SET_CAPA_SEARCH_DATA:
      return {
        ...state,
        capa_search: action.data,
      };
    case types.IMPLEMENTATION_DETAILS:
      return {
        ...state,
        details: action.data,
      };
    case types.SET_RETIRE_DATA:
      return {
        ...state,
        details: action.data,
      };
    case types.UPDATE_LINK_CHANGE:
      return {
        ...state,
        details: {
          ...state.details,
          link_change_imp: state.details.link_change_imp.map((req) =>
            req.id === action.data.id ? { ...req, ...action.data } : req
          ),
        },
      };

    case types.DELETE_LINK_CHANGE:
      return {
        ...state,
        details: {
          ...state.details,
          link_change_imp: state.details.link_change_imp.filter(
            (req) => req.id !== action.id
          ),
        },
      };

    case types.IMP_MODAL_DETAILS:
      return {
        ...state,
        imp_mod_details: action.data,
      };

    case types.IMP_DETAILS_ERR:
      return {
        ...state,
        imp_details_error: action.error,
      };

    case types.IMP_SEARCH:
      return {
        ...state,
        imp_search: action.data,
      };

    case types.UPDATE_IMPLEMENTATION_TASK:
      return {
        ...state,
        details: {
          ...state.details,
          imp_tasks: state.details.imp_tasks.map((task) =>
            task.id === action.data.id ? { ...task, ...action.data } : task
          ),
        },
      };

    case types.DELETE_IMP_TASK:
      key =
        module === MODULE.dnc
          ? 'ca_action_items'
          : module === MODULE.rm
          ? 'rm_action_items'
          : 'imp_tasks';
      return {
        ...state,
        details: {
          ...state.details,
          [key]: state.details[key].filter((task) => task.id !== action.id),
        },
      };

    default:
      return state;
  }
};

export default implementation;
