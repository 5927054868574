import React, { useState, useEffect } from 'react';
import { Tooltip, Card, Tag, Input } from 'antd';
import TextEditor from '../../../icontrolLogin/UtilityComponents/TextEditor';
import Attachment from './Attachment';
import { withRouter } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { UpdateQuestion } from '../../../store/actions/vmm/vendorEvaluation';
const TextEntry = (props) => {
  const dispatch = useDispatch();

  const [editorData, setEditorData] = useState('');
  const [initialValue, setInitialValue] = useState('');

  const handleChange = (data) => {
    setEditorData(data);
    handleEditorChange('answer', data);
  };
  useEffect(() => {
    setEditorData(props.activeQuestion.answer.answer || '');
  }, [props.activeQuestionNo]);

  const handleEditorChange = (value_type, value) => {
    dispatch(
      UpdateQuestion(
        props.activeSectionNo,
        props.activeQuestionNo,
        value_type,
        value
      )
    );
  };
  return (
    <div className="p-3 row multipleChoice">
      <div className="col-12">
        <Card>
          <div
            className="font-weight-bold h6 py-1 mr-1"
            style={{ color: '#333', whiteSpace: 'nowrap' }}
          >
            {props.question_no}
          </div>
          {props.question_name}
        </Card>
      </div>
      <div className="col-12 mt-4">
        <Card>
          <div className="w-100">
            <TextEditor
              handleChange={handleChange}
              value={editorData}
              initialValue={initialValue}
            />
          </div>
        </Card>
      </div>
      {props.comments && (
        <div className="col-12 d-flex mt-4">
          <Input.TextArea
            placeholder="Enter Your Comments (Non Mandatory)"
            autoSize={{ minRows: 4, maxRows: 6 }}
          />
        </div>
      )}

      {props.attachment && (
        <div className="col-12 mt-4">
          <Attachment {...props} />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ QPRReducer }) => ({
  activeSection: QPRReducer.activeSection,
  activeQuestion: QPRReducer.activeQuestion,
  activeQuestionNo: QPRReducer.activeQuestionNo,
  activeSectionNo: QPRReducer.activeSectionNo,
});

export default connect(mapStateToProps)(withRouter(TextEntry));
