import moment from 'moment';
import {
  FileImageOutlined,
  FilePdfOutlined,
  FileTextOutlined,
  FileExcelOutlined,
  FilePptOutlined,
  FileWordOutlined,
  FileUnknownOutlined,
} from '@ant-design/icons';

export const year_type_dict = {
  'Calendar Year (January - December)': 0,
  'Financial Year (April - March)': 1,
};

export const audit_plan_head = (plan_start_year, year_value, audit_plan_no) => {
  const year_type = parseInt(moment(year_value).format('YYYY'));
  if (year_type_dict[plan_start_year] === 0) {
    return `Audit Plan Calendar Year (January ${year_type} - December ${year_type}) (${audit_plan_no})`;
  } else if (year_type_dict[plan_start_year] === 1) {
    return `Audit Plan Financial Year (April ${year_type} - March ${
      year_type + 1
    }) (${audit_plan_no})`;
  }
};

export const audit_plan_body = (plan_start_year, year_value) => {
  const year_type = parseInt(moment(year_value).format('YYYY'));
  if (year_type_dict[plan_start_year] === 0) {
    return `Audit Plan Calendar Year (January ${year_type} - December ${year_type})`;
  } else if (year_type_dict[plan_start_year] === 1) {
    return `Audit Plan Financial Year (April ${year_type} - March ${
      year_type + 1
    })`;
  }
};

export const audit_plan_Type = (plan_start_year, year_value) => {
  const year_type = parseInt(moment(year_value).format('YYYY'));

  if (year_type_dict[plan_start_year] === 0) {
    return `Calendar Year (January ${year_type} - December ${year_type})`;
  } else if (year_type_dict[plan_start_year] === 1) {
    return `Financial Year (April ${year_type} - March ${year_type + 1})`;
  }
};

export const AuditFrequencyVariables = (type, year_value, freq_type) => {
  const year_type = parseInt(moment(year_value).format('YYYY'));

  const val = year_type_dict[type];
  if (freq_type === 'Monthly') {
    return [
      `January ${year_type + val}`,
      `February ${year_type + val}`,
      `March ${year_type + val}`,
      `April ${year_type}`,
      `May ${year_type}`,
      `June ${year_type}`,
      `July ${year_type}`,
      `August ${year_type}`,
      `September ${year_type}`,
      `October ${year_type}`,
      `November ${year_type}`,
      `December ${year_type}`,
    ];
  } else if (freq_type === 'Half-yearly') {
    if (val === 0) {
      return [
        `January ${year_type} - June ${year_type}`,
        `July ${year_type} - December ${year_type}`,
      ];
    } else {
      return [
        `April ${year_type} - September ${year_type}`,
        `October ${year_type} - March ${year_type + 1}`,
      ];
    }
  } else if (freq_type === 'Quarterly') {
    if (val === 0) {
      return [
        `April ${year_type} - June ${year_type}`,
        `July ${year_type} - September ${year_type}`,
        `October ${year_type} - December ${year_type}`,
        `January ${year_type} - March ${year_type}`,
      ];
    } else {
      return [
        `April ${year_type} - June ${year_type}`,
        `July ${year_type} - September ${year_type}`,
        `October ${year_type} - December ${year_type}`,
        `January ${year_type + 1} - March ${year_type + 1}`,
      ];
    }
  } else if (freq_type === 'Annual') {
    if (val === 0) {
      return [`January ${year_type} - December ${year_type}`];
    } else {
      return [`April ${year_type} - March ${year_type + 1}`];
    }
  }
};

export const roleRoute = {
  amm_initiator: 'initiation',
  amm_auditor:'auditor',
  amm_verifier: 'verification',
  amm_approver: 'approval',
};

export const OrderedChartData = (data) => {
  const sections = data?.map((items, index) => {
    const sectionData = {
      key: index + 1,
      head: (
        // <div className="d-flex flex-column">
        <span className="font-weight-bold"> {items.section_name}</span>
        // <span> {items.section_name}</span>
        // </div>
      ),
      section: true,
    };
    const formattedData = [];
    const childrenData = [];
    items.audits.map((audit, index) => {
      const { planned_dates, ...auditDetails } = audit;
      const auditData = {
        key: index,
        head: audit.audit_name,

        section: false,
      };
      planned_dates.map((plannedDate, index) => {
        const formattedDate = {
          ...plannedDate,
          ...auditDetails,
        };
        auditData[plannedDate.month] = formattedDate;
        formattedData.push(formattedDate);
      });
      childrenData.push(auditData);
    });
    const organizedData = formattedData.reduce((acc, item) => {
      const monthKey = item.month;

      if (!acc[monthKey]) {
        acc[monthKey] = [];
      }

      acc[monthKey].push(item);

      return acc;
    }, {});
    sectionData['children'] = childrenData;
    return { ...sectionData, ...organizedData };
  });
  console.log(sections);
  return sections;
  // {
  //   key: 1,
  //   head: 'John Brown sr.',
  //   section: true,
  //   Jan: [
  //     { audit_plan_no: 'ICOM-AUD-4', plan_date: '14-Jan-2024' },
  //     { audit_plan_no: 'ICOM-AUD-5', plan_date: '15-Jan-2024' },
  //     { audit_plan_no: 'ICOM-AUD-6', plan_date: '16-Jan-2024' },
  //     { audit_plan_no: 'ICOM-AUD-7', plan_date: '17-Jan-2024' },
  //   ],
  //   Feb: [
  //     { audit_plan_no: 'ICOM-AUD-4', plan_date: '14-Jan-2024' },
  //     { audit_plan_no: 'ICOM-AUD-5', plan_date: '15-Jan-2024' },
  //     { audit_plan_no: 'ICOM-AUD-6', plan_date: '16-Jan-2024' },
  //     { audit_plan_no: 'ICOM-AUD-7', plan_date: '17-Jan-2024' },
  //   ],
  //   children: [
  //     {
  //       key: 3,
  //       head: 'John Brown sr. sub',
  //       section: false,
  //       Jan: [{ audit_plan_no: 'ICOM-AUD-4', plan_date: '14-Jan-2024' }],
  //     },
  //   ],
  // },
};

export const OrderedTableData = (data) => {
  const masterData = [];
  const sections = data?.map((items, index) => {
    const data = {
      section_id: items.section_id,
      section_name: items.section_name,
    };
    masterData.push(data);
    masterData.push(...items.audits);
  });
  return masterData;
};

export const AuditPlanHistoryData = (item) => {
  const status = (data) => {
    const status = [
      'Pending for Initiation',
      'Pending for Verification',
      'Pending for Approval',
      'Pending for Second Level Approval',
    ];
    const value = status.indexOf(item.auditplan_status);
    if (value > data) {
      return 'Completed';
    } else if (
      item.routed_status == 'True' &&
      item.second_approver_Status == 'True' &&
      data == 2
    ) {
      return 'Completed';
    } else if (value <= data) {
      return 'Pending';
    }
  };
  const dataSource = [
    {
      label: 'Audit Plan Initiation',
      start_date: item.initiation_date,
      end_date: item.initiation_end_date,
      assignee: item.initiator_assignee || 'N/A',

      designation: item.initiator_designation || 'N/A',
      role: 'Initiator',
      status: status(0),
    },
    {
      label: 'Audit Plan Verification',
      start_date: item.initiation_end_date,
      end_date: item.verification_end_date,
      assignee: item.verifier_assignee || 'N/A',
      designation: item.verifier_designation || 'N/A',
      role: 'Verifier',
      status: status(1),
    },
    {
      label: 'Audit Plan Approval',
      start_date: item.verification_end_date,
      end_date: item.approval_end_date,
      assignee: item.approver_assignee || 'N/A',
      name: item.approver_name || 'N/A',
      designation: item.approver_designation || 'N/A',
      role: 'Approver',
      status: status(2),
    },
  ];
  if (item.second_approver_Status == 'True') {
    dataSource.push({
      label: 'Audit Plan Second Level Approval',
      start_date: item.approval_end_date,
      end_date: item.second_approval_end_date,
      assignee: item.second_approver_assignee || 'N/A',
      designation: item.second_approver_designation || 'N/A',
      name: item.second_approver_name || 'N/A',
      role: 'Approver',
      status: status(3),
    });
  }

  return dataSource;
};

export const attachment_extension = (data) => {
  if (data == 'image/jpeg') {
    return 'JPEG';
  } else if (data == 'image/jpg') {
    return 'JPG';
  } else if (data == 'image/bmp') {
    return 'BMP';
  } else if (data == 'image/png') {
    return 'PNG';
  } else if (data == 'application/pdf') {
    return 'PDF';
  } else if (
    data ==
    'application/vnd.openxmlformats-officedocument.presentationml.presentation'
  ) {
    return 'PPTX';
  } else if (data == 'application/vnd.ms-powerpoint') {
    return 'PPT';
  } else if (data == 'application/vnd.ms-excel') {
    return 'XLS';
  } else if (
    data == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  ) {
    return 'XLSX';
  } else if (data == 'application/msword') {
    return 'DOC';
  } else if (
    data ==
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ) {
    return 'DOCX';
  } else if (data == 'text/plain') {
    return 'TXT';
  } else {
    return 'Invalid Format';
  }
};

export const attachmentFileSize = (size) => {
  const maxSizeKB = 1024; // 1 MB in KB
  const fileSizeKB = size / 1024;
  if (fileSizeKB <= maxSizeKB) {
    return `${fileSizeKB.toFixed(2)} KB`;
  } else {
    const fileSizeMB = fileSizeKB / 1024;
    return `${fileSizeMB.toFixed(2)} MB`;
  }
};

export const attachmentAuditPlanStage = (role, second_approver) => {
  if (role == 'amm_initiator') {
    return 'Audit Plan Initiation';
  } 
  else if (role == 'amm_auditor') {
    return 'Audit Schedule Initiation';
  }
   else if (role == 'amm_verifier') {
    return 'Audit Plan Verification';
  } else if (role == 'amm_approver' && second_approver == 'True') {
    return 'Audit Plan Second Level Approval';
  } else if (role == 'Audit Plan Approval') {
    return 'JPG';
  }
};

export const getAttachmentFileIcon = (fileType) => {
  const img = ['image/jpg', 'image/jpeg', 'image/png', 'image/bmp'];
  if (img.includes(fileType)) {
    return <FileImageOutlined style={{ fontSize: '30px', color: '#9b59b6' }} />;
  }

  if (fileType === 'application/pdf') {
    return <FilePdfOutlined style={{ fontSize: '30px', color: '#ef5350' }} />;
  }

  if (
    fileType ===
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
    fileType === 'application/msword'
  ) {
    return <FileWordOutlined style={{ fontSize: '30px', color: '#3498db' }} />;
  }

  if (
    fileType ===
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
    fileType === 'application/vnd.ms-excel'
  ) {
    return <FileExcelOutlined style={{ fontSize: '30px', color: '#2ecc71' }} />;
  }

  if (
    fileType ===
      'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
    fileType === 'application/vnd.ms-powerpoint'
  ) {
    return <FilePptOutlined style={{ fontSize: '30px', color: '#ED6C47' }} />;
  }
  if (fileType === 'text/plain') {
    return <FileTextOutlined style={{ fontSize: '30px', color: '#A7C7E7' }} />;
  }
  return <FileUnknownOutlined style={{ fontSize: '35px', color: '#f1c40f' }} />;
};

export const getSequenceName = (ind, len) => {
  let data = [];
  if (len == 1) {
    data = ['AUD-ANL'];
  } else if (len == 2) {
    data = ['AUD-HLF1', 'AUD-HLF2'];
  } else if (len == 4) {
    data = ['AUD-QTR1', 'AUD-QTR2', 'AUD-QTR3', 'AUD-QTR4'];
  } else if (len == 12) {
    data = [
      'AUD-JAN',
      'AUD-FEB',
      'AUD-MAR',
      'AUD-APR',
      'AUD-MAY',
      'AUD-JUN',
      'AUD-JUL',
      'AUD-AUG',
      'AUD-SEP',
      'AUD-OCT',
      'AUD-NOV',
      'AUD-DEC',
    ];
  }
  return data[ind];
};
