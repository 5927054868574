import React, { useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Input,
  Modal,
  Button,
  Tooltip,
  Upload,
  Space,
  Select,
  message,
  Alert,
} from 'antd';
import {
  UploadOutlined,
  FileImageOutlined,
  FilePdfOutlined,
  FileTextOutlined,
  FileExcelOutlined,
  FileUnknownOutlined,
} from '@ant-design/icons';
import { db } from './db';
import { attachmentFileSize } from '../../../amm/components/UtilityComponents/AmmConstants';
import { Formik } from 'formik';
import Btn from '../UtilityComponents/Btn';
import { connect, useDispatch } from 'react-redux';
import { UpdateQuestion } from '../../../store/actions/vmm/vendorEvaluation';
import AttachmentList from '../UtilityComponents/AttachmentsList';
const Attachment = (props) => {
  const dispatch = useDispatch();
  const formRef = useRef();
  const [show, setShow] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [file, setFile] = useState({
    name: '',
    size: '',
    type: '',
  });

  const initialState = {
    file_name: '',
    classification: 'Others',
    attachment_comments: '',
  };

  const getFileIcon = (fileType) => {
    const img = ['image/jpg', 'image/jpeg', 'image/png'];
    if (img.includes(fileType)) {
      return (
        <FileImageOutlined style={{ fontSize: '40px', color: '#9b59b6' }} />
      );
    }

    if (fileType === 'application/pdf') {
      return <FilePdfOutlined style={{ fontSize: '40px', color: '#ef5350' }} />;
    }

    if (
      fileType ===
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ) {
      return (
        <FileTextOutlined style={{ fontSize: '40px', color: '#3498db' }} />
      );
    }

    if (
      fileType ===
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      return (
        <FileExcelOutlined style={{ fontSize: '40px', color: '#2ecc71' }} />
      );
    }

    return (
      <FileUnknownOutlined style={{ fontSize: '40px', color: '#f1c40f' }} />
    );
  };

  const hideModal = () => {
    setShow(false);
  };
  const handleUploadChange = ({ file, fileList }) => {
    if (file.status === 'uploading') {
      setFile({
        name: file.name,
        size: file.size,
        type: file.type,
      });
    } else if (file.status === 'done') {
      setFile(null);
      setFileList(fileList);
    } else if (file.status === 'removed') {
      setFileList(fileList);
    }
  };

  const beforeUpload = (file) => {
    const supportedTypes = [
      'image/jpeg',
      'image/png',
      'application/pdf',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    ];
    const isSupported = supportedTypes.includes(file.type);
    if (!isSupported) {
      message.error(
        'You can only upload .jpg, .png, .jpeg, .pdf, .docx, .xlsx, .pptx file!'
      );
      return false;
    }
    const isLimit = file.size / 1024 / 1024 < 10;
    if (!isLimit) {
      message.error('File must smaller than 10MB!');
      return false;
    }
    setShow(true);
    setFile(file);
    return false;
  };
  return (
    <Formik
      innerRef={formRef}
      initialValues={{ ...(props.record || initialState) }}
      // validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        values.file = file;
        values.uid = file.uid;
        values.filetype = file.type;
        values.size = attachmentFileSize(file.size);
        values.status = 'Pending';
        values.vendor_no = 'ICOMVDR';
        values.version = '01';
        values.section_no = props.activeSection;
        values.question_no = props.activeQuestion;
        values.organization = props.organization;
        values.file_name = values.file_name
          ? values.file_name
          : file?.name?.split('.')[0];
        dispatch(
          UpdateQuestion(
            props.activeSectionNo,
            props.activeQuestionNo,
            'attachments',
            [...props.activeQuestion.answer.attachments, values]
          )
        );
        db.Attachments.add(values);
        setShow(false);
        resetForm();
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }) => (
        <>
          <Space
            direction="vertical"
            style={{
              width: '100%',
            }}
            size="large"
          >
            <Upload
              name="attachment"
              fileList={fileList}
              showUploadList={false}
              accept=".jpeg, .png, .pdf, .docx, .xlsx, .pptx, .ppt"
              onChange={handleUploadChange}
              beforeUpload={beforeUpload}
            >
              <Button icon={<UploadOutlined />}>Upload Attachment</Button>
            </Upload>
          </Space>
          <Modal
            width={600}
            top
            title="Upload Document"
            visible={show}
            className="my-3"
            centered={true}
            onCancel={hideModal}
            destroyOnClose={true}
            maskClosable={false}
            bodyStyle={{
              padding: '0px',
              // overflowY: 'auto', maxHeight: '80vh'
            }}
            footer={[
              <Btn
                buttonText="Cancel"
                primary={false}
                secondary={true}
                clickHandler={hideModal}
                className="mr-1"
                key="Cancel"
              />,
              props.action_type === 'update' ? (
                <Btn
                  buttonText="Update Document"
                  primary={true}
                  secondary={false}
                  clickHandler={handleSubmit}
                  // loading={loading}
                  // disabled={loading}
                  className="mr-1"
                  key="Add"
                />
              ) : (
                <Btn
                  buttonText="Add Document"
                  primary={true}
                  secondary={false}
                  clickHandler={handleSubmit}
                  className="mr-1"
                  key="Add"
                  // loading={loading}
                  // disabled={loading}
                />
              ),
            ]}
          >
            <div className="row   initiation-form-container m-0 mb-4">
              <div className="col-12 d-flex my-3 p-3 bg-light">
                <div>{getFileIcon(file.type)}</div>
                <div className="mx-3">
                  <div>{file.name}</div>
                  <em>{attachmentFileSize(file.size)}</em>
                </div>
              </div>
              <div className="col-6">
                <div className="input-container">
                  <Tooltip title="File Name">
                    <Input
                      name="file_name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.file_name}
                      placeholder="Enter File Name"
                      size="large"
                    />
                  </Tooltip>
                  {errors.file_name && touched.file_name && (
                    <small className="form-err">Email Address Required</small>
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="input-container">
                  <Tooltip title="Attachment Classification">
                    <Select
                      name="classification"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.classification}
                      className="input-select"
                    >
                      <Select.Option value={'Others'}>Others</Select.Option>
                    </Select>
                  </Tooltip>
                  {errors.classification && touched.classification && (
                    <small className="form-err">Classification Required</small>
                  )}
                </div>
              </div>

              <div className="col-12 mb-4">
                <div className="input-container">
                  <Tooltip title="Attachment Comments">
                    <Input.TextArea
                      name="attachment_comments"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.attachment_comments}
                      autoSize={{ minRows: 3, maxRows: 5 }}
                      maxLength={150}
                      placeholder="Attachment Comments (Non Mandatory)"
                    />
                  </Tooltip>
                </div>
              </div>
            </div>
          </Modal>
          {props.activeQuestion?.answer?.attachments?.length > 0 && (
            <AttachmentList
              attachments={props.activeQuestion.answer.attachments}
            />
          )}
        </>
      )}
    </Formik>
  );
};
const mapStateToProps = ({ QPRReducer }) => ({
  activeSection: QPRReducer.activeSection,
  activeQuestion: QPRReducer.activeQuestion,
  activeQuestionNo: QPRReducer.activeQuestionNo,
  activeSectionNo: QPRReducer.activeSectionNo,
});

export default connect(mapStateToProps)(withRouter(Attachment));
