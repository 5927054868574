import React, { useState, useEffect, useRef } from 'react';
import { Card, Table, Modal, Input } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Upload } from 'antd';
import Attachment from './Attachment';
import RenderSubQuestion from './RenderSubQuestion';
import Btn from '../UtilityComponents/Btn';
import { withRouter } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { UpdateQuestion } from '../../../store/actions/vmm/vendorEvaluation';
import { Formik } from 'formik';
import * as Yup from 'yup';

const MultipleInput = (props) => {
  const dispatch = useDispatch();
  const formRef = useRef();

  const [columns, setColumns] = useState([]);
  const [show, setShow] = useState(false);
  useEffect(() => {
    const data = props.sub_questions
      .filter((item) => item.exclude !== true)
      .map((item, index) => ({
        title: item.question_name,
        dataIndex: item.question_name?.toLowerCase()?.replace(/ /g, '_'),
        key: item.question_name,
      }));
    setColumns(data);
  }, [props.sub_questions]);
  const hideModal = () => {
    setShow(false);
  };

  const handleChanges = () => {
    formRef.current.handleSubmit();
  };
  const [initialState, setInitialState] = useState(null);
  const [validationSchema, setValidationSchema] = useState(null);

  useEffect(() => {
    const data = {};
    const validData = {};
    props.sub_questions.forEach((item) => {
      data[item.question_name?.toLowerCase()?.replace(/ /g, '_')] = '';
      validData[item.question_name?.toLowerCase()?.replace(/ /g, '_')] =
        Yup.string().required();
    });
    setInitialState(data); // Setting the initialState with the data object
    setValidationSchema(Yup.object().shape(validData));
  }, [props.sub_questions]);

  return props.subQuestion ? (
    <>
      <div className="d-flex justify-content-end mb-3">
        <Button
          onClick={() => {
            setShow(true);
          }}
          type="primary"
          icon={<PlusCircleOutlined />}
        >
          Add Data
        </Button>
      </div>
      <div className="table-card-audit">
        <Table bordered columns={columns} />
      </div>
    </>
  ) : (
    <div className="p-3 row multipleChoice">
      <div className="col-12">
        <Card>
          <div
            className="font-weight-bold h6 py-1 mr-1"
            style={{ color: '#333', whiteSpace: 'nowrap' }}
          >
            {props.question_no}
          </div>
          {props.question_name}
        </Card>
      </div>
      <div className="col-12">
        <Card className="mt-3">
          <div className="d-flex justify-content-between mb-3">
            <div>
              Total No of Record : {props.activeQuestion?.answer?.answer || '0'}
            </div>
            <Button
              onClick={() => {
                setShow(true);
              }}
              type="primary"
              icon={<PlusCircleOutlined />}
            >
              Add Data
            </Button>
          </div>
          <div className="table-card-audit">
            <Table
              bordered
              columns={columns}
              data={props.activeQuestion?.answer?.answer || []}
            />
          </div>
        </Card>
      </div>

      {props.comments && (
        <div className="col-12 d-flex mt-4">
          <Input.TextArea
            placeholder="Enter Your Comments (Non Mandatory)"
            autoSize={{ minRows: 4, maxRows: 6 }}
          />
        </div>
      )}
      {props.attachment && (
        <div className="col-12 mt-4">
          <Attachment {...props} />
        </div>
      )}

      <Modal
        width={900}
        top
        title="Add Record"
        visible={show}
        className="my-3"
        centered={true}
        onCancel={hideModal}
        destroyOnClose={true}
        maskClosable={false}
        bodyStyle={{
          padding: '0px',
          minHeight: 200,
          maxHeight: 700,
        }}
        footer={[
          <Btn
            buttonText="Cancel"
            primary={false}
            secondary={true}
            clickHandler={hideModal}
            className="mr-1"
            key="Cancel"
          />,
          props.action_type === 'update' ? (
            <Btn
              buttonText="Update"
              primary={true}
              secondary={false}
              className="mr-1"
              key="Add"
            />
          ) : (
            <Btn
              buttonText="Submit"
              primary={true}
              secondary={false}
              clickHandler={handleChanges}
              className="mr-1"
              key="Add"
            />
          ),
        ]}
      >
        <Formik
          innerRef={formRef}
          initialValues={initialState}
          // validationSchema={validationSchema}
          onSubmit={(values) => {
            console.log(props.activeSectionNo, props.activeQuestionNo, values);
            const data = [values];
            console.log(data);

            console.log(
              props.activeSectionNo,
              props.activeQuestionNo,
              'answer',
              data
            );
            dispatch(
              UpdateQuestion(
                props.activeSectionNo,
                props.activeQuestionNo,
                'answer',
                data
              )
            );
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <div className="row px-4 py-3">
              {props.sub_questions.map((item, index) => (
                <RenderSubQuestion
                  {...item}
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  handleSubmit={handleSubmit}
                  setFieldValue={setFieldValue}
                />
              ))}
            </div>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

const mapStateToProps = ({ QPRReducer }) => ({
  activeSection: QPRReducer.activeSection,
  activeQuestion: QPRReducer.activeQuestion,
  activeQuestionNo: QPRReducer.activeQuestionNo,
  activeSectionNo: QPRReducer.activeSectionNo,
});

export default connect(mapStateToProps)(withRouter(MultipleInput));
