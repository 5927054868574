import { fetch, iaccessHttps } from '../../utils/config';
import { MODULE, role_alias, REDIRECT_MODULE } from '../../utils/constants';
import { message } from 'antd';
import {
  getDropdownValues,
  getMtfDropdownValues,
  getNcoDropdownValues,
} from './initiationActions';
import { getAmmDropdownValues } from './amm/ammActions';
import { getVmmDropdownValues } from './vmm/vmmActions';
import { CAPAConfig } from './commonActions';
import { CCMConfig } from './ccm/CCMActions';
import axios from 'axios';
import moment from 'moment';
import { setConfigurations, db } from '../../icontrolLogin/iControlDB';
import CryptoJS from 'crypto-js';
import { jwtDecode } from 'jwt-decode';

export const SET_USER_PROFILE = 'SET_USER_PROFILE';
export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_IMAGE_DATA = 'SET_IMAGE_DATA';
export const TOGGLE_BUTTON_LOADER = 'TOGGLE_BUTTON_LOADER';

export const auth = (formbody, browserHistory) => async (dispatch) => {
  dispatch({ type: TOGGLE_BUTTON_LOADER, loading: true });
  const loadingMessage = message.loading('Loading....', 0); // duration 0 means it will persist until manually closed
  try {
    const response = await fetch.post(`/user_login/`, formbody);
    const { data } = response;
    await dispatch({ type: TOGGLE_BUTTON_LOADER, loading: false });
    await localStorage.setItem('name', data.name);
    await localStorage.setItem('username', data.username);
    await localStorage.setItem('email', data.email);
    await localStorage.setItem('role', data.role.toLowerCase());
    await localStorage.setItem('application', data.application);
    await localStorage.setItem('roles', JSON.stringify(data.roles));
    await localStorage.setItem('organization', data.organization);
    await localStorage.setItem('module', data.module);
    await localStorage.setItem('access_token', data.access);
    await localStorage.setItem('refresh_token', data.refresh);
    await localStorage.setItem('designation', data.designation);
    await localStorage.setItem('loginTimestamp', moment().unix());
    await localStorage.setItem('mobile', data.mobile);
    await dispatch(getProfile());
    await loadingMessage();
    message.success('Login Successful..');
    browserHistory.push(`${REDIRECT_MODULE[data.module]}`);
    await dispatch(
      setConfigurations(
        data.username,
        data.module,
        data.organization,
        data.application,
        false
      )
    );
    await dispatch(getAllAssignees(data.module, data.organization));
  } catch (err) {
    await loadingMessage();
    message.error('Invalid Password');
    dispatch({ type: TOGGLE_BUTTON_LOADER, loading: false });
  }
};

export const authLogin = (formbody, browserHistory) => async (dispatch) => {
  try {
    const response = await fetch.post(`/refresh/`, formbody);
    const { data } = response;
    const data2 = jwtDecode(data.access);
    await localStorage.setItem('access_token', data.access);
    await localStorage.setItem('refresh_token', data.refresh);
    await localStorage.setItem('name', data2.name);
    await localStorage.setItem('username', data2.username);
    await localStorage.setItem('email', data2.email);
    await localStorage.setItem('role', data2.role.toLowerCase());
    await localStorage.setItem('application', data2.application);
    await localStorage.setItem('roles', JSON.stringify(data2.roles));
    await localStorage.setItem('organization', data2.organization);
    await localStorage.setItem('module', data2.module);
    await localStorage.setItem('designation', data2.designation);
    await localStorage.setItem('loginTimestamp', moment().unix());
    await localStorage.setItem('mobile', data2.contact_no);
    await dispatch(getProfile());
    message.success('Login Successful..');
    browserHistory.push(`${REDIRECT_MODULE[data2.module]}`);
    await dispatch(
      setConfigurations(
        data2.username,
        data2.module,
        data2.organization,
        data2.application,
        false
      )
    );
    await dispatch(getAllAssignees(data2.module, data2.organization));
  } catch (err) {
    window.location.replace(process.env.REACT_APP_IACCESS_URL);
  }
};
export const switchApp = (formbody, browserHistory) => async (dispatch) => {
  try {
    const response = await fetch.post(`/api/ApplicationLogin/`, formbody);
    const { data } = response;
    if (data.application != 'iControl') {
      window.location.replace(data.url);
    }
    const data2 = jwtDecode(data.access);
    await localStorage.setItem('access_token', data.access);
    await localStorage.setItem('refresh_token', data.refresh);
    await localStorage.setItem('name', data2.name);
    await localStorage.setItem('username', data2.username);
    await localStorage.setItem('email', data2.email);
    await localStorage.setItem('role', data2.role.toLowerCase());
    await localStorage.setItem('application', data2.application);
    await localStorage.setItem('roles', JSON.stringify(data2.roles));
    await localStorage.setItem('organization', data2.organization);
    await localStorage.setItem('module', data2.module);
    await localStorage.setItem('designation', data2.designation);
    await localStorage.setItem('loginTimestamp', moment().unix());
    await localStorage.setItem('mobile', data2.contact_no);
    await dispatch(getProfile());
    message.success('Login Successful..');
    browserHistory.push(`${REDIRECT_MODULE[data2.module]}`);
    await dispatch(
      setConfigurations(
        data2.username,
        data2.module,
        data2.organization,
        data2.application,
        false
      )
    );
    await dispatch(getAllAssignees(data2.module, data2.organization));
  } catch (err) {
    // window.location.replace(process.env.REACT_APP_IACCESS_URL);
  }
};
export const switch_application =
  (formbody, browserHistory) => async (dispatch) => {
    try {
      const response = await fetch.post(`/api/iAccessLogin/`, formbody);
      const { data } = response;
      const data2 = jwtDecode(data.access);
      await localStorage.setItem('access_token', data.refresh.access);
      await localStorage.setItem('refresh_token', data.refresh);
      await localStorage.setItem('name', data2.name);
      await localStorage.setItem('username', data2.username);
      await localStorage.setItem('email', data2.email);
      await localStorage.setItem('role', data2.role.toLowerCase());
      await localStorage.setItem('application', data2.application);
      await localStorage.setItem('roles', JSON.stringify(data2.roles));
      await localStorage.setItem('organization', data2.organization);
      await localStorage.setItem('module', data2.module);
      await localStorage.setItem('designation', data2.designation);
      await localStorage.setItem('loginTimestamp', moment().unix());
      await localStorage.setItem('mobile', data2.contact_no);
      await dispatch(getProfile());
      message.success('Login Successful..');
      browserHistory.push(`${REDIRECT_MODULE[data2.module]}`);
      await dispatch(
        setConfigurations(
          data2.username,
          data2.module,
          data2.organization,
          data2.application,
          false
        )
      );
      await dispatch(getAllAssignees(data2.module, data2.organization));
    } catch (err) {
      window.location.replace(process.env.REACT_APP_IACCESS_URL);
    }
  };

export const getProfile = () => (dispatch) => {
  fetch
    .get('/user_profile/')
    .then(({ data }) => {
      console.log(data.role.toLowerCase());
      dispatch({
        type: SET_USER_PROFILE,
        data: {
          ...data,
          role: role_alias[data.role.toLowerCase()],
          is_auth: true,
        },
      });
      dispatch(
        setConfigurations(
          data.username,
          data.module,
          data.organization,
          data.application,
          true
        )
      );
      if (data.module === MODULE.mtf) {
        dispatch(getMtfDropdownValues());
      } else if (data.module === MODULE.nco) {
        dispatch(getNcoDropdownValues());
        dispatch(CAPAConfig());
      } else if (data.module === MODULE.cmm) {
        dispatch(getDropdownValues());
        dispatch(CCMConfig());
      } else if (data.module === MODULE.amm) {
        dispatch(getAmmDropdownValues());
        dispatch(CCMConfig());
      } else if (data.module === MODULE.vmm) {
        dispatch(getVmmDropdownValues());
        dispatch(CCMConfig());
      } else {
        dispatch(getDropdownValues());
        dispatch(CCMConfig());
      }

      if (!sessionStorage.getItem('image')) {
        dispatch(getOrganizationImage());
      } else {
        dispatch({
          type: SET_IMAGE_DATA,
          data: JSON.parse(sessionStorage.getItem('image')),
        });
      }
    })
    .catch(({ response }) => {
      if (
        (response && response.statusText === 'Unauthorized') ||
        response.data.code === 'token_not_valid'
      ) {
        dispatch(logout());
      }
    });
};

export const signature = (formbody, callback) => async () => {
  message.loading({ content: 'Loading...', key: 'updatable', duration: 60 });
  delete formbody.role;
  await fetch
    .post('/authenticate/', formbody)
    .then((res) => {
      if (res.data.authenticated) {
        message.success({ content: 'Signature Authorized!', key: 'updatable' });
        callback();
      } else {
        message.error({
          content: 'Invalid Password!',
          key: 'updatable',
        });
      }
    })
    .catch((err) => {
      message.error({ content: 'Signature Not Authorized!', key: 'updatable' });
    });
};

export const logout = () => (dispatch) => {
  iaccessHttps
    .post(`/logOutApp/`, {
      refresh: localStorage.getItem('refresh_token'),
    })
    .then(() => {
      const keyToKeep = 'appVersion'; // Replace with the key you want to keep
      Object.keys(localStorage).forEach(
        (key) => key !== keyToKeep && localStorage.removeItem(key)
      );
      sessionStorage.clear();
      window.location.replace(process.env.REACT_APP_IACCESS_URL);
    })
    .catch((err) => {
      message.error('Invalid Password');
      dispatch({ type: TOGGLE_BUTTON_LOADER, loading: false });
    });
};

export const NotificationLoginApi = (formBody, history) => (dispatch) => {
  axios
    .post(`${process.env.REACT_APP_IACCESS_URL}/NotificationLogin/`, formBody)
    .then(({ data }) => {
      const { access_token, module } = data;
      console.log(access_token, history);
      sessionStorage.setItem('access_token', access_token);
      if (module == 'Audit Management Module') {
        history.push('/Notification/AuditPlan');
      }
    })
    .catch((error) => {
      message.error('Invalid Password!');
    });
};

export const getUserData = () => (dispatch) => {
  return fetch
    .get('/user_data/')
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getVendorUserData = () => (dispatch) => {
  return fetch
    .get('/vendor_data/')
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getOrganizationImage = () => (dispatch) => {
  fetch
    .get('/organization_image/')
    .then(({ data }) => {
      sessionStorage.setItem('image', JSON.stringify(data));
      dispatch({
        type: SET_IMAGE_DATA,
        data: data,
      });
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getAllAssignees = (module, organization) => async (dispatch) => {
  fetch
    .post('/getAllAssignee/')
    .then(({ data }) => {
      const mapData = data.map((item) => {
        return {
          module: module,
          organization: organization,
          created_at: moment().toISOString(),
          role: item.assignee_role,
          assignee_name: item.full_name,
          designation: item.designation,
          project: item.project_name,
        };
      });
      console.log(mapData);
      db.Assignees.bulkPut(mapData);
    })
    .catch((error) => {
      console.log(error);
    });
};
