import React, { useState, useEffect } from 'react';
import '../../../styles/LayoutComponents/vendorEvaluation.scss';
import { withRouter } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import * as modalActions from '../../../store/actions/modalActions';
import {
  getQuestionPaperRender,
  getAnswers,
} from '../../../store/actions/QPCreator/QPRAction';
import { Button, Tabs, Drawer, Badge } from 'antd';
import Home from './Home';
import {
  HomeOutlined,
  AppstoreOutlined,
  BarsOutlined,
  FileSearchOutlined,
  StarOutlined,
  ClearOutlined,
  ArrowRightOutlined,
  ArrowLeftOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import VendorEvaluationGrid from './VendorEvaluationGrid';
import VendorEvaluationList from './VendorEvaluationList';
import RenderMultiLevelData from './RenderMultiLevelData';
import QuestionList from './QuestionList';
import { db } from './db';
import RenderQuestion from './RenderQuestion';
import {
  getVEQuestionChange,
  getVEQuestion,
  getGridListQuestion,
} from '../../../store/actions/vmm/vendorEvaluation';
const { TabPane } = Tabs;
const VendorEvaluationLayout = (props) => {
  console.log(props);
  const [qpData, setQPData] = useState(null);
  const dispatch = useDispatch();
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchQuestions = async () => {
      const dataList = await getGridListQuestion();
      if (dataList?.length > 0) {
        setData(dataList);
      }
    };
    fetchQuestions();
  }, []);

  useEffect(() => {
    props.getQuestionPaperRender({
      paperId: 'ICOM-VDR-PM-2024',
      version: '01',
    });
    sessionStorage.setItem('vendor_no', 'ICOM-VDR-081');
    sessionStorage.setItem('version_no', '01');
  }, []);

  useEffect(() => {
    dispatch(getAnswers());
  }, []);

  return (
    <div className="evaluation row">
      <div className="header col-12">
        <div className="bg-light h-100 d-flex justify-content-center align-items-center">
          <img
            src={
              'https://iaccess-s3.s3.amazonaws.com/iComply%20Lifescience%20Solutions/logo/iComply%20Logos%20copy%202%20%281%29.png'
            }
            height={55}
          />
        </div>
        <div className="headingBar">
          {props.paper_name || 'Vendor Evaluation Form'}
        </div>
        <div className="mx-3 d-flex align-items-center">
          <Badge count={'Due Date : 14-May-2024'} className="mx-4" />
          <Button>
            <LogoutOutlined /> Log Out
          </Button>
        </div>
      </div>
      <div className="col-9 section">
        <div
          className="sectionHead"
          style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}
        >
          <Button
            type={props.activeSectionNo == 0 ? 'primary' : 'text'}
            icon={<HomeOutlined />}
            onClick={() => {
              dispatch({
                type: 'RESET_ACTIVE_SECTION',
              });
            }}
          >
            Home
          </Button>
          {props.sections?.map((item, index) => (
            <Button
              type={
                props.activeSectionNo == item.section_id ? 'primary' : 'text'
              }
              onClick={() => {
                dispatch({
                  type: 'SET_ACTIVE_SECTION',
                  activeSection: item,
                  activeSectionNo: item.section_id,
                });
              }}
              key={index}
            >
              {item.section_name}
            </Button>
          ))}
        </div>
        {props.activeSectionNo == 0 && props.activeQuestionNo == null ? (
          <div className="sectionBody2 bg-light">
            <Home />
          </div>
        ) : props.activeSectionNo !== 0 && props.activeQuestionNo !== null ? (
          <div className="sectionBody bg-light">
            <RenderQuestion />
          </div>
        ) : (
          <div className="sectionBody2 bg-light">
            <QuestionList />
          </div>
        )}

        {props.activeSectionNo !== 0 && props.activeQuestionNo !== null && (
          <div className="sectionFooter">
            <div className="buttonSpace">
              <Button type="primary" icon={<StarOutlined />}>
                Bookmark
              </Button>
              <Button type="primary" icon={<ClearOutlined />}>
                Clear Response
              </Button>
            </div>
            <div className="buttonSpace">
              <Button
                onClick={() =>
                  dispatch(
                    getVEQuestionChange(
                      'Previous',
                      props.activeSectionNo,
                      props.activeQuestionNo
                    )
                  )
                }
                type="primary"
                icon={<ArrowLeftOutlined />}
              >
                Save & previous
              </Button>
              <Button
                type="primary"
                onClick={() =>
                  dispatch(
                    getVEQuestionChange(
                      'Next',
                      props.activeSectionNo,
                      props.activeQuestionNo
                    )
                  )
                }
              >
                Save & Next <ArrowRightOutlined />
              </Button>
            </div>
          </div>
        )}
      </div>
      <div className="col-3 tabHeader">
        <Tabs
          defaultActiveKey="1"
          size="large"
          tabPosition="top"
          className="w-100"
          centered
        >
          <TabPane
            key="1"
            tab={
              <div className="tabName">
                <AppstoreOutlined />
                Grid View
              </div>
            }
          >
            <VendorEvaluationGrid className="h-100" data={data} />
          </TabPane>
          <TabPane
            key="2"
            tab={
              <div className="tabName">
                <BarsOutlined />
                List View
              </div>
            }
          >
            <VendorEvaluationList data={data} />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

const mapStateToProps = ({ qp, QPRReducer }) => ({
  description: QPRReducer.description,
  paper_name: QPRReducer.paper_name,
  organization: QPRReducer.organization,
  paperId: QPRReducer.paperId,
  version: QPRReducer.version,
  sections: QPRReducer.sections,
  activeSection: QPRReducer.activeSection,
  activeQuestion: QPRReducer.activeQuestion,
  activeQuestionNo: QPRReducer.activeQuestionNo,
  activeSectionNo: QPRReducer.activeSectionNo,
});

const mapDispatchToProps = {
  getQuestionPaperRender,
  ...modalActions,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(VendorEvaluationLayout));
