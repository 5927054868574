import moment from 'moment';
import {
  dnc_imp_stage_alias,
  dnc_ini_stage_alias,
  MODULE,
  months,
  rm_imp_stage_alias,
  rm_ini_stage_alias,
} from './constants';
import { message } from 'antd';
import XLSX from 'xlsx';
import {
  ini_stage_alias,
  ini_assignee_alias,
  imp_stage_alias,
  imp_assignee_alias,
} from '../utils/constants';
import {
  FormOutlined,
  HomeOutlined,
  CheckCircleFilled,
  ClockCircleFilled,
  ExclamationCircleFilled,
  DownloadOutlined,
  PrinterOutlined,
  SearchOutlined,
  ExportOutlined,
  MoreOutlined,
} from '@ant-design/icons';
export function dateFormat(date) {
  let formatted_date =
    date.getDate() + '-' + months[date.getMonth()] + '-' + date.getFullYear();
  return formatted_date;
}

export function checkEmptyString(str) {
  if (str) return str;
  return 'N/A';
}

export function getSignature() {
  const username = localStorage.getItem('username');
  // const role = localStorage.getItem('role');
  // const key = sign_key[role];
  // const signature = {};
  // signature[key] = `${username} ${moment(new Date())
  //  .utc()
  //  .format('DD-MMM-YYYY hh:mm:ss')}`;

  return `${username} ${moment(new Date())
    .utc()
    .format('DD-MMM-YYYY hh:mm:ss')}`;
}

export function removeNull(obj) {
  const res = { ...obj };
  if (res !== null && res !== undefined) {
    Object.keys(res).forEach((key) => res[key] === null && delete res[key]);
    return res;
  }
}

export function replaceNullToStr(obj) {
  for (let key in obj) {
    if (obj[key] === null) obj[key] = '';
    if (typeof obj[key] === 'object') replaceNullToStr(obj[key]);
  }
  return obj;
}

export function stringToHslColor(str, s = 45, l = 55) {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  var h = hash % 360;
  return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
}

export function getUsername(str) {
  if (str !== null) {
    return str.split(' ')[0];
  }
}

function getStatus(start_date, end_date) {
  if (start_date !== null && end_date !== null) {
    return 'Completed';
  }

  if (start_date !== null && !end_date) {
    return 'In-Progress';
  }

  if (!start_date && !end_date) {
    return 'N/A';
  }
}
function getStatusWithCount(count, due, pending) {
  let status = 'pending';
  let overdueCount = 0;
  let pendingCount = 0;

  if (count == 0) {
    status = 'completed';
  } else if (moment(due).isBefore(moment())) {
    status = 'overdue';
    overdueCount++;
  } else {
    status = 'pending';
    pendingCount++;
  }

  return { status, overdueCount, pendingCount };
}

export function formatHistoryTableData(details, type) {
  if (type === 'rm') {
    let dataSource = [
      {
        label: 'Risk Registration',
        start_date: details.r_initiation_date,
        end_date: details.ini_end_date,
        username: getUsername(details.ini_sign),
        name: details.ini_name || 'N/A',
        role: 'Initiator',
        status: getStatus(details.r_initiation_date, details.ini_end_date),
      },
      {
        label: 'Risk Evaluation',
        start_date: details.ini_end_date,
        end_date: details.pr_end_date,
        username: getUsername(details.pr_sign),
        name: details.pr_name || 'N/A',
        role: 'Process Evaluator',
        status: getStatus(details.ini_end_date, details.pr_end_date),
      },
      {
        label: 'Risk Verification',
        start_date: details.pr_end_date,
        end_date: details.qr_end_date,
        username: getUsername(details.qr_sign),
        name: details.qr_name || 'N/A',
        role: 'Verifier',
        status: getStatus(details.pr_end_date, details.qr_end_date),
      },
      {
        label: 'Risk Closure',
        start_date: details.qr_end_date,
        end_date: details.qa_end_date,
        username: getUsername(details.qa_sign),
        name: details.qa_name || 'N/A',
        role: 'Approver',
        status: getStatus(details.qr_end_date, details.qa_end_date),
      },
      {
        label: 'Risk Mitigation',
        start_date: details.imp_start_date,
        end_date: details.imp_end_date,
        username: getUsername(details.imp_sign),
        name: details.imp_name || 'N/A',
        role: 'Initiator',
        status: getStatus(details.imp_start_date, details.imp_end_date),
      },
      {
        label: 'Risk Mitigation Review',
        start_date: details.imp_end_date,
        end_date: details.imp_pr_end_date,
        username: getUsername(details.pr_imp_sign),
        name: details.pr_imp_name || 'N/A',
        role: 'Process Evaluator',
        status: getStatus(details.imp_end_date, details.imp_pr_end_date),
      },
      {
        label: 'Risk Mitigation Verification',
        start_date: details.imp_pr_end_date,
        end_date: details.imp_qr_end_date,
        username: getUsername(details.qr_imp_sign),
        name: details.qr_imp_name || 'N/A',
        role: 'Verifier',
        status: getStatus(details.imp_pr_end_date, details.imp_qr_end_date),
      },
      {
        label: 'Risk Control Closure',
        start_date: details.imp_qr_end_date,
        end_date: details.imp_qa_end_date,
        username: getUsername(details.qa_imp_sign),
        name: details.qa_imp_name || 'N/A',
        role: 'Approver',
        status: getStatus(details.imp_qr_end_date, details.imp_qa_end_date),
      },
    ];
    return dataSource;
  }
  if (type === 'dnc') {
    let dataSource = [
      {
        label: 'Deviation Initiation',
        start_date: details.d_initiation_date,
        end_date: details.ini_end_date,
        username: getUsername(details.ini_sign),
        name: details.ini_name || 'N/A',
        role: 'Initiator',
        status: getStatus(details.d_initiation_date, details.ini_end_date),
      },
      {
        label: 'Deviation Evaluation',
        start_date: details.ini_end_date,
        end_date: details.pr_end_date,
        username: getUsername(details.pr_sign),
        name: details.pr_name || 'N/A',
        role: 'Process Evaluator',
        status: getStatus(details.ini_end_date, details.pr_end_date),
      },
      {
        label: 'Deviation Verification',
        start_date: details.pr_end_date,
        end_date: details.qr_end_date,
        username: getUsername(details.qr_sign),
        name: details.qr_name || 'N/A',
        role: 'Verifier',
        status: getStatus(details.pr_end_date, details.qr_end_date),
      },
      {
        label: 'Deviation Closure',
        start_date: details.qr_end_date,
        end_date: details.qa_end_date,
        username: getUsername(details.qa_sign),
        name: details.qa_name || 'N/A',
        role: 'Approver',
        status: getStatus(details.qr_end_date, details.qa_end_date),
      },
      {
        label: 'CAPA Implementation',
        start_date: details.imp_start_date,
        end_date: details.imp_end_date,
        username: getUsername(details.imp_sign),
        name: details.imp_name || 'N/A',
        role: 'Initiator',
        status: getStatus(details.imp_start_date, details.imp_end_date),
      },
      {
        label: 'CAPA Implementation Review',
        start_date: details.imp_end_date,
        end_date: details.imp_pr_end_date,
        username: getUsername(details.pr_imp_sign),
        name: details.pr_imp_name || 'N/A',
        role: 'Process Evaluator',
        status: getStatus(details.imp_end_date, details.imp_pr_end_date),
      },
      {
        label: 'CAPA Implementation Verification',
        start_date: details.imp_pr_end_date,
        end_date: details.imp_qr_end_date,
        username: getUsername(details.qr_imp_sign),
        name: details.qr_imp_name || 'N/A',
        role: 'Verifier',
        status: getStatus(details.imp_pr_end_date, details.imp_qr_end_date),
      },
      {
        label: 'CAPA Closure',
        start_date: details.imp_qr_end_date,
        end_date: details.imp_qa_end_date,
        username: getUsername(details.qa_imp_sign),
        name: details.qa_imp_name || 'N/A',
        role: 'Approver',
        status: getStatus(details.imp_qr_end_date, details.imp_qa_end_date),
      },
    ];
    return dataSource;
  }
  if (type === 'mtf') {
    const mtfDataSource = [
      {
        label: 'Memo to File Initiation',
        start_date: details.mf_initiation_date,
        end_date: details.ini_end_date,
        username: getUsername(details.ini_sign),
        name: details.ini_name || 'N/A',
        role: 'Initiator',
        status: getStatus(details.mf_initiation_date, details.ini_end_date),
      },
      {
        label: 'Memo to File Review',
        start_date: details.ini_end_date,
        end_date: details.r_end_date,
        username: getUsername(details.r_sign),
        name: details.r_name || 'N/A',
        role: 'Reviewer',
        status: getStatus(details.r_end_date, details.r_end_date),
      },
      {
        label: 'Memo to File Approval',
        start_date: details.r_end_date,
        end_date: details.a_end_date,
        username: getUsername(details.a_sign),
        name: details.a_name || 'N/A',
        role: 'Approver',
        status: getStatus(details.r_end_date, details.a_end_date),
      },
    ];
    return mtfDataSource;
  }
  if (type === 'nco') {
    let NcoDataSource = [
      {
        label: 'CAPA Plan Initiation',
        start_date: details.nco_initiation_date,
        end_date: details.ini_end_date,
        username: getUsername(details.ini_sign),
        name: details.ini_name || 'N/A',
        designation: details.ini_designation || details?.ini_desg || 'N/A',
        role: 'Initiator',
        status: getStatus(details.cci_initiation_date, details.ini_end_date),
      },
      {
        label: 'Action Item Notification',
        start_date: details.ini_end_date,
        end_date: details.pr_end_date,
        username: getUsername(details.pr_sign),
        name: details.pr_name || 'N/A',
        designation: details.pr_designation || details?.pr_desg || 'N/A',
        role: 'Verifier',
        status: getStatus(details.ini_end_date, details.pr_end_date),
      },
      {
        label: 'CAPA Plan Verification',
        start_date: details.pr_end_date,
        end_date: details.qr_end_date,
        username: getUsername(details.qr_sign),
        name: details.qr_name || 'N/A',
        designation: details.qr_designation || details?.qr_desg || 'N/A',
        role: 'Verifier',
        status: getStatus(details.pr_end_date, details.qr_end_date),
      },
      {
        label: 'CAPA Plan Approval',
        start_date: details.qr_end_date,
        end_date: details.qa_end_date,
        username: getUsername(details.qa_sign),
        name: details.qa_name || 'N/A',
        designation: details.qa_designation || details?.qa_desg ||'N/A',
        role: 'Approver',
        status: getStatus(details.qr_end_date, details.qa_end_date),
      },
    ];
    return NcoDataSource;
  }

  let dataSource = [
    {
      label: 'Change Control Initiation',
      start_date: details.cci_initiation_date,
      end_date: details.ini_end_date,
      username: getUsername(details.ini_sign),
      name: details.ini_name || 'N/A',
      role: 'Initiator',
      status: getStatus(details.cci_initiation_date, details.ini_end_date),
    },
    {
      label: 'Change Control Evaluation',
      start_date: details.ini_end_date,
      end_date: details.pr_end_date,
      username: getUsername(details.pr_sign),
      name: details.pr_name || 'N/A',
      role: 'Process Evaluator',
      status: getStatus(details.ini_end_date, details.pr_end_date),
    },
    {
      label: 'Change Control Review',
      start_date: details.pr_end_date,
      end_date: details.qr_end_date,
      username: getUsername(details.qr_sign),
      name: details.qr_name || 'N/A',
      role: 'Verifier',
      status: getStatus(details.pr_end_date, details.qr_end_date),
    },
    {
      label: 'Change Control Approval',
      start_date: details.qr_end_date,
      end_date: details.qa_end_date,
      username: getUsername(details.qa_sign),
      name: details.qa_name || 'N/A',
      role: 'Approver',
      status: getStatus(details.qr_end_date, details.qa_end_date),
    },
  ];
  return dataSource;
}

export function beforeUpload(file) {
  const supportedTypes = [
    'image/jpeg',
    'image/png',
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  ];
  const isSupported = supportedTypes.includes(file.type);
  if (!isSupported) {
    message.error(
      'You can only upload .jpg, .png, .jpeg, .pdf, .docx, .xlsx, .pptx file!'
    );
    return false;
  }
  const isLimit = file.size / 1024 / 1024 < 10;
  if (!isLimit) {
    message.error('File must smaller than 10MB!');
    return false;
  }
  return true;
}

export function validateUploads(uploads) {
  const attachments = uploads.map((upload, i) => {
    if (upload.activity_comments === '') {
      throw new Error(`Attachment ${i + 1} reason cannot be Empty!`);
    }
    return upload;
  });

  return attachments;
}

export function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

const excel_cols = {
  initiation: [
    { wch: 25 },
    { wch: 25 },
    { wch: 20 },
    { wch: 20 },
    { wch: 25 },
    { wch: 25 },
  ],
  implementation: [
    { wch: 25 },
    { wch: 25 },
    { wch: 20 },
    { wch: 20 },
    { wch: 25 },
    { wch: 25 },
  ],
  logs: [
    { wch: 25 },
    { wch: 25 },
    { wch: 25 },
    { wch: 20 },
    { wch: 20 },
    { wch: 25 },
    { wch: 20 },
    { wch: 25 },
    { wch: 25 },
    { wch: 20 },
    { wch: 25 },
  ],
};

export async function exportJSONToExcel(obj) {
  const { filteredData, filename, sheetname = 'Sheet 1', type } = obj;

  if (!type) {
    return message.error('Error Exporting!');
  }

  // Create a new Workbook
  const workbook = XLSX.utils.book_new();

  // Create a new Worksheet using the filtered data
  const worksheet = XLSX.utils.json_to_sheet(filteredData);
  worksheet['!cols'] = excel_cols[type];

  // Name your sheet
  XLSX.utils.book_append_sheet(workbook, worksheet, sheetname);

  // Export your excel
  await XLSX.writeFile(workbook, filename);
  message.success('Export Successful');
}

const getInitiationStage = (record) => {
  if (record.ini_stage === 'initiated' && record.cca_status === 'approve') {
    return 'Change Control Approved';
  }

  if (record.ini_stage === 'initiated' && record.cca_status === 'not_approve') {
    return 'Change Control Not Approved';
  }

  return ini_stage_alias[record.ini_stage];
};

const getDNCInitiationStage = (record) => {
  if (record.d_stage === 'initiated' && record.da_status === 'approve') {
    return 'Pending for CAPA';
  }

  if (record.d_stage === 'initiated' && record.da_status === 'not_approve') {
    return 'Deviation Approved';
  }

  return dnc_ini_stage_alias[record.d_stage];
};

const getRMInitiationStage = (record) => {
  if (record.r_stage === 'initiated' && record.ra_status === 'approve') {
    return 'Mitigate Risk';
  }

  if (record.r_stage === 'initiated' && record.ra_status === 'not_approve') {
    return 'Accept Risk';
  }

  return rm_ini_stage_alias[record.r_stage];
};

const getImplementationStage = (record) => {
  if (record.imp_stage === 'implemented' && record.cca_status === 'yes') {
    return 'Change Successful';
  }

  if (record.imp_stage === 'implemented' && record.cca_status === 'no') {
    return 'Change Not Successful';
  }

  return imp_stage_alias[record.imp_stage];
};

const getDNCImplementationStage = (record) => {
  if (record.capa_stage === 'implemented' && record.da_status === 'yes') {
    return 'CAPA Effective';
  }

  if (record.capa_stage === 'implemented' && record.da_status === 'no') {
    return 'CAPA Not Effective';
  }

  return dnc_imp_stage_alias[record.capa_stage];
};

const getRMImplementationStage = (record) => {
  if (record.rm_stage === 'implemented' && record.ra_status === 'yes') {
    return 'Risk Monitoring';
  }

  if (record.rm_stage === 'implemented' && record.ra_status === 'no') {
    return 'Risk not Monitoring';
  }

  return rm_imp_stage_alias[record.rm_stage];
};

const getLogStatus = (status) => {
  switch (status) {
    case 'approve':
      return 'Approved';
    case 'not_approve':
      return 'Not Approved';
    case 'yes':
      return 'Yes';
    case 'no':
      return 'No';
    default:
      return '';
  }
};
export const formatNcoExcelData = (type, data) => {
  const role = localStorage.getItem('role');
  const module = localStorage.getItem('module');

  const res = data.map((d, i) => {
    let obj = null;
    // console.log(d);
    obj = {
      'S no': i + 1,
      'Control No': d.nco_no,
      Classification: d.nco_classification,
      Type: d.nco_type,
      'Initiated Date': d.nco_initiation_date,
      Source: d.nco_source,
      Stage: d.nco_stage,
    };
    // console.log({ initiationStatus: d, type });
    if (type === 'initiation') {
      const assignee = ini_assignee_alias[role];
      obj['Assigned By'] = d[assignee] ?? 'N/A';
      obj['Assigned To'] = d.current_handler ?? 'N/A';
      //   obj.Stage = d.nco_stage;
    }

    if (type === 'implementation') {
      const assignee = imp_assignee_alias[role];
      obj.Assignee = d[assignee] ?? 'N/A';
      //   obj.Stage = getRMImplementationStage(d);
    }

    return obj;
  });

  return res;
};

export const formatExcelData = (type, data) => {
  const role = localStorage.getItem('role');
  const module = localStorage.getItem('module');

  const res = data.map((d) => {
    let obj = null;

    if (module === MODULE.dnc) {
      obj = {
        'Risk ID': d.risk_id,
        'Risk Classification': d.r_classification,
        'Risk Type': d.r_type,
        'Initiated Date': d.r_initiation_date,
      };
      console.log({ initiationStatus: d, type });
      if (type === 'initiation') {
        const assignee = ini_assignee_alias[role];
        obj.Assignee = d[assignee] ?? 'N/A';
        obj.Stage = getRMInitiationStage(d);
      }

      if (type === 'implementation') {
        const assignee = imp_assignee_alias[role];
        obj.Assignee = d[assignee] ?? 'N/A';
        obj.Stage = getRMImplementationStage(d);
      }
    } else if (module === MODULE.dnc) {
      obj = {
        'Deviation No.': d.d_no,
        'Deviation Classification': d.d_classification,
        'Deviation Type': d.d_type,
        'Initiated Date': d.d_initiation_date,
      };
      console.log({ initiationStatus: d, type });
      if (type === 'initiation') {
        const assignee = ini_assignee_alias[role];
        obj.Assignee = d[assignee] ?? 'N/A';
        obj.Stage = getDNCInitiationStage(d);
      }

      if (type === 'implementation') {
        const assignee = imp_assignee_alias[role];
        obj.Assignee = d[assignee] ?? 'N/A';
        obj.Stage = getDNCImplementationStage(d);
      }
    } else if (module === MODULE.nco) {
      const currentDate = new Date();
      const finalVerificationDate = new Date(d.nco_final_verification_due_date);
      const totalPendingCount = d.nco_total_pending_count;
      const actionItemsCount = d.total_count;

      let status;
      let statusText;

      if (currentDate > finalVerificationDate) {
        status = 'Overdue';
        statusText = `(${totalPendingCount})`;
      } else if (totalPendingCount === 0) {
        status = 'Completed';
        statusText = '';
      } else {
        status = 'Pending';
        statusText = `(${totalPendingCount})`;
      }

      obj = {
        'Control No': d.nco_no,
        Classification: d.nco_classification,
        Type: d.nco_type,
        'Initiated Date': moment(d.c_initiation_date).format('DD-MMM-YYYY'),
        Source: d.nco_source,
        Status: status + statusText,
      };
    } else {
      obj = {
        'Change Control No.': d.cc_no,
        'Change Control Priority': d.c_priority,
        'Change Control Type': d.c_type,
        'Initiated Date': d.cci_initiation_date,
      };
      if (type === 'initiation') {
        const assignee = ini_assignee_alias[role];
        obj.Assignee = d[assignee] ?? 'N/A';
        obj.Stage = getInitiationStage(d);
      }

      if (type === 'implementation') {
        const assignee = imp_assignee_alias[role];
        obj.Assignee = d[assignee] ?? 'N/A';
        obj.Stage = getImplementationStage(d);
      }
    }

    return obj;
  });

  return res;
};

export const functionalReviewExcel = (data) => {
  const module = localStorage.getItem('module');

  const res = data.map((d) => {
    let obj = null;

    if (module === MODULE.rm) {
      obj = {
        'Risk ID': d.r_no,
        'Risk Classification': d.r_classification,
        'Assigned By': d.assigned_by,
        'Assigned On': d.assign_date,
        'Evaluation Due Date': moment(d.re_overall_due_date).format(
          'DD-MMM-YYYY'
        ),
        'Action item Due Date': d.due_date,
      };
    } else if (module === MODULE.dnc) {
      obj = {
        'Deviation No.': d.d_no,
        'Deviation Classification': d.d_classification,
        'Assigned By': d.assigned_by,
        'Assigned On': d.assign_date,
        'Evaluation Due Date': moment(d.de_overall_due_date).format(
          'DD-MMM-YYYY'
        ),
        'Action item Due Date': d.due_date,
      };
    } else if (module === MODULE.nco) {
      const currentDate = new Date();
      const finalVerificationDate = moment(d.de_overall_due_date).format(
        'DD-MMM-YYYY'
      );
      const totalPendingCount = d.total_pending_count;
      const actionItemsCount = d.total_count;
      const { status, overdueCount, pendingCount } = getStatusWithCount(
        [totalPendingCount],
        [finalVerificationDate],
        [totalPendingCount]
      );

      obj = {
        'Control No.': d.nco_no,
        Classification: d.nco_classification,
        Type: d.nco_type,
        Source: d.nco_source,
        'Initiated Date': d.nco_initiation_date,
        'Final Verification Due Date': moment(d.de_overall_due_date).format(
          'DD-MMM-YYYY'
        ),
        'No of Action Items': d.total_count,
        Status: `${status} (${d.total_pending_count})`,
      };
    } else {
      obj = {
        'Change Control No.': d.cc_no,
        'Change Control Priority': d.c_priority,
        'Assigned By': d.assigned_by,
        'Assigned On': d.assign_date,
        'Due Date': moment(d.cce_overall_due_date).format('DD-MMM-YYYY'),
        'Due Date': d.due_date,
      };
    }

    return obj;
  });

  return res;
};

export const impTasksExcel = (data) => {
  const module = localStorage.getItem('module');

  const res = data.map((d) => {
    let obj = null;

    if (module === MODULE.rm) {
      obj = {
        'Risk Control ID.': d.rc_id,
        'Risk Mitigation Action': d.rm_action,
        'Mitigation Type': d.risk_mitigation_type,
        'Assigned By': d.assigned_by,
        'Assigned On': moment(d.assign_date).format('DD-MMM-YYYY'),
        'Action item Due Date': moment(d.due_date).format('DD-MMM-YYYY'),
      };
    } else if (module === MODULE.dnc) {
      obj = {
        'CAPA No.': d.ca_no,
        'CAPA Action': d.ca_action,
        'Action Item Type': d.action_item_type,
        'Assigned By': d.assigned_by,
        'Assigned On': moment(d.assign_date).format('DD-MMM-YYYY'),
        'Action item Due Date': moment(d.due_date).format('DD-MMM-YYYY'),
      };
    } else {
      obj = {
        'Change Implementation No.': d.imp_no,
        'Task Area': d.task_area,
        'Task Priority': d.task_priority,
        'Assigned By': d.assigned_by,
        'Assigned On': d.assign_date,
        'Due Date': d.due_date,
      };
    }

    return obj;
  });

  return res;
};

const getAssignee = (role, d) => {
  if (role === 'initiator') return d.ini_asignee;
  if (role === 'reviewer') return d.r_asignee;
  if (role === 'approver') return d.a_asignee;
  return 'N/A';
};

export const formatMTFExcelData = (type, data) => {
  const role = localStorage.getItem('role');

  const res = data.map((d) => {
    const obj = {
      'Memo to File No.': d.mf_no,
      'Memo to File Department': d.mf_department,
      'Memo to File Project Name': d.mf_project_name,
      'Memo to File Purpose': d.mf_purpose,
      'Initiated Date': d.mf_initiation_date,
      'Assigned To': getAssignee(role, d),
    };

    return obj;
  });

  return res;
};

export const formatDraftExcelData = (data) => {
  if (!data) return;
  const module = localStorage.getItem('module');

  const res = data.map((d, i) => {
    let obj = null;

    if (module === MODULE.rm) {
      obj = {
        'Risk Id': d.risk_id,
        'Risk Classification': d.r_classification,
        'Risk Type': d.r_type,
        'Created Date': d.created_at,
      };
    } else if (module === MODULE.dnc) {
      obj = {
        'Deviation Id': d.d_id,
        'Deviation Classification': d.d_classification,
        'Deviation Type': d.d_type,
        'Created Date': d.created_at,
      };
    } else if (module === MODULE.nco) {
      obj = {
        'S No': i + 1,
        'Draft Id': d.nco_id,
        Type: d.nco_type,
        Source: d.nco_source,
        Classification: d.nco_classification,
        Department: d.nco_department,
        Project: d.nco_project_name,
        'Created Date': d.created_at,
      };
    } else {
      obj = {
        'Change Control Id': d.id,
        'Change Control Department': d.cci_department,
        'Change Control Project Name': d.cci_project_name,
        'Change Control Purpose': d.cci_purpose,
        'Created Date': d.created_date,
      };
    }

    return obj;
  });

  return res;
};

export const formatMTFDraftExcelData = (data) => {
  if (!data) return;
  const res = data.map((d) => {
    const obj = {
      'Memo to File Id': d.id,
      'Memo to File Department': d.mf_department,
      'Memo to File Project Name': d.mf_project_name,
      'Memo to File Purpose': d.mf_purpose,
      'Created Date': d.created_date,
    };

    return obj;
  });

  return res;
};

export const formatNCOLogExcelData = (data, type) => {
  let res = [];
  if (type === 'nc/ob') {
    if (Array.isArray(data)) {
      res = data.map((d) => ({
        'Control No': d.nco_no,
        'Initiation Date': d.nco_initiation_date,
        'Identification Date': d.nco_identification_date,
        Department: d.nco_department,
        Project: d.nco_project_name,
        Source: d.nco_source,
        Status: d.da_status,
      }));
    }
    return res;
  } else if (type === 'CAPA') {
    if (Array.isArray(data)) {
      res = data.map((d) => ({
        'CAPA No': d.capa_no,
        'Control No': d.nco_no,
        Department: d.nco_department,
        Project: d.nco_project_name,
        Source: d.nco_source,
        'RCA No': d.rca_no,
        'Root Cause': d.root_cause,
        'Impact ': d.impact,
        'CAPA Type': d.capa_type,
        'Action Category ': d.action_category,
        'Action Owner': d.nco_source,
        'Estimated Action Implementation Date': d.est_action_impl_date,
        'Actual Implementation Date': d.nco_source,
        'Estimated Action Closure Date': d.est_action_closure_date,
        'Actual Action closure date': d.nco_source,
        Status: d.nco_source,
      }));
    }
    return res;
  } else if (type === 'RCA') {
    if (Array.isArray(data)) {
      res = data.map((d) => ({
        'RCA No': d.rca_no,
        'Control No': d.nco_no,
        Department: d.nco_department,
        Project: d.nco_project_name,
        Source: d.nco_source,
        'Root Cause': d.root_cause,
        'Impact ': d.impact,
        'CAPA Required': d.capa_required === 0 ? 'NO' : 'YES',
        'CAPA Count': d.Total_capa === 0 ? 'N/A' : d.Total_capa,
        Status: d.status,
      }));
    }
    return res;
  }
};

export const formatLogExcelData = (data) => {
  let res = [];
  const module = localStorage.getItem('module');

  if (module === MODULE.rm) {
    if (Array.isArray(data)) {
      res = data.map((d) => ({
        'Risk id': d.risk_id,
        'Risk Initiated Date': d.r_initiation_date,
        Organization: d.r_organisation,
        Department: d.r_department,
        'Project Name': d.r_project_name,
        'Risk Source': d.r_source,
        'Risk Closure Status': getLogStatus(d.ra_status),
        'Risk Closure Date': d.qa_end_date,
        'Risk Mitigation No.': d.rm_no,
        'Risk Monitoring Required': getLogStatus(d.rm_required),
        'Risk Closure Date': d.imp_qa_end_date,
      }));
    }
    return res;
  }

  if (module === MODULE.dnc) {
    if (Array.isArray(data)) {
      res = data.map((d) => ({
        'Deviation No.': d.d_no,
        'Deviation Initiated Date': d.d_initiation_date,
        Organization: d.d_organisation,
        Department: d.d_department,
        'Project Name': d.d_project_name,
        'Deviation Source': d.d_source,
        'Deviation Closure Status': getLogStatus(d.da_status),
        'Deviation Closure Date': d.qa_end_date,
        'CAPA No.': d.ca_no,
        'CAPA Effectiveness Check Required': getLogStatus(d.caec_required),
        'CAPA Closure Date': d.imp_qa_end_date,
      }));
    }
    return res;
  }

  if (module === MODULE.mtf) {
    if (Array.isArray(data)) {
      res = data.map((d) => ({
        'Memo to File No.': d.mf_no,
        'Memo to File Initiated Date': d.mf_initiation_date,
        Organization: d.mf_organisation,
        Department: d.mf_department,
        'Project Name': d.mf_project_name,
        'Memo to File Purpose': d.mf_purpose,
        'Memo to File Approval Status': getLogStatus(d.a_status),
        'Memo to File Approved Date': d.a_end_date,
      }));
    }
    return res;
  }

  if (Array.isArray(data)) {
    res = data.map((d) => ({
      'Change Control No.': d.cc_no,
      'Change Control Initiated Date': d.cci_initiation_date,
      Organization: d.cci_organisation,
      Department: d.cci_department,
      'Project Name': d.cci_project_name,
      'Change Control Purpose': d.cci_purpose,
      'Change Control Status': getLogStatus(d.cca_status),
      'Change Control Approved Date': d.ini_end_date,
      'Implementation No.': d.imp_no,
      'Change Successful': getLogStatus(d.ccc_change),
      'Change Control Closure Date': d.imp_end_date,
    }));
  }

  return res;
};

export const removeUnderscoreFromString = (str) => {
  if (typeof str !== 'string') return '';
  const strArr = str.split('_');
  for (let i = 0; i < strArr.length; i++) {
    strArr[i] = strArr[i].charAt(0).toUpperCase() + strArr[i].slice(1);
  }
  let newStr = strArr.join(' ');
  return newStr;
};

export const restrictValues = (allowedValues, values) => {
  return allowedValues.reduce(
    (acc, _) => ({
      ...acc,
      [_]: values[_],
    }),
    {}
  );
};

export const NCOAudit = (data) => {
  const formdata = {
    Classification: data.nco_classification,
    'Due Date': data.c_date,
    'Current Handler': data.current_handler,
    'CAPA Plan Initiation Assignee': data.ini_asignee,
    ini_designation: data.ini_designation,
    name: data.name,
    Category: data.nco_category,
    Classification: data.nco_classification,
    Department: data.nco_department,
    'Description ': data.nco_description,
    'Identification Date': data.nco_identification_date,
    nco_no: data.nco_no,
    Organization: data.nco_organisation,
    'Project Name': data.nco_project_name,
    'Source ': data.nco_source,
    Summary: data.nco_summary,
    Type: data.nco_type,
    role: data.role,
    stage: data.stage,
    username: data.username,
  };
  return formdata;
};
