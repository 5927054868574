import React, { useState, useEffect } from 'react';
import { Card, Avatar, Space, Input } from 'antd';
import { withRouter } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { UpdateQuestion } from '../../../store/actions/vmm/vendorEvaluation';
import Attachment from './Attachment';
const MultipleChoice = (props) => {
  const dispatch = useDispatch();
  const handleChange = (value_type, value) => {
    console.log(value_type, value);
    dispatch(
      UpdateQuestion(
        props.activeSectionNo,
        props.activeQuestionNo,
        value_type,
        value
      )
    );
  };

  const gridStyle = {
    width: '100%',
    textAlign: 'start',
  };
  const [gridNo, setGridNo] = useState(3);
  useEffect(() => {
    const longestStringLength = props.options?.reduce((maxLength, option) => {
      return option.length > maxLength ? option.length : maxLength;
    }, 0);
    let gridNo;
    if (props.options.length == 2) {
      gridNo = 2;
    } else if (props.options.length == 3) {
      gridNo = 3;
    } else if (longestStringLength < 20) {
      gridNo = 4;
    } else if (longestStringLength < 40) {
      gridNo = 3;
    } else {
      gridNo = 2;
    }
    setGridNo(gridNo);
  }, [props.options]);

  const Symbol = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
  ];

  return (
    <div className="p-3 row multipleChoice">
      <div className="col-12">
        <Card>
          <div
            className="font-weight-bold h6 py-1 mr-1"
            style={{ color: '#333', whiteSpace: 'nowrap' }}
          >
            {props.question_no}
          </div>{' '}
          {props.question_name}
        </Card>
      </div>
      <div
        className="col-12 mt-2"
        style={{
          display: 'grid',
          gridTemplateColumns: `repeat(${gridNo}, 1fr)`,
          justifyContent: 'center',
          gap: '16px',
        }}
      >
        {props.options?.map((item, index) => (
          <Card.Grid
            style={gridStyle}
            className={`${
              props.activeQuestion.answer.answer == item
                ? 'optionsCardSuccess'
                : 'optionsCard'
            }`}
            hoverable={
              props.activeQuestion.answer.answer == item ? false : true
            }
            onClick={() => handleChange('answer', item)}
          >
            <div className="optionAvatar">
              <Avatar size="large" className="optionAvatarInside">
                {Symbol[index]}
              </Avatar>
            </div>

            <div className="mx-3">{item}</div>
          </Card.Grid>
        ))}
      </div>
      {props.comments && (
        <div className="col-12 d-flex mt-4">
          <Input.TextArea
            placeholder="Enter Your Comments (Non Mandatory)"
            autoSize={{ minRows: 4, maxRows: 6 }}
            value={props.activeQuestion.answer.comments}
            onChange={(e) => handleChange('comments', e.target.value)}
          />
        </div>
      )}
      {props.attachment && (
        <div className="col-12 mt-4">
          <Attachment {...props} />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ QPRReducer }) => ({
  activeSection: QPRReducer.activeSection,
  activeQuestion: QPRReducer.activeQuestion,
  activeQuestionNo: QPRReducer.activeQuestionNo,
  activeSectionNo: QPRReducer.activeSectionNo,
});

export default connect(mapStateToProps)(withRouter(MultipleChoice));
