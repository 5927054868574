import { db } from '../../../vmm/components/VendorEvaluation/db';

export const getVESections =
  (organization, paperId, version) => async (dispatch) => {
    const sectionData = await db
      .table('Sections')
      .where('[organization+paper_id+version]')
      .equals([organization, paperId, version])
      .toArray();
    dispatch({ type: 'SET_SECTION_DATA', data: sectionData });
  };

export const getVEQuestions = async (section_id) => {
  const paperId = sessionStorage.getItem('paperId');
  const version = sessionStorage.getItem('version');
  const organization = sessionStorage.getItem('organization');
  const questionData = await db
    .table('Questions')
    .where('[organization+paper_id+version+section_id]')
    .equals([organization, paperId, version, section_id])
    .toArray();
  return questionData;
};

export const getVESection = (section_id) => async (dispatch) => {
  const paperId = sessionStorage.getItem('paperId');
  const version = sessionStorage.getItem('version');
  const organization = sessionStorage.getItem('organization');
  const sectionData = await db
    .table('Sections')
    .where('[organization+paper_id+version+section_id]')
    .equals([organization, paperId, version, section_id])
    .toArray();
  console.log(sectionData);
  dispatch({ type: 'SET_ACTIVE_SECTION', activeSection: sectionData?.[0] });
};

export const getVEQuestion = (section_id, question_id) => async (dispatch) => {
  const paperId = sessionStorage.getItem('paperId');
  const version = sessionStorage.getItem('version');
  const organization = sessionStorage.getItem('organization');
  const vendor_no = sessionStorage.getItem('vendor_no');
  const version_no = sessionStorage.getItem('version_no');
  const questionData = await db
    .table('Questions')
    .where('[organization+paper_id+version+section_id+question_id]')
    .equals([organization, paperId, version, section_id, question_id])
    .toArray();
  const answerData = await db
    .table('Answers')
    .where('[organization+vendor_no+version_no+section_id+question_id]')
    .equals([organization, vendor_no, version_no, section_id, question_id])
    .toArray();
  await dispatch({
    type: 'SET_QUESTION_DATA',
    data: { ...questionData?.[0], answer: answerData?.[0] },
    activeSectionNo: section_id,
    activeQuestionNo: question_id,
  });
};

export const getVEQuestionChange =
  (action_type, section_id, question_id) => async (dispatch) => {
    const questionsCount = JSON.parse(sessionStorage.getItem('questionsCount'));
    let sectionId = section_id;
    let questionId = question_id;
    if (action_type == 'Next') {
      if (
        section_id == Object.keys(questionsCount).length &&
        parseInt(questionsCount[section_id]) == question_id
      ) {
        return;
      }
      questionId = question_id + 1;
      if (
        section_id <= Object.keys(questionsCount).length &&
        parseInt(questionsCount[section_id]) == question_id
      ) {
        sectionId = section_id + 1;
        questionId = 1;
        dispatch(getVESection(sectionId));
      }
      dispatch(getVEQuestion(sectionId, questionId));
    } else if (action_type == 'Previous') {
      if (section_id == 1 && question_id == 1) {
        dispatch({
          type: 'RESET_ACTIVE_SECTION',
        });
        return;
      }
      questionId = question_id - 1;
      if (section_id >= 2 && question_id == 1) {
        sectionId = section_id - 1;
        questionId = questionsCount[sectionId];
        dispatch(getVESection(sectionId));
      }
      dispatch(getVEQuestion(sectionId, questionId));
    }
  };

export const getGridListQuestion = async () => {
  try {
    const paperId = sessionStorage.getItem('paperId');
    const version = sessionStorage.getItem('version');
    const organization = sessionStorage.getItem('organization');
    const vendor_no = sessionStorage.getItem('vendor_no');
    const version_no = sessionStorage.getItem('version_no');

    const questionData = await db
      .table('Sections')
      .where('[organization+paper_id+version]')
      .equals([organization, paperId, version])
      .toArray();

    const groupedData = await Promise.all(
      questionData.map(async (item) => {
        const answerData = await db
          .table('Answers')
          .where('[organization+vendor_no+version_no+section_id]')
          .equals([organization, vendor_no, version_no, item.section_id])
          .toArray();

        item['answers'] = answerData;
        return item;
      })
    );
    console.log(groupedData);
    return groupedData;
  } catch (error) {
    console.error('Error fetching questions:', error);
    // Handle error appropriately, maybe dispatch an error action
  }
};

export const UpdateQuestion =
  (section_id, question_id, value_type, value) => async (dispatch) => {
    console.log(section_id, question_id, value_type, value);
    const vendor_no = sessionStorage.getItem('vendor_no');
    const version_no = sessionStorage.getItem('version_no');
    const organization = sessionStorage.getItem('organization');
    console.log(section_id, question_id, value_type, value);
    await db.Answers.where(
      '[organization+vendor_no+version_no+section_id+question_id]'
    )
      .equals([organization, vendor_no, version_no, section_id, question_id])
      .modify({
        [value_type]: value,
        offlineStatus: true,
      });
    dispatch({
      type: 'UPDATE_ANSWERS_DATA',
      data: { [value_type]: value, offlineStatus: true },
    });
  };
