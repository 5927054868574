const initState = {
  QuestionNo: null,
  SectionNo: null,
  SubQuestion: null,
  QuestionData: null,
  SectionData: [],
  MasterData: [],
  QuestionPaperList: [],
  changed_id: 'default',
  QuestionPaper: [],
};

const QPReducer = (state = initState, action) => {
  switch (action.type) {
    case 'SET_MASTER_DATA':
      return {
        ...state,
        MasterData: action.data,
        changed_id: Math.floor(Math.random() * (9999 - 0 + 1)),
      };
    case 'SET_QUESTION_NO':
      return {
        ...state,
        QuestionNo: action.data,
      };
    case 'SET_SECTION_NO':
      return {
        ...state,
        SectionNo: action.data,
      };
    case 'SET_SUB_QUESTION':
      return {
        ...state,
        SubQuestion: action.data,
      };
    case 'SET_QUESTION_DATA':
      return {
        ...state,
        QuestionData: action.data,
      };
    case 'GET_QUESTION_PAPER_DATA':
      return {
        ...state,
        QuestionPaperList: action.data,
      };
    case 'GET_QUESTION_PAPER':
      return {
        ...state,
        QuestionPaper: action.data,
        MasterData: action.data.questionPaperData
          ? action.data.questionPaperData
          : [],
        changed_id: Math.floor(Math.random() * (9999 - 0 + 1)),
      };
    default:
      return state;
  }
};

export default QPReducer;
