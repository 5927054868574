const initState = {
  description: null,
  paper_name: null,
  organization: null,
  paperId: null,
  version: null,
  sections: [],
  activeSection: {},
  activeQuestion: {},
  activeQuestionNo: null,
  activeSectionNo: 0,
};

const QPRReducer = (state = initState, action) => {
  switch (action.type) {
    case 'SET_QUESTION_PAPER_DATA':
      return {
        ...state,
        description: action.data.description,
        paper_name: action.data.paperName,
        organization: action.data.organization,
        paperId: action.data.paperId,
        version: action.data.version,
      };
    case 'SET_SECTION_DATA':
      return {
        ...state,
        sections: action.data,
      };
    case 'RESET_ACTIVE_SECTION':
      return {
        ...state,
        activeSection: {},
        activeQuestion: {},
        activeQuestionNo: null,
        activeSectionNo: 0,
      };
    case 'SET_ACTIVE_SECTION':
      return {
        ...state,
        activeSection: action.activeSection,
        activeSectionNo: action.activeSectionNo,
        activeQuestionNo: null,
        activeQuestion: {},
      };
    case 'SET_QUESTION_DATA':
      console.log('hi');

      return {
        ...state,
        activeQuestion: action.data,
        activeQuestionNo: action.activeQuestionNo,
        activeSectionNo: action.activeSectionNo,
      };
    case 'UPDATE_ANSWERS_DATA':
      console.log('hi');
      console.log('master', {
        ...state,
        activeQuestion: {
          ...state.activeQuestion,
          answers: { ...state.activeQuestion.answers, ...action.data },
        },
      });
      return {
        ...state,
        activeQuestion: {
          ...state.activeQuestion,
          answer: { ...state.activeQuestion.answer, ...action.data },
        },
      };
    default:
      return state;
  }
};

export default QPRReducer;
