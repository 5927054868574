import Dexie from 'dexie';
import moment from 'moment';

export const db = new Dexie('FormRenderer');
db.version(1).stores({
  Sections:
    '[organization+paper_id+version+section_id], [organization+paper_id+version], section_no, section_id, created_at, dependency, dependency_value, question_count, section_information, section_name, section_type, sequence_name',
  Questions:
    '[organization+paper_id+version+section_id+question_id], [organization+paper_id+version+section_id], question_no, question_numeric_no, section_id, question_id, created_at, question_name_editor, question_name, options, dependency_value, dependency_question, sub_questions, lower_limit, upper_limit, date_type, file_types, answer_type, question_type, dependency, attachment, tableFormat, comments, exclude, required',
  Attachments:
    '[organization+vendor_no+version_no+section_id+question_id], section_id, question_id, uid, created_at, sequence_no, file, status, size, classification, filename, filetype, comments',

  Answers:
    '[organization+vendor_no+version_no+section_id+question_id], [organization+vendor_no+version_no+section_id], created_at, section_id, question_id, answer, attachments, offlineStatus, status, comments, subInputDataAnswers, multiFormDataAnswers, tableDataAnswers',
});

db.open().catch((err) => {
  console.error('Failed to open db: ', err.stack || err);
});

const deleteOldQuestions = async () => {
  const momentNow = moment().subtract(5, 'days').toISOString();
  try {
    const oldQuestions = await db.Questions.where('created_at')
      .below(momentNow)
      .toArray();
    await Promise.all(
      oldQuestions.map((question) => db.Questions.delete(question.id))
    );
    const oldSections = await db.Sections.where('created_at')
      .below(momentNow)
      .toArray();
    await Promise.all(
      oldSections.map((section) => db.Sections.delete(section.id))
    );
    const oldAttachments = await db.Attachments.where('created_at')
      .below(momentNow)
      .toArray();
    await Promise.all(
      oldAttachments.map((section) => db.Attachments.delete(section.id))
    );
    const oldAnswers = await db.Answers.where('created_at')
      .below(momentNow)
      .toArray();
    await Promise.all(
      oldAnswers.map((section) => db.Answers.delete(section.id))
    );
  } catch (error) {
    console.error('Error deleting old Questions or Sections:', error);
    throw error;
  }
};

export const createDB2 = async (tables, paper_id, version, organization) => {
  try {
    // deleteOldQuestions();
    let questionsCount = {};
    for (const [index, table] of tables.entries()) {
      const sectionIndex = index + 1;
      if (table.questions && table.questions.length > 0) {
        const questionsToStore = table.questions.map((question, index2) => ({
          created_at: moment().toISOString(),
          section_id: sectionIndex,
          section_no: table.section_no,
          paper_id: paper_id,
          version: version,
          organization: organization,
          ...question,
          question_id: index2 + 1,
        }));
        await db.Questions.bulkPut(questionsToStore);

        // Bulk insert/update questions
        // await Promise.all(
        //   questionsToStore.map(async (question) => {
        //     const existingQuestion = await db.Questions.where({
        //       organization: organization,
        //       version: version,
        //       paper_id: paper_id,
        //       section_id: question.section_id,
        //       section_no: question.section_no,
        //     }).first();
        //     if (existingQuestion) {
        //       await db.Questions.update(existingQuestion.id, question);
        //     } else {
        //       await db.Questions.add(question);
        //     }
        //   })
        // );
      }

      // Insert/update section data
      const sectionData = {
        created_at: moment().toISOString(),
        section_id: index + 1,
        paper_id: paper_id,
        version: version,
        organization: organization,
        dependency: table.dependency,
        dependency_value: table.dependency_value,
        section_information: table.section_information,
        section_name: table.section_name,
        section_no: table.section_no,
        section_type: table.section_type,
        sequence_name: table.sequence_name,
        question_count:
          table.section_type == 'Multi Level Data'
            ? 1
            : table.questions.length || 0,
      };
      await db.Sections.put(sectionData);
      questionsCount[index + 1] = sectionData.question_count;
    }
    sessionStorage.setItem('questionsCount', JSON.stringify(questionsCount));
  } catch (error) {
    console.error('Error in createDB2:', error);
    throw error;
  }
};

export const createDB = async (vendor_no, version_no, organization, tables) => {
  try {
    // deleteOldQuestions();
    console.log(vendor_no, version_no, organization, tables);

    for (const [index, table] of tables.entries()) {
      const sectionIndex = index + 1;
      if (table.VendorQuestions && table.VendorQuestions.length > 0) {
        const answersToStore = table.VendorQuestions.map(
          (question, index2) => ({
            created_at: moment().toISOString(),
            section_id: sectionIndex,
            section_no: table.section_no,
            vendor_no: vendor_no,
            version_no: version_no,
            organization: organization,
            ...question,
            question_id: index2 + 1,
            attachments: [],
          })
        );
        console.log(answersToStore);
        await db.Answers.bulkPut(answersToStore);
      }
    }
  } catch (error) {
    console.error('Error in createDB2:', error);
    throw error;
  }
};
