import axios from 'axios';

export const CCM_CONFIG = 'CCM_CONFIG';

export const CCMConfig = () => (dispatch) => {
  try {
    const userToken = localStorage.getItem('access_token');

    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
    };
    const data = {
      type: 'global_config',
    };
    axios
      .post(`${process.env.REACT_APP_IACCESS_URL}/globalConfig/`, data, config)
      .then(({ data }) => {
        dispatch({ type: CCM_CONFIG, data });
      })
      .catch((error) => {
        console.error(error);
      });
  } catch (error) {
    console.error(error);
  }
};
