import React, { useState, useEffect } from 'react';
import {
  Tooltip,
  Card,
  Statistic,
  Button,
  Descriptions,
  Space,
  Checkbox,
  Input,
  Upload,
} from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import { ClockCircleOutlined, CarOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';

import Btn from '../UtilityComponents/Btn';
const Home = (props) => {
  const [fileList, setFileList] = useState([]);
  const onChange = ({ fileList: newFileList }) => {
    if (newFileList.length > 1) {
      newFileList = newFileList.slice(-1); // Keep only the latest file
    }
    setFileList(newFileList);
  };
  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const { data, questionNo } = props;
  const [activity, setActivity] = useState(false);
  return (
    <div className="mx-2  row">
      <div className="d-flex align-items-center my-3  mb-2 justify-content-between col-12">
        <div className="h4">Vendor Evaluation Form</div>
        <div className="">
          <Button
            size="medium"
            className="mx-2"
            type="primary"
            // onClick={(e) => handleDownload(e, record.cc_no)}
          >
            Change Representative
          </Button>
          <Button
            size="small"
            className="icoButton mx-2"

            // onClick={(e) => handleDownload(e, record.cc_no)}
          >
            <CarOutlined style={{ fontSize: '1.125rem' }} />
          </Button>
          <Button
            size="small"
            className="icoButton mx-2"
            onClick={() => setActivity(true)}
          >
            <ClockCircleOutlined style={{ fontSize: '1.125rem' }} />
          </Button>
        </div>
      </div>
      <div className="col-12 mb-3">
        <Card>
          <div className=" details-container d-flex justify-content-between w-100 ">
            <div className="detail-card">
              <div className="detail-card-label">Vendor Registration No</div>
              <div className="detail-card-value">ICOM-VDR-001 (Version 01)</div>
            </div>
            <div className="detail-card">
              <div className="detail-card-label">Department Name</div>
              <div className="detail-card-value"> Procurement Department</div>
            </div>
            <div className="detail-card">
              <div className="detail-card-label">Project Name</div>
              <div className="detail-card-value">RP Procurement</div>
            </div>
            <div className="detail-card">
              <div className="detail-card-label">Representative Email</div>
              <div className="detail-card-value">
                SANTHOSHPARTHIBAN2002@GMAIL.COM
              </div>
            </div>
          </div>
        </Card>
      </div>
      <div className="col-3">
        <Card bordered={false}>
          <Statistic
            title="Total No of Questions"
            value={11.28}
            precision={2}
            valueStyle={{
              color: '#3f8600',
            }}
            // prefix={<ArrowUpOutlined />}
            suffix="%"
          />
        </Card>
      </div>
      <div className="col-3">
        <Card bordered={false}>
          <Statistic
            title="No of Questions Answered"
            value={11.28}
            precision={2}
            valueStyle={{
              color: '#3f8600',
            }}
            // prefix={<ArrowUpOutlined />}
            suffix="%"
          />
        </Card>
      </div>
      <div className="col-3">
        <Card bordered={false}>
          <Statistic
            title="No of Questions BookMarked"
            value={11.28}
            precision={2}
            valueStyle={{
              color: '#3f8600',
            }}
            // prefix={<ArrowUpOutlined />}
            suffix="%"
          />
        </Card>
      </div>

      <div className="col-3">
        <Card bordered={false}>
          <Statistic
            title="Total No Of Attachments"
            value={11.28}
            precision={2}
            valueStyle={{
              color: '#3f8600',
            }}
            // prefix={<ArrowUpOutlined />}
            suffix="%"
          />
        </Card>
      </div>

      {/* 
      <div className="col-12  mt-2  w-100 ">
        <Descriptions
          bordered
          size="small"
          layout="vertical"
          className="descriptionBox"
          column={5}
        >
          <Descriptions.Item label="Vendor Registration No">
            ICOM-VDR-001 (Version 01)
          </Descriptions.Item>
     
          <Descriptions.Item label="Department Name">
            Procurement Department
          </Descriptions.Item>
          <Descriptions.Item label="Project Name">
            RP Procurement
          </Descriptions.Item>
          <Descriptions.Item label="Representative Email">
            SANTHOSHPARTHIBAN2002@GMAIL.COM
          </Descriptions.Item>
       
        </Descriptions>
      </div>
       */}

      <div className="col-12 py-4">
        <Tooltip title="Vendor Comments">
          <Input.TextArea
            name="vendor_comments"
            // value={values.vendor_comments}
            // onChange={handleChange}
            // onBlur={handleBlur}
            autoSize={{ minRows: 3, maxRows: 6 }}
            maxLength={200}
            placeholder="Any other information, you wish to furnish"
          />
        </Tooltip>
      </div>
      <div className="col-4 ">
        <Tooltip title="Section Name">
          <Input
            name="vendor_name"
            // value={values.vendor_name}
            // onChange={handleChange}
            // onBlur={handleBlur}
            placeholder="Enter Your Name"
            size="large"
          />
        </Tooltip>
      </div>
      <div className="col-4 ">
        <Tooltip title="Section Name">
          <Input
            name="vendor_name"
            // value={values.vendor_designation}
            // onChange={handleChange}
            // onBlur={handleBlur}
            placeholder="Enter Your Designation"
            size="large"
          />
        </Tooltip>
      </div>

      <div className="col-4 ">
        <Tooltip title="Section Name">
          <Input
            name="vendor_name"
            // value={values.vendor_contact_no}
            // onChange={handleChange}
            // onBlur={handleBlur}
            placeholder="Enter Your Contact No"
            size="large"
          />
        </Tooltip>
      </div>
      <div className="col-12 mt-3  py-2 ">
        <Checkbox className=" h6   text-justify">
          I hereby declare that to the best of my knowledge the answers
          contained within this questionnaire are true and accurate. I
          understand that the information will be used in the evaluation process
          to assess our organization’s suitability as a supplier.
        </Checkbox>
      </div>
      <div className="col-12 d-flex justify-content-between vendorSignature mb-2">
        <ImgCrop
          rotationSlider
          aspect={21 / 9} // Set aspect ratio for rectangle (4:3)
          modalTitle="Crop Signature"
          modalWidth={800}
        >
          <Upload
            listType="picture-card"
            fileList={fileList}
            onChange={onChange}
            onPreview={onPreview}
          >
            {fileList.length >= 1 ? null : '+ Upload Signature'}
          </Upload>
        </ImgCrop>
        <div className="w-100  d-flex justify-content-end align-items-center">
          <Btn buttonText="Submit Evaluation Form" />
        </div>
      </div>
    </div>
  );
};

export default Home;
