import React, { useState, useEffect } from 'react';
import Attachment from './Attachment';
import Btn from '../UtilityComponents/Btn';
import RenderSubQuestion from './RenderSubQuestion';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Form, Input, Space, Card } from 'antd';
const MultipleInputExclude = (props) => {
  const [fields, setFields] = useState([{ first: '', last: '' }]);
  const [errors, setErrors] = useState([{}]);

  const handleAdd = () => {
    setFields([...fields, { first: '', last: '' }]);
    setErrors([...errors, {}]);
  };

  const handleRemove = (index) => {
    const newFields = fields.filter((_, i) => i !== index);
    const newErrors = errors.filter((_, i) => i !== index);
    setFields(newFields);
    setErrors(newErrors);
  };

  const handleChange = (index, key, value) => {
    const newFields = fields.map((field, i) =>
      i === index ? { ...field, [key]: value } : field
    );
    setFields(newFields);
  };

  const handleValidation = () => {
    let valid = true;
    const newErrors = fields.map((field) => {
      const fieldErrors = {};
      if (!field.first) {
        fieldErrors.first = 'Missing first name';
        valid = false;
      }
      if (!field.last) {
        fieldErrors.last = 'Missing last name';
        valid = false;
      }
      return fieldErrors;
    });
    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (handleValidation()) {
      console.log('Received values of form:', fields);
    }
  };
  // return props.subQuestion ? (
  //   <>
  //     <div className="d-flex justify-content-end mb-3">
  //       <Button
  //         onClick={() => {
  //           setShow(true);
  //         }}
  //         type="primary"
  //         icon={<PlusCircleOutlined />}
  //       >
  //         Add Data
  //       </Button>
  //     </div>
  //     <div className="table-card-audit">
  //       <Table bordered columns={columns} />
  //     </div>
  //   </>
  // ) : (
  return (
    <div className="row ">
      {fields.map((field, index) => (
        <>
          <div className="col-11">
            <div className="row">
              {props.sub_questions.map((item, index) => (
                <RenderSubQuestion {...item} />
              ))}
            </div>
          </div>

          <div className="d-flex align-items-center col-1">
            <MinusCircleOutlined onClick={() => handleRemove(index)} />
            {fields.length == index + 1 && (
              <PlusCircleOutlined onClick={handleAdd} className="mx-2" />
            )}
          </div>
        </>
      ))}

      {props.comments && (
        <div className="col-12 d-flex mt-4">
          <Input.TextArea
            placeholder="Enter Your Comments (Non Mandatory)"
            autoSize={{ minRows: 4, maxRows: 6 }}
          />
        </div>
      )}
      {props.attachment && (
        <div className="col-12 mt-4">
          <Attachment {...props} />
        </div>
      )}
    </div>
  );
};

export default MultipleInputExclude;
