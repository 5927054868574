import Dexie from 'dexie';
import moment from 'moment';
import { getUserData, getVendorUserData } from '../store/actions/authActions';

export const db = new Dexie('iControl');
db.version(1).stores({
  Configuration:
    '[username+module+organization+application+configuration_name], [username+module+organization+application], created_at, configuration_name, configuration',
  Authentication:
    '[username+module+organization], [organization+module+role], created_at, token_name, token',
  Assignees:
    '[organization+module+role+project+assignee_name], [organization+module+role], [organization+module], [organization+module+role+project], designation, role, project, assignee_name, created_at',
});
db.open().catch((err) => {
  console.error('Failed to open db: ', err.stack || err);
});

const deleteOldData = async () => {
  const momentNow = moment().subtract(2, 'days').toISOString();
  try {
    // Fetch all items to be deleted
    const oldConfigurations = await db.Configuration.where('created_at')
      .below(momentNow)
      .primaryKeys();
    const oldAuthentications = await db.Authentication.where('created_at')
      .below(momentNow)
      .primaryKeys();
    const oldAssignees = await db.Assignees.where('created_at')
      .below(momentNow)
      .primaryKeys();

    // Bulk delete using primary keys
    if (oldConfigurations.length) {
      await db.Configuration.bulkDelete(oldConfigurations);
    }
    if (oldAuthentications.length) {
      await db.Authentication.bulkDelete(oldAuthentications);
    }
    if (oldAssignees.length) {
      await db.Assignees.bulkDelete(oldAssignees);
    }
  } catch (error) {
    console.error('Error deleting old data:', error);
    throw error;
  }
};

export const setConfigurations =
  (username, module, organization, application, reduxData) =>
  async (dispatch) => {
    deleteOldData();
    try {
      // Check if UserData already exists in the Dexie database
      console.log(username, module, organization, application);
      const existingUserDataCount = await db.Configuration.where(
        '[username+module+organization+application]'
      )
        .equals([username, module, organization, application])
        .count();
      let DBData;
      if (reduxData && existingUserDataCount > 2) {
        DBData = await db
          .table('Configuration')
          .where('[username+module+organization+application]')
          .equals([username, module, organization, application])
          .toArray();
      } else {
        const UserData = await dispatch(getUserData());
        DBData = Object.keys(UserData).map((key) => {
          const item = UserData[key];
          const data = {
            username: username,
            module: module,
            application: application,
            organization: organization,
            created_at: moment().toISOString(),
            configuration_name: key,
            configuration: item,
          };
          return data;
        });
        await db.Configuration.bulkPut(DBData);
      }
      const dropDown = DBData.find(
        (obj) => obj.configuration_name === 'dropDown'
      );
      const globalConfig = DBData.find(
        (obj) => obj.configuration_name === 'globalConfig'
      );
      const switch_application = DBData.find(
        (obj) => obj.configuration_name === 'switch_application'
      );
      const department = DBData.find(
        (obj) => obj.configuration_name === 'department_project'
      );
      const dueDates = DBData.find(
        (obj) => obj.configuration_name === 'due_date'
      );
      dispatch({
        type: 'SET_USER_DATA',
        dropDown: dropDown.configuration,
        globalConfig: globalConfig.configuration,
        switch_application: switch_application.configuration,
        department: department.configuration,
        dueDates: dueDates.configuration,
      });
    } catch (error) {
      console.error('Error in createDB2:', error);
      throw error;
    }
  };

export const getAssignees =
  (organization, module, role, filter = true) =>
  async (dispatch) => {
    try {
      const totalAssignee = await db.Assignees.where(
        '[organization+module+role]'
      )
        .equals([organization, module, role])
        .toArray();
      if (filter) {
        const assigneeNames = totalAssignee
          .map((user) => user.assignee_name)
          ?.filter((value, index, self) => self.indexOf(value) === index);
        return assigneeNames;
      } else {
        return totalAssignee;
      }
    } catch (error) {
      console.error('Error in createDB2:', error);
      throw error;
    }
  };
export const getProjectAssignees =
  (organization, module, role, project, filter = true) =>
  async (dispatch) => {
    try {
      const totalAssignee = await db.Assignees.where(
        '[organization+module+role+project]'
      )
        .equals([organization, module, role, project])
        .toArray();
      if (filter) {
        const assigneeNames = totalAssignee
          .map((user) => user.assignee_name)
          ?.filter((value, index, self) => self.indexOf(value) === index);
        return assigneeNames;
      } else {
        return totalAssignee;
      }
    } catch (error) {
      console.error('Error in createDB2:', error);
      throw error;
    }
  };

export const getAllAssignees =
  (organization, module, filter = true) =>
  async (dispatch) => {
    try {
      const totalAssignee = await db.Assignees.where('[organization+module]')
        .equals([organization, module])
        .toArray();
      if (filter) {
        const assigneeNames = totalAssignee
          .map((user) => {
            return {
              assignee_name: user.assignee_name,
              designation: user.designation,
            };
          })
          .filter(
            (user, index, self) =>
              index ===
              self.findIndex(
                (t) =>
                  t.assignee_name === user.assignee_name &&
                  t.designation === user.designation
              )
          );
        return assigneeNames;
      } else {
        return totalAssignee;
      }
    } catch (error) {
      console.error('Error in createDB2:', error);
      throw error;
    }
  };

export const setVendorConfigurations =
  (username, module, organization, application, reduxData) =>
  async (dispatch) => {
    deleteOldData();
    try {
      // Check if UserData already exists in the Dexie database
      console.log(username, module, organization, application);
      const existingUserDataCount = await db.Configuration.where(
        '[username+module+organization+application]'
      )
        .equals([username, module, organization, application])
        .count();
      let DBData;
      if (reduxData && existingUserDataCount > 2) {
        DBData = await db
          .table('Configuration')
          .where('[username+module+organization+application]')
          .equals([username, module, organization, application])
          .toArray();
      } else {
        const UserData = await dispatch(getVendorUserData());
        DBData = Object.keys(UserData).map((key) => {
          const item = UserData[key];
          const data = {
            username: username,
            module: module,
            application: application,
            organization: organization,
            created_at: moment().toISOString(),
            configuration_name: key,
            configuration: item,
          };
          return data;
        });
        await db.Configuration.bulkPut(DBData);
      }
      const dropDown = DBData.find(
        (obj) => obj.configuration_name === 'dropDown'
      );
      const globalConfig = DBData.find(
        (obj) => obj.configuration_name === 'globalConfig'
      );
      // const switch_application = DBData.find(
      //   (obj) => obj.configuration_name === 'switch_application'
      // );
      // const department = DBData.find(
      //   (obj) => obj.configuration_name === 'department_project'
      // );
      dispatch({
        type: 'SET_USER_DATA',
        dropDown: dropDown.configuration,
        globalConfig: globalConfig.configuration,
        // switch_application: switch_application.configuration,
        // department: department.configuration,
      });
    } catch (error) {
      console.error('Error in createDB2:', error);
      throw error;
    }
  };
