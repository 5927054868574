import React from 'react';
import '../../../styles/UtilityComponents/btn.scss';
import { LoadingOutlined } from '@ant-design/icons';

const Btn = ({
	disabled,
	primary,
	secondary,
	danger,
	text,
	border,
	shadow,
	className,
	buttonText,
	loadingText,
	buttonType,
	buttonIcon,
	small,
	loading,
	clickHandler
}) => {
	return (
		<button
			type={buttonType}
			className={`button
      ${primary ? 'primary-btn' : ''}
			${secondary ? 'secondary-btn' : ''}
			 ${danger ? 'danger-btn' : ''} 
			${text ? 'btn-text' : ''}
      ${border ? 'btn-border' : ''}
			${shadow ? 'btn-shadow' : ''}
			${small ? 'btn-small' : ''}
      ${className}`}
			onClick={clickHandler}
			disabled={disabled}
		>
			{loading ? (
				<div className='button-content'>
					<LoadingOutlined />
					<span>{loadingText}</span>
				</div>
			) : (
				<div className='button-content'>
					{React.isValidElement(buttonIcon)
						? buttonIcon
						: buttonIcon && <i className={`${buttonIcon}`}></i>}
					<span>{buttonText}</span>
				</div>
			)}
		</button>
	);
};

Btn.defaultProps = {
	disabled: false,
	primary: true,
	secondary: false,
	danger: false,
	text: false,
	border: false,
	shadow: false,
	className: '',
	buttonText: 'Submit',
	loadingText: 'Loading',
	buttonType: 'button',
	buttonIcon: '',
	small: '',
	loading: false,
	clickHandler: () => {}
};

export default Btn;
