import React, { useState, useEffect } from 'react';
import { Collapse, Badge, Space, Avatar } from 'antd';
import '../../../styles/LayoutComponents/app-layout.scss';
import { withRouter } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import * as modalActions from '../../../store/actions/modalActions';
import { getQuestionPaper } from '../../../store/actions/QPCreator/QPAction';
import { Switch, Route, Redirect } from 'react-router-dom';
import { CaretRightOutlined } from '@ant-design/icons';
import { db } from './db';
import { getVEQuestion } from '../../../store/actions/vmm/vendorEvaluation';

const VendorEvaluationGrid = (props) => {
  const dispatch = useDispatch();

  const getColor = (section, question, data) => {
    if (
      section == props.activeSectionNo &&
      question == props.activeQuestionNo
    ) {
      return 'activeBtn';
    } else {
      return 'NonActiveBtn';
    }
  };

  return (
    Array.isArray(props.data) && (
      <div className="h-100  sidebar">
        <Collapse
          bordered={false}
          defaultActiveKey={Array.from(
            { length: props.data.length || 0 },
            (_, index) => index
          )}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
          style={{ backgroundColor: '#fff' }}
        >
          {props.data.map(({ section_name, answers, section_id }, index) => {
            return (
              <Collapse.Panel
                key={index}
                header={
                  <span style={{ fontSize: '1rem', fontWeight: 600 }}>
                    {section_name}
                  </span>
                }
                className="site-collapse-custom-panel"
              >
                <Space
                  direction="horizontal"
                  className="d-flex justify-content-around mb-3"
                >
                  <Badge status="default" text={'65'} className="mx" />
                  <Badge color="yellow" text={'65'} />
                  <Badge color="red" text={'65'} />
                  <Badge color="geekblue" text={'65'} />
                  <Badge color="green" text={'65'} />
                </Space>
                <Space
                  direction="horizontal"
                  className="w-100 mx-2 QuestionPannedButtons"
                  size={15}
                  wrap
                >
                  {answers?.map((item, index) => (
                    <Badge dot>
                      <Avatar
                        key={index}
                        size={50}
                        shape="square"
                        className={`font-weight-bold  ${getColor(
                          section_id,
                          index + 1,
                          item
                        )}`}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          dispatch(getVEQuestion(section_id, index + 1));
                        }}
                      >
                        {index + 1}
                      </Avatar>
                    </Badge>
                  ))}
                </Space>
              </Collapse.Panel>
            );
          })}
        </Collapse>
      </div>
    )
  );
};

const mapStateToProps = ({ qp, QPRReducer }) => ({
  description: QPRReducer.description,
  paper_name: QPRReducer.paper_name,
  organization: QPRReducer.organization,
  paperId: QPRReducer.paperId,
  version: QPRReducer.version,
  activeQuestionNo: QPRReducer.activeQuestionNo,
  activeSectionNo: QPRReducer.activeSectionNo,
});
const mapDispatchToProps = {
  getQuestionPaper,
  ...modalActions,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(VendorEvaluationGrid));
