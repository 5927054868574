import { combineReducers } from 'redux';
import auth from './authReducer';
import modals from './modalReducer';
import dashboard from './dashboardReducer';
import initiation from './initiationReducer';
import implementation from './implementationReducer';
import reports from './reportReducer';
import common from './commonReducer';
import vendor from './vendorReducer';
import ccmReducer from './ccm/ccmReducer';
import darkModeReducer from './darkmodeReducer';
import NcoFilterReducer from './nco/ncoFilterReducer';
import QPReducer from './QPCreator/QPReducer';
import ncoDashboardReducer from './nco/ncoDashboardReducer';
import ncoReportsReducer from './nco/ncoReportsReducer';
import ncoGraphicalDashboardReducer from './nco/GraphicalDasdhBoard';
import QPRReducer from './QPCreator/QPRReducer';
import ammReducer from './ammReducer';
import vmmReducer from './vmmReducer';
const rootReducer = combineReducers({
  auth,
  modals,
  dashboard,
  initiation,
  darkmode: darkModeReducer,
  implementation,
  reports,
  vendor,
  common,
  ccmReducer,
  ncoDashboard: ncoDashboardReducer,
  qp: QPReducer,
  ncoFilter: NcoFilterReducer,
  ncoReport: ncoReportsReducer,
  ncoGraphicalDashboardReducer,
  QPRReducer: QPRReducer,
  vmm: vmmReducer,
  amm: ammReducer,
});

export default rootReducer;
