import React, { useState, useEffect } from 'react';
import '../../../styles/LayoutComponents/vendorEvaluation.scss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as modalActions from '../../../store/actions/modalActions';
import { getQuestionPaperRender } from '../../../store/actions/QPCreator/QPRAction';
import { db } from './db';
import MultipleChoice from './MultipleChoice';
import TextField from './TextField';
import TextEditor from '../../../icontrolLogin/UtilityComponents/TextEditor';
import SubInputs from './SubInputs';
import { Spin } from 'antd';
import { Select, Input, InputNumber, DatePicker, Tabs } from 'antd';
import Attachment from './Attachment';
import RenderSubQuestion from './RenderSubQuestion';
import MultipleInput from './MultipleInput';
import MultipleInputExclude from './MultipleInputExclude';
const RenderMultiLevelData = (props) => {
  const [qpData, setQPData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const questionData = await db
          .table('Questions')
          .where('[organization+paper_id+version+section_id]')
          .equals([
            props.organization,
            props.paperId,
            props.version,
            props.activeSectionNo,
          ])
          .toArray();
        setQPData(questionData);
      } catch (error) {
        console.error('Error fetching data from the Questions table:', error);
      }
    };

    fetchData();
  }, [props.organization, props.paperId, props.version, props.activeSectionNo]);
  console.log(qpData);
  const getQuestion = (data) => {
    const {
      answer_type,
      question_name,
      upper_limit,
      lower_limit,
      options,
      sub_questions,
    } = data;
    console.log(answer_type, data);
    if (answer_type === 'Multiple Choice') {
      return (
        <div className="col-4 mb-3">
          <div className="mb-1">{question_name}</div>
          <Select placeholder={question_name} className="w-100" size="large">
            {options.map((item, index) => (
              <Select.Option key={index} value={item}>
                {item}
              </Select.Option>
            ))}
          </Select>
        </div>
      );
    } else if (answer_type === 'Multiple Select') {
      return (
        <div className="col-4 mb-3">
          <div className="mb-1">{question_name}</div>
          <Select placeholder={question_name} className="w-100" size="large">
            {options.map((item, index) => (
              <Select.Option key={index} value={item}>
                {item}
              </Select.Option>
            ))}
          </Select>
        </div>
      );
    }
    //  else if (answer_type === 'Dependency Select') {
    //   // Add the corresponding component for Dependency Select
    // }
    else if (answer_type === 'Multiple Input') {
      return (
        <div className="col-12 mb-3">
          <div className="mb-1">{question_name}</div>
          <MultipleInputExclude {...data} subQuestion={true} />
        </div>
      );
    } else if (answer_type === 'Text Field') {
      return (
        <div className="col-4 mb-3 ">
          <div className="mb-1">{question_name}</div>
          <Input placeholder={`Enter ${question_name}`} size="large" />
        </div>
      );
    } else if (answer_type === 'Text Area') {
      return (
        <div className="col-12 mb-3">
          <div className="mb-1">{question_name}</div>
          <Input.TextArea
            placeholder={`Enter ${question_name}`}
            size="large"
            autoSize={{ minRows: 3, maxRows: 6 }}
          />
        </div>
      );
    } else if (answer_type === 'Text Editor') {
      return (
        <div className="col-4 mb-3">
          <div className="mb-1">{question_name}</div>
          <TextEditor />
        </div>
      );
    } else if (answer_type === 'Number') {
      return (
        <div className="col-4 mb-3">
          <div className="mb-1">{question_name}</div>
          <InputNumber
            min={parseInt(lower_limit)}
            max={parseInt(upper_limit)}
            className="w-100"
            size="large"
            placeholder={`Enter ${question_name}`}
          />
        </div>
      );
    } else if (answer_type === 'Sub Inputs') {
      return (
        <div className="col-12 mb-3">
          <div className="mb-1 font-weight-bold">{question_name}</div>
          <div className="row mt-3">
            {sub_questions?.map((items, index) => (
              <RenderSubQuestion index={index} {...items} />
            ))}
          </div>
        </div>
      );
    } else if (answer_type === 'Date Field') {
      return (
        <div className="col-4 mb-3">
          <div className="mb-1">{question_name}</div>
          <DatePicker
            placeholder={`Enter ${question_name}`}
            size="large"
            className="w-100"
          />
        </div>
      );
    } else if (answer_type === 'Email Address') {
      <div className="col-4 mb-3">
        <div className="mb-1">{question_name}</div>
        <Input placeholder={`Enter Email Address`} size="large" />
      </div>;
    } else if (answer_type === 'Contact No') {
      return (
        <div className="col-4 mb-3">
          <div className="mb-1">{question_name}</div>
          <Input placeholder={`Enter ${question_name}`} size="large" />
        </div>
      );
    } else if (answer_type === 'Rating') {
      <div className="col-4 mb-3">
        <div className="mb-1">{question_name}</div>
        <Input placeholder={`Enter ${question_name}`} size="large" />
      </div>;
    } else if (answer_type === 'Upload File') {
      return (
        <div className="col-12 my-3">
          <Attachment {...props} />
        </div>
      );
    }
    return <></>;
  };

  return (
    <>
      <div className=" p-3">
        <Tabs
          defaultActiveKey="1"
          size="large"
          type="editable-card"
          tabPosition="top"
          className="w-100"
        >
          <Tabs.TabPane
            // style={{ backgroundColor: '#ffffff' }}
            key="1"
            tab={<div className="tabName">Grid View</div>}
            className="px-4"
          >
            <div className="row">
              {qpData?.map((item, index) => getQuestion(item))}
            </div>
          </Tabs.TabPane>
        </Tabs>
      </div>
    </>
  );
};

const mapStateToProps = ({ QPRReducer }) => ({
  description: QPRReducer.description,
  paper_name: QPRReducer.paper_name,
  organization: QPRReducer.organization,
  paperId: QPRReducer.paperId,
  version: QPRReducer.version,
  activeSection: QPRReducer.activeSection,
  activeSectionNo: QPRReducer.activeSectionNo,
  activeQuestion: QPRReducer.activeQuestion,
});

const mapDispatchToProps = {
  getQuestionPaperRender,
  ...modalActions,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RenderMultiLevelData));
