import * as types from '../../actions/ccm/CCMActions';

const initState = {
  config: [],
  report_controlled: null,
};

const ccmReducer = (state = initState, action) => {
  switch (action.type) {
    case types.CCM_CONFIG:
      return {
        ...state,
        config: action.data,
        report_controlled: action.data.report_controlled,
      };

    default:
      return state;
  }
};

export default ccmReducer;
