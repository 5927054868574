import * as types from '../actions/reportActions';

const initialState = {
  forms: null,
  totalForms: 0,
  formDetails: null,
  logs: null,
  logExcelData: [],
  totalLogs: 0,
  historyDetails: null,
  historyTable: [],
  audits: null,
  perms: {},
  RCAdata: null,
  Effectivedata:null,
  EffectiveexcelData:[],
  Effectivetotal:0,
  RCAexcelData: [],
  RCAtotal: 0,
  CAPAData: null,
  CAPAExcelData: [],
  CAPATotal: 0,
  CapahistoryDetails : null,
  CapahistoryTable : [],
};

const reports = (state = initialState, action) => {
  switch (action.type) {
    case types.FORM_DATA:
      return {
        ...state,
        forms: action.data,
        totalForms: action.total,
      };

    case types.FORM_DETAILS:
      return {
        ...state,
        formDetails: action.data,
      };

    case types.LOG_DATA:
      return {
        ...state,
        logs: action.data,
        logExcelData: action.excelData,
        totalLogs: action.total,
      };

    case types.RCA_LOG_DATA:
      return {
        ...state,
        RCAdata: action.RCAdata,
        RCAexcelData: action.RCAexcelData,
        RCAtotal: action.RCAtotal,
      };
    case types.EFFECTIVE_LOG_DATA:
      return {
        ...state,
        Effectivedata: action.Effectivedata,
        EffectiveexcelData: action.EffectiveexcelData,
        Effectivetotal: action.Effectivetotal,
      };


    case types.CAPA_LOG_DATA:
      return {
        ...state,
        CAPAData: action.CAPAData,
        CAPAExcelData: action.CAPAExcelData,
        CAPATotal: action.CAPATotal,
      };

    case types.HISTORY_DETAILS:
      return {
        ...state,
        historyDetails: action.data,
        historyTable: action.historyTable,
      };
    case types.CAPA_HISTORY_DATA:
      return {
        ...state,
        CapahistoryDetails: action.data,
        CapahistoryTable: action.historyTable,
      };
    case types.AUDIT_REPORT:
      return {
        ...state,
        audits: action.data,
      };

    case types.GET_REPORT_PERMS:
      return {
        ...state,
        perms: action.data.reduce((acc, perm) => {
          const permKeys = Object.keys(perm)?.[0] ?? [];
          if (!permKeys) return { ...acc };
          return {
            ...acc,
            [permKeys]: perm[permKeys],
          };
        }, {}),
      };

    case types.RESET_REPORT_PERMS:
      return {
        ...state,
        perms: {},
      };

    default:
      return { ...state };
  }
};

export default reports;
