import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'text-editor-icomply/build/ckeditor';

const TextEditor = (props) => {
  console.log(props);
  return (
    <CKEditor
      editor={Editor}
      config={{
        placeholder: 'Type Here...',
      }}
      data={props.value}
      onChange={(event, editor) => {
        props.handleChange(editor.getData());
      }}
      onReady={(editor) => {
        // You can store the "editor" and use when it is needed.
        console.log('Editor 1 is ready to use!', editor);
      }}
    />
  );
};

export default TextEditor;
