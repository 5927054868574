import React from 'react';
import moment from 'moment';
import { Table } from 'antd'; // Assuming you're using Ant Design's Table component
import '../../../../styles/LayoutComponents/initiationDetails.scss';

const VendorDummyDetails = (props) => {
  // Mocked data (you can fetch this data from your Redux store or API later)
  const vendorPlanDetails = {
    history: [
      {
        activity: 'Vendor Plan Initiation',
        startTime: '2024-10-01 12:14:45',
        endTime: '2024-10-01 12:27:56',
        name: 'Surya',
        designation: 'Deputy Manager',
        signature: 'SURYA712 01-Oct-2024 12:27:56',
      },
      {
        activity: 'Action Item Notification',
        startTime: '2024-10-01 12:27:56',
        endTime: '2024-10-01 13:17:10',
        name: 'Vijay',
        designation: 'Manager',
        signature: 'VIJAY164 01-Oct-2024 13:17:10',
      },
    ],
    activityHistory: [
      {
        time: '2024-10-01 12:14:45',
        description: 'Surya has created control no (DSFL-Vendor-155)',
        userId: 'SURYA712',
      },
      {
        time: '2024-10-01 12:27:56',
        description: 'Surya completed Vendor plan initiation and assigned Action Item Notification to Vijay (VIJAY164)',
        userId: 'SURYA712',
      },
      {
        time: '2024-10-01 13:17:05',
        description: 'ACT-155-1 is created by Vijay (VIJAY164)',
        userId: 'VIJAY164',
      },
      {
        time: '2024-10-01 13:17:10',
        description: 'Vijay has notified action item (ACT-155-1) to action owner Raghul (RAGHUL60)',
        userId: 'VIJAY164',
      },
    ],
  };

  const columns = [
    {
      title: 'Activity',
      dataIndex: 'activity',
      key: 'activity',
    },
    {
      title: 'Start Date and Time',
      dataIndex: 'startTime',
      key: 'startTime',
      render: (startTime) => moment(startTime).format('DD-MMM-YYYY HH:mm:ss'),
    },
    {
      title: 'End Date and Time',
      dataIndex: 'endTime',
      key: 'endTime',
      render: (endTime) => moment(endTime).format('DD-MMM-YYYY HH:mm:ss'),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Designation',
      dataIndex: 'designation',
      key: 'designation',
    },
    {
      title: 'Signature',
      dataIndex: 'signature',
      key: 'signature',
    },
  ];

  const tableData = vendorPlanDetails.history.map((row, index) => ({
    key: index,
    activity: row.activity,
    startTime: row.startTime,
    endTime: row.endTime,
    name: row.name,
    designation: row.designation,
    signature: row.signature,
  }));

  return (
    <div className="vendor-plan-container w-100">
      
      {/* Vendor Plan Authentication Details */}
      <div className="col-12 view-container-label">
        VENDOR PLAN Authentication DETAILS
      </div>
      <div className="col-12 mt-3">
        <Table
          className="report-table table-card-audit"
          dataSource={tableData}
          columns={columns}
          size="small"
          rowKey={(record) => record.key}
          bordered={true}
          pagination={false}
        />
      </div>

      <div className="w-100 mt-4">
        <div className="col-12 view-container-label">
          Activity History
        </div>

        <div className="col-12 mt-4">
          <ul className="activity-list w-100" style={{ listStyle: 'none', padding: '0' }}>
            {vendorPlanDetails.activityHistory.map((activity, index) => (
              <li key={index} style={{ marginBottom: '15px' }}>
                <div className="activity-description" style={{ marginTop: '5px' }}>
                  {activity.description}
                </div>
                <div className="activity-time" style={{ color: '#3f51b5', fontWeight: 'bold' }}>
                  {moment(activity.time).format('DD-MMM-YYYY HH:mm:ss')}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default VendorDummyDetails;
