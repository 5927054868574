import React, { useState, useEffect } from 'react';
import { Collapse, Badge, Space, Alert, Button, Timeline } from 'antd';
import '../../../styles/LayoutComponents/app-layout.scss';
import { withRouter } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import * as modalActions from '../../../store/actions/modalActions';
import { getQuestionPaper } from '../../../store/actions/QPCreator/QPAction';
import { Switch, Route, Redirect } from 'react-router-dom';
import { CaretRightOutlined } from '@ant-design/icons';

const VendorEvaluationList = (props) => {
  return (
    <div className="h-100  sidebar">
      <Collapse
        bordered={false}
        defaultActiveKey={Array.from(
          { length: props.data.length || 0 },
          (_, index) => index
        )}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        style={{ backgroundColor: '#fff' }}
      >
        {Array.isArray(props.data) &&
          props.data.map((items, index) => {
            return (
              <Collapse.Panel
                key={index}
                header={
                  <span style={{ fontSize: '1rem', fontWeight: 600 }}>
                    {items.section_name}
                  </span>
                }
                className="site-collapse-custom-panel"
              >
                <Space
                  direction="horizontal"
                  className="d-flex justify-content-around mb-3"
                >
                  <Badge status="default" text={'65'} />
                  <Badge color="yellow" text={'65'} />
                  <Badge color="red" text={'65'} />
                  <Badge color="geekblue" text={'65'} />
                  <Badge color="green" text={'65'} />
                </Space>

                <Timeline>
                  {Array.isArray(items.answers) &&
                    items.answers.map((data, index) => (
                      <Timeline.Item key={index} color={'red'}>
                        {data.question}
                      </Timeline.Item>
                    ))}
                </Timeline>
              </Collapse.Panel>
            );
          })}
      </Collapse>
    </div>
  );
};

const mapStateToProps = ({ qp }) => ({
  questionPaper: qp.QuestionPaper.questionPaperData,
  paperName: qp.QuestionPaper.paperName,
});

const mapDispatchToProps = {
  getQuestionPaper,
  ...modalActions,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(VendorEvaluationList));
