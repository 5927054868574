import React, { useState, useEffect } from 'react';
import '../../../styles/LayoutComponents/vendorEvaluation.scss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as modalActions from '../../../store/actions/modalActions';
import { getQuestionPaperRender } from '../../../store/actions/QPCreator/QPRAction';
import { db } from './db';
import MultipleChoice from './MultipleChoice';
import TextField from './TextField';
import TextEditor from '../../../icontrolLogin/UtilityComponents/TextEditor';
import SubInputs from './SubInputs';
import { Spin } from 'antd';
import { Select, Input, InputNumber, DatePicker } from 'antd';
import Attachment from './Attachment';
const RenderSubQuestion = (props) => {
  const getQuestion = () => {
    const { answer_type, question_name, upper_limit, lower_limit, options } =
      props;
    if (answer_type === 'Multiple Choice') {
      return (
        <div className="col-4 mb-3">
          <div className="mb-1">{question_name}</div>
          <Select placeholder={question_name} className="w-100" size="large">
            {options.map((item, index) => (
              <Select.Option key={index} value={item}>
                {item}
              </Select.Option>
            ))}
          </Select>
        </div>
      );
    } else if (answer_type === 'Multiple Select') {
      return (
        <div className="col-4 mb-3">
          <div className="mb-1">{question_name}</div>
          <Select placeholder={question_name} className="w-100" size="large">
            {options.map((item, index) => (
              <Select.Option key={index} value={item}>
                {item}
              </Select.Option>
            ))}
          </Select>
        </div>
      );
    }
    //  else if (answer_type === 'Dependency Select') {
    //   // Add the corresponding component for Dependency Select
    // }
    // //  else if (answer_type === 'Multiple Input') {
    // //   // Add the corresponding component for Multiple Input
    // // } else if (answer_type === 'Sub Inputs') {
    // //   return <SubInputs {...props} {...qpData[0]} />;
    // // }
    else if (answer_type === 'Text Field') {
      return (
        <div className="col-4 mb-3 ">
          <div className="mb-1">{question_name}</div>
          <Input
            placeholder={`Enter ${question_name}`}
            size="large"
            name={question_name?.toLowerCase()?.replace(/ /g, '_')}
            onChange={props.handleChange}
          />
        </div>
      );
    } else if (answer_type === 'Text Area') {
      return (
        <div className="col-12 mb-3">
          <div className="mb-1">{question_name}</div>
          <Input.TextArea
            placeholder={`Enter ${question_name}`}
            autoSize={{ minRows: 3, maxRows: 6 }}
          />
        </div>
      );
    } else if (answer_type === 'Text Editor') {
      return (
        <div className="col-4 mb-3">
          <div className="mb-1">{question_name}</div>
          <TextEditor />
        </div>
      );
    } else if (answer_type === 'Number') {
      return (
        <div className="col-4 mb-3">
          <div className="mb-1">{question_name}</div>
          <InputNumber
            min={parseInt(lower_limit)}
            max={parseInt(upper_limit)}
            className="w-100"
            size="large"
            placeholder={`Enter ${question_name}`}
            name={question_name}
            onChange={props.handleChange}
          />
        </div>
      );
    } else if (answer_type === 'Date Field') {
      return (
        <div className="col-4 mb-3">
          <div className="mb-1">{question_name}</div>
          <DatePicker
            placeholder={`Enter ${question_name}`}
            size="large"
            className="w-100"
          />
        </div>
      );
    } else if (answer_type === 'Email Address') {
      return (
        <div className="col-4 mb-3">
          <div className="mb-1">{question_name}</div>

          <Input placeholder={`Enter Email Address`} size="large" />
        </div>
      );
    } else if (answer_type === 'Contact No') {
      return (
        <div className="col-4 mb-3">
          <div className="mb-1">{question_name}</div>
          <Input placeholder={`Enter ${question_name}`} size="large" />
        </div>
      );
    } else if (answer_type === 'Rating') {
      <div className="col-4 mb-3">
        <div className="mb-1">{question_name}</div>
        <Input placeholder={`Enter ${question_name}`} size="large" />
      </div>;
    } else if (answer_type === 'Upload File') {
      return (
        <div className="col-12 my-3">
          <Attachment {...props} />
        </div>
      );
    }
    return <></>;
  };

  return getQuestion();
};

const mapStateToProps = ({ QPRReducer }) => ({
  description: QPRReducer.description,
  paper_name: QPRReducer.paper_name,
  organization: QPRReducer.organization,
  paperId: QPRReducer.paperId,
  version: QPRReducer.version,
  activeSection: QPRReducer.activeSection,
  activeQuestion: QPRReducer.activeQuestion,
});

const mapDispatchToProps = {
  getQuestionPaperRender,
  ...modalActions,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RenderSubQuestion));
