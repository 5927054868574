import { iaccessHttps, vmmHttps } from '../../../utils/config';
import { message } from 'antd';
import {
  createDB2,
  createDB,
} from '../../../vmm/components/VendorEvaluation/db';
import { getVESections } from '../vmm/vendorEvaluation';
export const getQuestionPaperRender = (data) => async (dispatch) => {
  try {
    const res = await iaccessHttps.post('/getQuestionPaper/', data);
    dispatch({ type: 'SET_QUESTION_PAPER_DATA', data: res.data });
    sessionStorage.setItem('paperId', res.data.paperId);
    sessionStorage.setItem('version', res.data.version);
    sessionStorage.setItem('organization', res.data.organization);
    await createDB2(
      res.data.questionPaperData,
      res.data.paperId,
      res.data.version,
      res.data.organization
    );
    dispatch(
      getVESections(res.data.organization, res.data.paperId, res.data.version)
    );
  } catch (err) {
    console.error(err);
  }
};

export const getAnswers = (data) => async (dispatch) => {
  try {
    const res = await vmmHttps.post('/vendor/getQuestions/', {
      vendor_no: 'ICOM-VDR-081',
      version_no: '01',
      organization: 'iComply Lifescience Solutions Test',
    });
    const { vendor_no, version_no, organization, VendorSections } = res.data[0];
    dispatch(createDB(vendor_no, version_no, organization, VendorSections));
  } catch (err) {
    console.error(err);
  }
};
