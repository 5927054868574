export const colors = {
  primary: '#3c2a5e',
  secondary: '#7f60b8',
  danger: '#FF7C7C',
  font: '#2A2A2A',
  white: '#FFFFFF',
  border: '#dbdbdb',
  red: '#e53935',
  ember: '#faad14',
  pumpkin: '#d35400',
  green: '#87d068',
};

export const role_alias = {
  initiator: 'Initiator',
  process_reviewer: 'Process Evaluator',
  qa_reviewer: 'Verifier',
  qa_approver: 'Approver',
  general: 'General',
  initiated: 'Initiated',
  implemented: 'Implemented',
  reviewer: 'Reviewer',
  approver: 'Approver',
  action_owner: 'Action Owner',
  amm_initiator: 'Initiator',
  amm_verifier: 'Verifier',
  amm_approver: 'Approver',
  amm_auditor: 'Auditor',
  amm_auditee: 'Auditee',
  vmm_initiator: 'Initiator',
  vmm_verifier: 'Verifier',
};

export const ini_stage_alias = {
  initiator: 'Change Control Initiation',
  process_reviewer: 'Change Control Evaluation',
  qa_reviewer: 'Change Control Verification',
  qa_approver: 'Change Control Approval',
  initiated: 'Change Control Approved',
};

// ECM MCI Table
export const ecm_misc_tables = {
  initiator: 'Misconduct Initiation',
  process_reviewer: 'Threshold Assessment',
  qa_reviewer: 'Investigation Review',
  qa_approver: 'Case Finalization',
  // initiated: 'Change Control Approved',
};

export const dnc_ini_stage_alias = {
  initiator: 'Deviation Initiation',
  process_reviewer: 'Deviation Evaluation',
  qa_reviewer: 'Deviation Verification',
  qa_approver: 'Deviation Closure',
  initiated: 'Pending for CAPA',
};
export const nco_ini_stage_alias = {
  initiator: 'Initiator',
  verifier: 'verifier',
  qa_approver: 'Approver',
  qa_reviewer: 'verifier',
  general: 'General',
};
export const nco_ini_stage_alias_state = {
  initiator: 'Initiation',
  verifier: 'Notification',
  qa_approver: 'Approval',
  qa_reviewer: 'Verification',
  //general: 'General',
};
export const nco_imp_stage_alias = {
  implemented: 'CAPA Closed',
  initiator: 'CAPA Assignment',
  qa_approver: 'CAPA Closure',
  qa_reviewer: 'CAPA Implementation Verification',
};

export const rm_ini_stage_alias = {
  initiator: 'Risk Registration',
  process_reviewer: 'Risk Evaluation',
  qa_reviewer: 'Risk Verification',
  qa_approver: 'Risk Registration Approval',
  initiated: 'Mitigate Risk',
};

export const mf_stage_alias = {
  initiator: 'Memo to File Initiation',
  reviewer: 'Memo to File Reviewer',
  approver: 'Memo to File Approver',
  completed: 'Memo to File Completed',
};

export const nco_stage_alias = {
  initiator: 'CAPA Plan Initiation',
  qa_reviewer: 'CAPA Plan Verification',
  qa_approver: 'CAPA Plan Approval',
  completed: 'CAPA Plan Completed',
};

export const imp_stage_alias = {
  initiator: 'Change Control Implementation',
  process_reviewer: 'Process Implementation Review',
  qa_reviewer: 'Implementation Verification',
  qa_approver: 'Change Control Closure',
  implemented: 'Change Closed',
};

export const dnc_imp_stage_alias = {
  initiator: 'CAPA Implementation',
  process_reviewer: 'CAPA Implementation Review',
  qa_reviewer: 'CAPA Implementation Verification',
  qa_approver: 'CAPA Closure',
  implemented: 'CAPA Closed',
};

export const rm_imp_stage_alias = {
  initiator: 'Risk Mitigation',
  process_reviewer: 'Risk Mitigation Review',
  qa_reviewer: 'Risk Mitigation Verification',
  qa_approver: 'Risk Control Closure',
  implemented: 'Risk Mitigation Closed',
};

export const label_alias = {
  cc_no: 'Change Control No',
  cci_initiation_date: 'Change Control Initiated Date',
  cci_organisation: 'Organization',
  cci_department: 'Department',
  cci_project_name: 'Project Name',
  cci_purpose: 'Change Control Purpose',
  cci_summary: 'Change Control Summary',
  cci_proposed_change: 'Details of proposed change',
  cci_process: 'Existing Process/Procedure',
  cce_eval_risk: 'Evaluation and Risk assessment',
  cce_training: 'Training needed, if any',
  cce_comments1: 'Additional Comments',
  cce_status: 'Status',
  ccr_review_comments: 'Change Control Verification Comments',
  ccr_status: 'Status',
  ccr_comments1: 'Additional Comments',
  cca_review_comments: 'Change Control Approval Comments',
  cca_comments1: 'Additional Comments',
  cca_status: 'Status',
  imp_no: 'Change Control Implementation No',
  cc_impl_comments: 'Implementation Comments',
  cc_impl_wf_comments: 'Additional Comments',
  pir_review_comments: 'Process Implementation Review comments',
  pir_lessons_learnt: 'Lessons learnt',
  pir_wf_comments: 'Additional Comments',
  qair_review_comments: 'Change Control implementation Verification Comments',
  qair_wf_comments: 'Additional Comments',
  ccc_review_comments: 'Change Closure Review comments',
  ccc_wf_comments: 'Additional Comments',
  ccc_change: 'Change Successful',
  rcomments: 'Reviewer Comment',
  acomments: 'Approver Comment',
};

export const dnc_label_alias = {
  cc_no: 'Change Control No',
  cci_initiation_date: 'Change Control Initiated Date',
  cci_organisation: 'Organization',
  cci_department: 'Department',
  cci_project_name: 'Project Name',
  cci_purpose: 'Change Control Purpose',
  cci_summary: 'Change Control Summary',
  cci_proposed_change: 'Details of proposed change',
  cci_process: 'Existing Process/Procedure',
  de_rca: 'Root Cause Analysis',
  de_ia: 'Impact Assessment',
  de_comments: 'Additional Comments',
  cce_status: 'Status',
  dr_review_comments: 'Deviation Verification Comments',
  ccr_status: 'Status',
  dr_comments1: 'Additional Comments',
  da_review_comments: 'Approval Comments',
  da_comments1: 'Additional Comments',
  cca_status: 'Status',
  imp_no: 'CAPA No',
  cc_impl_comments: 'Implementation Comments',
  cc_impl_wf_comments: 'Additional Comments',
  car_review_comments: 'CAPA Implementation Review comments',
  car_lessons_learnt: 'Lessons learnt',
  pir_wf_comments: 'Additional Comments',
  cair_review_comments: 'CAPA Implementation Verification Comments',
  qair_wf_comments: 'Additional Comments',
  cac_review_comments: 'CAPA Closure comments',
  ccc_wf_comments: 'Additional Comments',
  caec_required: 'CAPA Effectiveness Check required',
  rcomments: 'Reviewer Comment',
  acomments: 'Approver Comment',
};

export const nco_label_alias = {
  cc_no: 'Change Control No',
  cci_initiation_date: 'Change Control Initiated Date',
  cci_organisation: 'Organization',
  cci_department: 'Department',
  cci_project_name: 'Project Name',
  cci_purpose: 'Change Control Purpose',
  cci_summary: 'Change Control Summary',
  cci_proposed_change: 'Details of proposed change',
  cci_process: 'Existing Process/Procedure',
  de_rca: 'Root Cause Analysis',
  de_ia: 'Impact Assessment',
  de_comments: 'Additional Comments',
  cce_status: 'Status',
  dr_review_comments: 'Verification Comments',
  ccr_status: 'Status',
  dr_comments1: 'Additional Comments',
  da_review_comments: 'Approval Comments',
  da_comments1: 'Additional Comments',
  cca_status: 'Status',
  imp_no: 'CAPA No',
  cc_impl_comments: 'Implementation Comments',
  cc_impl_wf_comments: 'Additional Comments',
  car_review_comments: 'CAPA Implementation Review comments',
  car_lessons_learnt: 'Lessons learnt',
  pir_wf_comments: 'Additional Comments',
  cair_review_comments: 'CAPA Implementation Verification Comments',
  qair_wf_comments: 'Additional Comments',
  cac_review_comments: 'CAPA Closure comments',
  ccc_wf_comments: 'Additional Comments',
  caec_required: 'CAPA Effectiveness Check required',
  rcomments: 'Reviewer Comment',
  acomments: 'Approver Comment',
};

export const rm_label_alias = {
  cc_no: 'Change Control No',
  cci_initiation_date: 'Change Control Initiated Date',
  cci_organisation: 'Organization',
  cci_department: 'Department',
  cci_project_name: 'Project Name',
  cci_purpose: 'Change Control Purpose',
  cci_summary: 'Change Control Summary',
  cci_proposed_change: 'Details of proposed change',
  cci_process: 'Existing Process/Procedure',
  re_ra: 'Risk Analysis',
  re_ia: 'Impact Assessment',
  re_comments: 'Additional Comments',
  cce_status: 'Status',
  rr_review_comments: 'Risk Verification Comments',
  ccr_status: 'Status',
  rr_comments1: 'Additional Comments',
  ra_review_comments: 'Approval Comments',
  ra_comments1: 'Additional Comments',
  cca_status: 'Status',
  imp_no: 'Risk Control Id',
  cc_impl_comments: 'Implementation Comments',
  cc_impl_wf_comments: 'Additional Comments',
  rmr_review_comments: 'Risk Mitigation Review Comments',
  rmr_lessons_learnt: 'Lessons learnt',
  pir_wf_comments: 'Additional Comments',
  qair_review_comments: 'Risk Mitigation Verification Comments',
  qair_wf_comments: 'Additional Comments',
  rcc_review_comments: 'Risk Control Closure Comments',
  ccc_wf_comments: 'Additional Comments',
  rm_possible: 'Risk Mitigation Possible',
  rcomments: 'Reviewer Comment',
  acomments: 'Approver Comment',
};

export const months = [
  'JAN',
  'FEB',
  'MAR',
  'APR',
  'MAY',
  'JUN',
  'JUL',
  'AUG',
  'SEP',
  'OCT',
  'NOV',
  'DEC',
];

export const getStatus = {
  primary: colors['primary'],
  secondary: colors['secondary'],
  approved: colors['green'],
  pending: colors['ember'],
  rejected: colors['red'],
  Emergency: colors['red'],
  High: colors['pumpkin'],
  Medium: colors['ember'],
  Low: colors['green'],
  Critical: colors['red'],
  Major: colors['pumpkin'],
  Minor: colors['green'],
};

export const sign_key = {
  initiator: 'ini_sign',
  process_reviewer: 'pr_sign',
  qa_reviewer: 'qr_sign',
  qa_approver: 'qa_sign',
};

export const ini_assignee_alias = {
  initiator: 'ini_asignee',
  process_reviewer: 'pr_asignee',
  qa_reviewer: 'qr_asignee',
  qa_approver: 'qa_asignee',
};
export const Nco_ini_assignee_alias = {
  initiator: 'ini_asignee',
  // action_owner: 'qr_asignee',
  // qa_reviewer: 'pr_asignee',
  action_owner: 'pr_asignee',
  qa_reviewer: 'qr_asignee',
  qa_approver: 'qa_asignee',
};
export const mf_assignee_alias = {
  initiator: 'ini_asignee',
  reviewer: 'r_asignee',
  approver: 'a_asignee',
};

export const imp_assignee_alias = {
  initiator: 'imp_asignee',
  process_reviewer: 'pr_imp_asignee',
  qa_reviewer: 'qr_imp_asignee',
  qa_approver: 'qa_imp_asignee',
};

export const fileTypes = {
  'image/jpeg': 'jpeg',
  'image/png': 'png',
  'image/jpg': 'jpg',
  'application/pdf': 'pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    'docx',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
};

export const ini_mail_stage = {
  initiator: 0,
  process_reviewer: 1,
  qa_reviewer: 2,
  qa_approver: 3,
};

export const mtf_mail_stage = {
  initiator: 0,
  qa_reviewer: 1,
  qa_approver: 2,
};

export const nco_mail_stage = {
  initiator: 0,
  qa_reviewer: 1,
  qa_approver: 3,
};

export const imp_mail_stage = {
  initiator: 4,
  process_reviewer: 5,
  qa_reviewer: 6,
  qa_approver: 7,
};

export const ini_route_back_alias = {
  process_reviewer: 'cce_rbc',
  qa_reviewer: 'ccr_rbc',
  qa_approver: 'cca_rbc',
};

export const imp_route_back_alias = {
  process_reviewer: 'icce_rbc',
  qa_reviewer: 'iccr_rbc',
  qa_approver: 'icca_rbc',
};

export const MODULE = {
  mtf: 'Memo To File Module',
  dnc: 'Deviation and CAPA Module',
  rm: 'Risk Management Module',
  ecm: 'Ethics Case Management',
  cmm: 'Change Management Module',
  ccm: 'Change Control Module',
  nco: 'CAPA Module',
  amm: 'Audit Management Module',
  vmm: 'Vendor Management Module',
  pdm: 'Performance Dashboard Module',
};

export const REDIRECT_MODULE = {
  'Memo To File Module': '/mtf',
  'Deviation and CAPA Module': '/dnc',
  'Risk Management Module': '/rm',
  'Ethics Case Management': '/ecm',
  'Change Management Module': '/cmm',
  'Change Control Module': '/iControl',
  'CAPA Module': '/nco',
  'Audit Management Module': '/amm',
  'Vendor Management Module': '/vmm',
};

export const MTF_REPORT_ROLES = {
  approver: 'Approver',
  initiator: 'Initiator',
  reviewer: 'Reviewer',
};

export const AuditVariables = {
  nco_classification: 'Classification',
  c_date: 'Due Date',
  current_handler: 'Current Handler',
  ini_asignee: 'Initiation Assignee',
  ini_designation: 'Initiator Designation',
  name: 'Name',
  ini_name: 'Initiator Name',
  nco_category: 'Category',
  nco_classification: 'Classification',
  nco_department: 'Department',
  nco_description: 'Description ',
  nco_identification_date: 'Identification Date',
  nco_no: 'nco_no',
  nco_organisation: 'Organization',
  nco_project_name: 'Project Name',
  nco_source: 'Source ',
  nco_summary: 'Summary',
  nco_type: 'Type',
  role: 'role',
  stage: 'stage',
  username: 'username',
};
