const initialState = {
  capa_plan_filters: null,
  capa_plan_sorting: { field: '', order: 'descend' },
  capa_plan_tabIndex: '1',
  capa_plan_request_type: false,
  action_items_request_type: true,
  action_items_filter: '',
  action_items_sorting: '',
  action_items_tabIndex: '1',

  monitor_request_type: true,
  monitor_filter: '',
  monitor_sorting: '',
  monitor_tabIndex: '1',

  capa_check_request_type: false,
  capa_impl_filter: '',
  capa_impl_sorting: '',
  capa_impl_tabIndex: '1',
};

const NcoFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_CAPA_PLAN_FILTERS':
      return {
        ...state,
        capa_plan_filters: action.payload,
      };
    case 'SET_CAPA_PLAN_SORTING':
      return {
        ...state,
        capa_plan_sorting: action.payload,
      };

    case 'SET_CAPA_PLAN_TABLE_LIST':
      return {
        ...state,
        capa_plan_table_list: action.payload,
      };

    case 'SET_NCO_REQUEST_TYPE':
      return {
        ...state,
        [action.key]: action.data,
      };

    case 'SET_NCO_FILTERS':
      return {
        ...state,
        [action.key]: action.data,
      };

    case 'SET_NCO_SORTING':
      return {
        ...state,
        [action.key]: action.data,
      };

    case 'SET_NCO_TAB':
      return {
        ...state,
        [action.key]: action.data,
      };

    case 'RESET_NCO_RESET':
      return {
        capa_plan_filters: {},
        capa_plan_sorting: { field: '', order: 'descend' },
        capa_plan_tabIndex: '1',
        capa_plan_request_type: false,
        action_items_request_type: true,
        action_items_filter: '',
        action_items_sorting: '',
        action_items_tabIndex: '1',
        capa_impl_request_type: true,
        capa_impl_filter: '',
        capa_impl_sorting: '',
        capa_impl_tabIndex: '1',
        monitor_request_type: false,
        monitor_filter: '',
        monitor_sorting: '',
        monitor_tabIndex: '1',
      };
    default:
      return state;
  }
};

export default NcoFilterReducer;
