export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';

// @param modalData --> ({type, modalType, modalProps})

export const showModal = (modalData) => (dispatch) => {
  return dispatch({
    type: SHOW_MODAL,
    data: modalData,
  });
};

export const hideModal = (modalData) => (dispatch) => {
  return dispatch({
    type: HIDE_MODAL,
    data: modalData,
  });
};
