import axios from 'axios';
export const SET_VMM_DROPDOWN_VALUES = 'SET_VMM_DROPDOWN_VALUES';
export const SET_VR_TABLE_INITIATOR = 'SET_VR_TABLE_INITIATOR';
export const SET_VR_TABLE_VERIFIER = 'SET_VR_TABLE_VERIFIER';
export const SET_VR_TABLE_APPROVER = 'SET_VR_TABLE_APPROVER';
export const CCM_CONFIG = 'CCM_CONFIG';

export const CCMConfig = () => (dispatch) => {
  try {
    const userToken = localStorage.getItem('access_token');

    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
    };
    const data = {
      type: 'global_config',
    };
    axios
      .post(`${process.env.REACT_APP_IACCESS_URL}/globalConfig/`, data, config)
      .then(({ data }) => {
        dispatch({ type: CCM_CONFIG, data });
      })
      .catch((error) => {
        console.error(error);
      });
  } catch (error) {
    console.error(error);
  }
};

export const getVmmDropdownValues = () => (dispatch) => {
  try {
    const userToken = localStorage.getItem('access_token');

    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
    };

    axios
      .post(
        `${process.env.REACT_APP_IACCESS_URL}/GetVMMFormDropdown/`,
        null,
        config
      )
      .then(({ data }) => {
        dispatch({ type: SET_VMM_DROPDOWN_VALUES, data });
      })
      .catch((error) => {
        console.error(error);
      });
  } catch (error) {
    console.error(error);
  }
};

export const setVendorRegTableRequest = (data) => (dispatch) => {
  try {
    const role = localStorage.getItem('role');
    if (role === 'vmm_initiator') {
      console.log(data, ':setVendorRegTableRequest data in vmm action');
      dispatch({ type: SET_VR_TABLE_INITIATOR, data: data });
    } else if (role === 'vmm_verifier') {
      dispatch({ type: SET_VR_TABLE_VERIFIER, data: data });
    }
  } catch (error) {
    console.error(error);
  }
};

export const sendVMMEmail = (data) => (dispatch) => {
  try {
    const userToken = localStorage.getItem('access_token');
    const name = localStorage.getItem('name');
    const username = localStorage.getItem('username');
    const organization = localStorage.getItem('organization');

    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
    };
    const formBody = {
      ...data,
      action_by: `${name} (${username})`,
      organization: organization,
    };

    axios
      .post(
        `${process.env.REACT_APP_IACCESS_URL}/sendAMMEmail/`,
        formBody,
        config
      )
      .then(() => {})
      .catch((error) => {
        console.error(error);
      });
  } catch (error) {
    console.error(error);
  }
};
