import {
  dncHttps,
  https,
  rmHttps,
  cmmHttps,
  ncoHttps,
} from '../../utils/config';
import { audit, getActivityList, SET_LOADER } from './initiationActions';
import {
  getNextAssignee,
  getAttachmentsList,
  sendEmail,
  CLEAR_ATTACHMENTS,
} from './commonActions';
import { getNCOAttachmentsList } from './nco/CAPAPlanInitiation';
import { getTaskDetails } from './commonActions';
import { hideModal } from './modalActions';
import { message } from 'antd';
import { formatExcelData } from '../../utils/helper';
import {
  imp_assignee_alias,
  imp_stage_alias,
  imp_mail_stage,
  imp_route_back_alias,
  MODULE,
  dnc_imp_stage_alias,
  rm_imp_stage_alias,
} from '../../utils/constants';

export const GET_IMPLEMENTATION_LIST = 'GET_IMPLEMENTATION_LIST';
export const IMPLEMENTATION_DETAILS = 'IMPLEMENTATION_DETAILS';
export const DELETE_LINK_CHANGE = 'DELETE_LINK_CHANGE';
export const UPDATE_LINK_CHANGE = 'UPDATE_LINK_CHANGE';
export const IMP_MODAL_DETAILS = 'IMP_MODAL_DETAILS';
export const IMP_DETAILS_ERR = 'IMP_DETAILS_ERR';
export const IMP_SEARCH = 'IMP_SEARCH';
export const SET_RETIRE_DATA = 'SET_RETIRE_DATA';
export const ADD_IMPLEMENTATION_TASK = 'ADD_IMPLEMENTATION_TASK';
export const UPDATE_IMPLEMENTATION_TASK = 'UPDATE_IMPLEMENTATION_TASK';
export const SET_CAPA_SEARCH_DATA = 'SET_CAPA_SEARCH_DATA';
export const DELETE_IMP_TASK = 'DELETE_IMP_TASK';

export const getCAPASearchList = () => (dispatch) => {
  const role = localStorage.getItem('role');
  const organization = localStorage.getItem('organization');
  // const module = localStorage.getItem('module');
  const module = localStorage.getItem('module');

  const obj = JSON.stringify({
    organization: localStorage.getItem('organization'),
  });
  ncoHttps
    .get(`/capa/effectiveCapaList.php?organisation=${organization}`)
    .then(({ data }) => {
      //     const res = data.filter((d) => d.rm_stage === role);
      const excelData = [];
      dispatch({ type: SET_CAPA_SEARCH_DATA, data });
    })
    .catch((err) => console.log(err));

  return;
};

export const getImplementationList = () => (dispatch) => {
  const role = localStorage.getItem('role');
  const organization = localStorage.getItem('organization');
  // const module = localStorage.getItem('module');
  const module = localStorage.getItem('module');

  if (module === MODULE.rm) {
    rmHttps
      .get(`/getimplementationView.php?organization=${organization}`)
      .then(({ data }) => {
        const res = data.filter((d) => d.rm_stage === role);
        const excelData = formatExcelData('implementation', res);
        dispatch({ type: GET_IMPLEMENTATION_LIST, data: res, excelData });
      })
      .catch((err) => console.log(err));

    return;
  }

  if (module === MODULE.dnc) {
    dncHttps
      .get(`/getimplementationView.php?organization=${organization}`)
      .then(({ data }) => {
        const res = data.filter((d) => d.capa_stage === role);
        const excelData = formatExcelData('implementation', res);
        dispatch({ type: GET_IMPLEMENTATION_LIST, data: res, excelData });
      })
      .catch((err) => console.log(err));

    return;
  }

  if (module === MODULE.cmm) {
    cmmHttps
      .get(`/getimplementationView.php?organization=${organization}`)
      .then(({ data }) => {
        const res = data.filter((d) => d.imp_stage === role);
        const excelData = formatExcelData('implementation', res);
        dispatch({ type: GET_IMPLEMENTATION_LIST, data: res, excelData });
      })
      .catch((err) => console.log(err));
    return;
  }

  https
    .get(`/getimplementationView.php?organization=${organization}`)
    .then(({ data }) => {
      const res = data.filter((d) => d.imp_stage === role);
      const excelData = formatExcelData('implementation', res);
      dispatch({ type: GET_IMPLEMENTATION_LIST, data: res, excelData });
    })
    .catch((err) => console.log(err));
};

export const getImplementationDetails = (formData) => (dispatch) => {
  const obj = JSON.stringify(formData);
  // const module = localStorage.getItem('module');
  const module = localStorage.getItem('module');

  if (module === MODULE.rm) {
    rmHttps
      .post('/getImplementation.php', obj)
      .then(({ data }) => {
        const res = data[0];
        const attachments = data[1] ? data[1] : null;
        dispatch({ type: IMPLEMENTATION_DETAILS, data: res, attachments });
        dispatch(getActivityList(obj));
        dispatch(getNextAssignee());
        dispatch(getAttachmentsList(obj));
      })
      .catch((err) => console.log(err));
    return;
  }

  if (module === MODULE.dnc) {
    dncHttps
      .post('/getImplementation.php', obj)
      .then(({ data }) => {
        const res = data[0];
        const attachments = data[1] ? data[1] : null;
        dispatch({ type: IMPLEMENTATION_DETAILS, data: res, attachments });
        dispatch(getActivityList(obj));
        dispatch(getNextAssignee());
        dispatch(getAttachmentsList(obj));
      })
      .catch((err) => console.log(err));
    return;
  }

  if (module === MODULE.cmm) {
    cmmHttps
      .post('/getImplementation.php', obj)
      .then(({ data }) => {
        const res = data[0];
        const attachments = data[1] ? data[1] : null;
        dispatch({ type: IMPLEMENTATION_DETAILS, data: res, attachments });
        dispatch(getActivityList(obj));
        dispatch(getNextAssignee());
        dispatch(getAttachmentsList(obj));
      })
      .catch((err) => console.log(err));
    return;
  }

  if (module === MODULE.nco) {
    ncoHttps
      .post('/getImplementation.php', obj)
      .then(({ data }) => {
        const res = data[0];
        const attachments = data[1] ? data[1] : null;
        dispatch({ type: IMPLEMENTATION_DETAILS, data: res, attachments });
        dispatch(getActivityList(obj));
        dispatch(getNextAssignee());
        dispatch(getNCOAttachmentsList(formData.nco_no));
      })
      .catch((err) => console.log(err));
    return;
  }

  https
    .post('/getImplementation.php', obj)
    .then(({ data }) => {
      const res = data[0];
      const attachments = data[1] ? data[1] : null;
      dispatch({ type: IMPLEMENTATION_DETAILS, data: res, attachments });
      dispatch(getActivityList(obj));
      dispatch(getNextAssignee());
      dispatch(getAttachmentsList(obj));
    })
    .catch((err) => console.log(err));
};

export const getCCDetails = (formData) => (dispatch) => {
  const obj = JSON.stringify(formData);
  // const module = localStorage.getItem('module');
  const module = localStorage.getItem('module');

  if (module === MODULE.rm) {
    rmHttps
      .post('/getImplementation.php', obj)
      .then(({ data }) => {
        const res = data[0];
        dispatch({ type: IMP_MODAL_DETAILS, data: res });
        dispatch(getActivityList(obj));
      })
      .catch((err) => {
        dispatch({ type: IMP_DETAILS_ERR, error: true });
        console.log(err);
      });
    return;
  }

  if (module === MODULE.dnc) {
    dncHttps
      .post('/getImplementation.php', obj)
      .then(({ data }) => {
        const res = data[0];
        dispatch({ type: IMP_MODAL_DETAILS, data: res });
        dispatch(getActivityList(obj));
      })
      .catch((err) => {
        dispatch({ type: IMP_DETAILS_ERR, error: true });
        console.log(err);
      });
    return;
  }

  if (module === MODULE.cmm) {
    cmmHttps
      .post('/getImplementation.php', obj)
      .then(({ data }) => {
        const res = data[0];
        dispatch({ type: IMP_MODAL_DETAILS, data: res });
        dispatch(getActivityList(obj));
      })
      .catch((err) => {
        dispatch({ type: IMP_DETAILS_ERR, error: true });
        console.log(err);
      });
    return;
  }

  https
    .post('/getImplementation.php', obj)
    .then(({ data }) => {
      const res = data[0];
      dispatch({ type: IMP_MODAL_DETAILS, data: res });
      dispatch(getActivityList(obj));
    })
    .catch((err) => {
      dispatch({ type: IMP_DETAILS_ERR, error: true });
      console.log(err);
    });
};

export const getImpDetails = (formData) => (dispatch) => {
  console.log('getImpDetails');
  const obj = JSON.stringify(formData);
  // const module = localStorage.getItem('module');
  const module = localStorage.getItem('module');

  if (module === MODULE.rm) {
    rmHttps
      .post('/getChangeLinkImplementation.php', obj)
      .then(({ data }) => {
        console.log(data);
        const res = data[0];
        dispatch({ type: IMP_MODAL_DETAILS, data: res });
        dispatch(getActivityList(obj));
      })
      .catch((err) => {
        dispatch({ type: IMP_DETAILS_ERR, error: true });
        console.log(err);
      });
    return;
  }

  if (module === MODULE.dnc) {
    dncHttps
      .post('/getChangeLinkImplementation.php', obj)
      .then(({ data }) => {
        console.log(data);
        const res = data[0];
        dispatch({ type: IMP_MODAL_DETAILS, data: res });
        dispatch(getActivityList(obj));
      })
      .catch((err) => {
        dispatch({ type: IMP_DETAILS_ERR, error: true });
        console.log(err);
      });
    return;
  }

  if (module === MODULE.cmm) {
    cmmHttps
      .post('/getChangeLinkImplementation.php', obj)
      .then(({ data }) => {
        console.log(data);
        const res = data[0];
        dispatch({ type: IMP_MODAL_DETAILS, data: res });
        dispatch(getActivityList(obj));
      })
      .catch((err) => {
        dispatch({ type: IMP_DETAILS_ERR, error: true });
        console.log(err);
      });
    return;
  }

  https
    .post('/getChangeLinkImplementation.php', obj)
    .then(({ data }) => {
      console.log(data);
      const res = data[0];
      dispatch({ type: IMP_MODAL_DETAILS, data: res });
      dispatch(getActivityList(obj));
    })
    .catch((err) => {
      dispatch({ type: IMP_DETAILS_ERR, error: true });
      console.log(err);
    });
};

export const implement =
  (formbody, history, attachment_role, flow) => async (dispatch) => {
    const obj = JSON.stringify(formbody);
    const assignee = imp_assignee_alias[formbody.role];
    const role = localStorage.getItem('role');
    // const module = localStorage.getItem('module');
    const module = localStorage.getItem('module');

    if (module === MODULE.rm) {
      try {
        await audit({ ...formbody, stage: rm_imp_stage_alias[role] });
      } catch (err) {
        message.error('Error Submitting! Try again.');
        dispatch({ type: SET_LOADER, data: false });
        return;
      }

      const mailData = {
        email_type:
          formbody.role === 'qa_approver'
            ? formbody.rm_required === 'yes'
              ? 'change_successful'
              : 'change_unsuccessful'
            : 'stage_complete',
        stage: rm_imp_stage_alias[formbody.role],
        stage_index: imp_mail_stage[formbody.role],
        action_by: formbody.username,
        risk_id: formbody.risk_id,
      };

      const mailObj =
        formbody.role !== 'qa_approver'
          ? {
              ...mailData,
              assigned_to: formbody[assignee],
              assigned_comment: formbody.activity_comments,
            }
          : mailData;

      rmHttps
        .post('/newImplementation.php', obj)
        .then(() => {
          sendEmail(mailObj);
          message.success('Form Submission Successful.');
          dispatch(
            hideModal({
              type: 'HIDE_MODAL',
              modalType: 'SIGNATURE_FORM',
              modalProps: { show: false },
            })
          );
          history.push('/rm/implementation');
        })
        .catch((err) => {
          message.error('Error Submitting! Try again.');
          console.log(err);
        });
      return;
    }

    if (module === MODULE.nco) {
      // try {
      //   await audit({ ...formbody, stage: rm_imp_stage_alias[role] });
      // } catch (err) {
      //   message.error('Error Submitting! Try again.');
      //   dispatch({ type: SET_LOADER, data: false });
      //   return;
      // }

      // const mailData = {
      //   email_type:
      //     formbody.role === 'qa_approver'
      //       ? formbody.rm_required === 'yes'
      //         ? 'change_successful'
      //         : 'change_unsuccessful'
      //       : 'stage_complete',
      //   stage: rm_imp_stage_alias[formbody.role],
      //   stage_index: imp_mail_stage[formbody.role],
      //   action_by: formbody.username,
      //   risk_id: formbody.risk_id,
      // };

      // const mailObj =
      //   formbody.role !== 'qa_approver'
      //     ? {
      //         ...mailData,
      //         assigned_to: formbody[assignee],
      //         assigned_comment: formbody.activity_comments,
      //       }
      //     : mailData;
      const attahment_role = localStorage.getItem('role');
      const flow = 'implementation';
      console.log(attahment_role);
      console.log(flow);

      ncoHttps
        .post('/newImplementation.php', obj, attahment_role, flow)
        .then(() => {
          // sendEmail(mailObj);
          message.success('Form Submission Successful.');
          dispatch(
            hideModal({
              type: 'HIDE_MODAL',
              modalType: 'SIGNATURE_FORM',
              modalProps: { show: false },
            })
          );

          dispatch(getImplementationDetails(formbody))();
          // history.push('/nco/implementation');
        })
        .catch((err) => {
          message.error('Error Submitting! Try again.');
          console.log(err);
        });
      return;
    }

    if (module === MODULE.dnc) {
      try {
        await audit({ ...formbody, stage: dnc_imp_stage_alias[role] });
      } catch (err) {
        message.error('Error Submitting! Try again.');
        dispatch({ type: SET_LOADER, data: false });
        return;
      }

      const mailData = {
        email_type:
          formbody.role === 'qa_approver'
            ? formbody.caec_required === 'yes'
              ? 'change_successful'
              : 'change_unsuccessful'
            : 'stage_complete',
        stage: dnc_imp_stage_alias[formbody.role],
        stage_index: imp_mail_stage[formbody.role],
        action_by: formbody.username,
        d_no: formbody.d_no,
      };

      const mailObj =
        formbody.role !== 'qa_approver'
          ? {
              ...mailData,
              assigned_to: formbody[assignee],
              assigned_comment: formbody.activity_comments,
            }
          : mailData;

      dncHttps
        .post('/newImplementation.php', obj)
        .then(() => {
          sendEmail(mailObj);
          message.success('Form Submission Successful.');
          dispatch(
            hideModal({
              type: 'HIDE_MODAL',
              modalType: 'SIGNATURE_FORM',
              modalProps: { show: false },
            })
          );
          history.push('/dnc/implementation');
        })
        .catch((err) => {
          message.error('Error Submitting! Try again.');
          console.log(err);
        });
      return;
    }

    try {
      await audit({ ...formbody, stage: imp_stage_alias[role] });
    } catch (err) {
      message.error('Error Submitting! Try again.');
      dispatch({ type: SET_LOADER, data: false });
      return;
    }

    const mailData = {
      email_type:
        formbody.role === 'qa_approver'
          ? formbody.ccc_change === 'yes'
            ? 'change_successful'
            : 'change_unsuccessful'
          : 'stage_complete',
      stage: imp_stage_alias[formbody.role],
      stage_index: imp_mail_stage[formbody.role],
      action_by: formbody.username,
      cc_no: formbody.cc_no,
    };

    const mailObj =
      formbody.role !== 'qa_approver'
        ? {
            ...mailData,
            assigned_to: formbody[assignee],
            assigned_comment: formbody.activity_comments,
          }
        : mailData;

    if (module === MODULE.cmm) {
      cmmHttps
        .post('/newImplementation.php', obj)
        .then(() => {
          sendEmail(mailObj);
          message.success('Form Submission Successful.');
          dispatch(
            hideModal({
              type: 'HIDE_MODAL',
              modalType: 'SIGNATURE_FORM',
              modalProps: { show: false },
            })
          );
          history.push('/iControl/implementation');
        })
        .catch((err) => {
          message.error('Error Submitting! Try again.');
          console.log(err);
        });
      return;
    }
    https
      .post('/newImplementation.php', obj)
      .then(() => {
        sendEmail(mailObj);
        message.success('Form Submission Successful.');
        dispatch(
          hideModal({
            type: 'HIDE_MODAL',
            modalType: 'SIGNATURE_FORM',
            modalProps: { show: false },
          })
        );
        history.push('/iControl/implementation');
      })
      .catch((err) => {
        message.error('Error Submitting! Try again.');
        console.log(err);
      });
  };

export const implementCapaVerification = (formbody, history) => (dispatch) => {
  const obj = JSON.stringify(formbody);
  const assignee = imp_assignee_alias[formbody.role];
  const role = localStorage.getItem('role');
  // const module = localStorage.getItem('module');
  const module = localStorage.getItem('module');
  const mailObj = {
    email_type: 'capa_accepted',
    stage: 'CAPA Implementation Verification',
    capa_items: [formbody.capa_no],
    stage_index: 1,
    action_by: localStorage.getItem('username'),
    assigned_comment: formbody.activity_comments,
    nco_no: formbody.nco_no,
  };

  const mailObj2 = {
    email_type: 'capa_rejected',
    stage: 'CAPA Implementation Verification',
    stage_index: 2,
    action_by: formbody.username,
    capa_items: [formbody.capa_no],
    route_comment: formbody.verifer_comment,
    nco_no: formbody.nco_no,
    indicator: formbody.type,
  };
  ncoHttps
    .post('capa/acceptOrRejectCAPA.php', formbody)
    .then(() => {
      dispatch(
        hideModal({
          type: 'HIDE_MODAL',
          modalType: 'SIGNATURE_FORM',
          modalProps: { show: false },
        })
      );
      if (formbody.verifier_response === 'false') {
        sendEmail(mailObj2);
        history.push(`/nco/implementation`);
      } else {
        sendEmail(mailObj);
        dispatch(
          getTaskDetails({
            id: formbody.nco_no,
            capa_no: formbody.capa_no,
          })
        );
      }
    })
    .catch((err) => {
      // message.error('Error Submitting! Try again.');
      console.log(err);
    });
};

export const implementCapaClosure = (formbody, history) => async (dispatch) => {
  console.log(formbody);
  const obj = JSON.stringify(formbody);
  //const assignee = imp_assignee_alias[formbody.role];
  const role = localStorage.getItem('role');
  // const module = localStorage.getItem('module');
  const module = localStorage.getItem('module');
  const mailObj = {
    email_type: `capa_closed`,
    stage: 'CAPA Plan Verification',
    capa_items: [formbody.capa_no],
    stage_index: 1,
    action_by: localStorage.getItem('username'),
    assigned_comment: formbody.activity_comments,
    nco_no: formbody.nco_no,
  };
  ncoHttps
    .post('capa/close.php', obj)
    .then(() => {
      if (formbody.type === 'closure') {
        sendEmail(mailObj);
      }

      message.success('Form Submission Successful.');
      dispatch(
        hideModal({
          type: 'HIDE_MODAL',
          modalType: 'SIGNATURE_FORM',
          modalProps: { show: false },
        })
      );
      if (formbody.type == 'revert') {
        dispatch(
          getTaskDetails({
            id: formbody.nco_no,
            capa_no: formbody.capa_no,
          })
        );
      } else {
        history.push(`/nco/implementation`);
      }
    })
    .catch((err) => {
      // message.error('Error Submitting! Try again.');
      console.log(err);
    });
};

export const impRouteBack = (formbody, history, callback) => () => {
  const obj = JSON.stringify(formbody);
  const comments = imp_route_back_alias[formbody.role];
  // const module = localStorage.getItem('module');
  const module = localStorage.getItem('module');

  if (module === MODULE.rm) {
    const mailObj = {
      email_type: 'route_back',
      stage: rm_imp_stage_alias[formbody.role],
      stage_index: imp_mail_stage[formbody.role],
      action_by: formbody.username,
      route_comment: formbody[comments],
      risk_id: formbody.risk_id,
    };

    rmHttps
      .post('/implementationrouteBack.php', obj)
      .then(() => {
        sendEmail(mailObj);
        callback();
        history.push('/rm/implementation');
      })
      .catch((err) => console.log(err));
    return;
  }

  if (module === MODULE.dnc) {
    const mailObj = {
      email_type: 'route_back',
      stage: dnc_imp_stage_alias[formbody.role],
      stage_index: imp_mail_stage[formbody.role],
      action_by: formbody.username,
      route_comment: formbody[comments],
      d_no: formbody.d_no,
    };

    dncHttps
      .post('/implementationrouteBack.php', obj)
      .then(() => {
        sendEmail(mailObj);
        callback();
        history.push('/dnc/implementation');
      })
      .catch((err) => console.log(err));
    return;
  }
  if (module === MODULE.nco) {
    const mailObj = {
      email_type: 'route_back',
      stage: dnc_imp_stage_alias[formbody.role],
      stage_index: imp_mail_stage[formbody.role],
      action_by: formbody.username,
      route_comment: formbody[comments],
      d_no: formbody.d_no,
    };

    ncoHttps
      .post('/implementationrouteBack.php', obj)
      .then(() => {
        sendEmail(mailObj);
        callback();
        history.push('/nco/implementation');
      })
      .catch((err) => console.log(err));
    return;
  }

  const mailObj = {
    email_type: 'route_back',
    stage: imp_stage_alias[formbody.role],
    stage_index: imp_mail_stage[formbody.role],
    action_by: formbody.username,
    route_comment: formbody[comments],
    cc_no: formbody.cc_no,
  };

  if (module === MODULE.cmm) {
    cmmHttps
      .post('/implementationrouteBack.php', obj)
      .then(() => {
        sendEmail(mailObj);
        callback();
        history.push('/iControl/implementation');
      })
      .catch((err) => console.log(err));
    return;
  }

  https
    .post('/implementationrouteBack.php', obj)
    .then(() => {
      sendEmail(mailObj);
      callback();
      history.push('/iControl/implementation');
    })
    .catch((err) => console.log(err));
};

export const searchImplementation = (imp_no) => (dispatch) => {
  const organization = localStorage.getItem('organization');
  // const module = localStorage.getItem('module');
  const module = localStorage.getItem('module');
  console.log(imp_no);

  if (module === MODULE.rm) {
    rmHttps
      .get(
        `/searchLinkChangeImplementation.php?organization=${organization}&search=${imp_no}`
      )
      .then(({ data }) => {
        dispatch({
          type: IMP_SEARCH,
          data: data,
        });
      })
      .catch((err) => {
        dispatch({
          type: IMP_SEARCH,
          data: [],
        });
        console.log(err.response.data);
      });
    return;
  }

  if (module === MODULE.dnc) {
    dncHttps
      .get(
        `/searchLinkChangeImplementation.php?organization=${organization}&search=${imp_no}`
      )
      .then(({ data }) => {
        dispatch({
          type: IMP_SEARCH,
          data: data,
        });
      })
      .catch((err) => {
        dispatch({
          type: IMP_SEARCH,
          data: [],
        });
        console.log(err.response.data);
      });
    return;
  }

  if (module === MODULE.nco) {
    ncoHttps
      .get(
        `/searchLinkChangeImplementation.php?organization=${organization}&search=${imp_no}`
      )
      .then(({ data }) => {
        dispatch({
          type: IMP_SEARCH,
          data: data,
        });
      })
      .catch((err) => {
        dispatch({
          type: IMP_SEARCH,
          data: [],
        });
        console.log(err.response.data);
      });
    return;
  }
  if (module === MODULE.cmm) {
    cmmHttps
      .get(
        `/searchLinkChangeImplementation.php?organization=${organization}&search=${imp_no}`
      )
      .then(({ data }) => {
        dispatch({
          type: IMP_SEARCH,
          data: data,
        });
      })
      .catch((err) => {
        dispatch({
          type: IMP_SEARCH,
          data: [],
        });
        console.log(err.response.data);
      });
    return;
  }

  https
    .get(
      `/searchLinkChangeImplementation.php?organization=${organization}&search=${imp_no}`
    )
    .then(({ data }) => {
      dispatch({
        type: IMP_SEARCH,
        data: data,
      });
    })
    .catch((err) => {
      dispatch({
        type: IMP_SEARCH,
        data: [],
      });
      console.log(err.response.data);
    });
};

export const addImplementationTask = (formData, history) => (dispatch) => {
  const obj = JSON.stringify(formData);
  const module = localStorage.getItem('module');
  // const module = localStorage.getItem('module');

  if (module === MODULE.rm) {
    const mailObj = {
      email_type: 'task_created',
      stage: 'Risk Mitigation',
      stage_index: 4,
      action_by: localStorage.getItem('username'),
      d_no: formData.d_no,
    };

    rmHttps
      .post(`/newTaskImplementation.php`, obj)
      .then(() => {
        sendEmail(mailObj);
        dispatch(
          hideModal({
            type: 'HIDE_MODAL',
            modalType: 'SIGNATURE_FORM',
            modalProps: { show: false },
          })
        );
        history.push('/rm/implementation');
        message.success('Tasks added successfully.');
      })
      .catch((err) => {
        console.log(err.response.data);
      });
    return;
  }

  if (module === MODULE.dnc) {
    const mailObj = {
      email_type: 'capa_create',
      stage: 'CAPA Implementation',
      stage_index: 4,
      action_by: localStorage.getItem('username'),
      d_no: formData.d_no,
    };

    dncHttps
      .post(`/newTaskImplementation.php`, obj)
      .then(() => {
        sendEmail(mailObj);
        dispatch(
          hideModal({
            type: 'HIDE_MODAL',
            modalType: 'SIGNATURE_FORM',
            modalProps: { show: false },
          })
        );
        history.push('/dnc/implementation');
        message.success('Tasks added successfully.');
      })
      .catch((err) => {
        console.log(err.response.data);
      });
    return;
  }

  if (module === MODULE.nco) {
    // const mailObj = {
    //   email_type: 'capa_create',
    //   stage: 'CAPA Implementation',
    //   stage_index: 4,
    //   action_by: localStorage.getItem('username'),
    //   nco_no: formData.nco_no,
    // };
    // console.log(obj);

    ncoHttps
      .post(`/newTaskImplementation.php`, obj)
      .then(() => {
        // sendEmail(mailObj);
        dispatch(
          hideModal({
            type: 'HIDE_MODAL',
            modalType: 'SIGNATURE_FORM',
            modalProps: { show: false },
          })
        );
        // history.push('/nco/implementation');
        // message.success('Tasks added successfully.');
      })
      .catch((err) => {
        console.log(err);
      });
    return;
  }

  const mailObj = {
    email_type: 'task_created',
    stage: 'Change Control Implementation',
    stage_index: 4,
    action_by: localStorage.getItem('username'),
    cc_no: formData.cc_no,
  };

  if (module === MODULE.cmm) {
    cmmHttps
      .post(`/newTaskImplementation.php`, obj)
      .then(() => {
        sendEmail(mailObj);
        dispatch(
          hideModal({
            type: 'HIDE_MODAL',
            modalType: 'SIGNATURE_FORM',
            modalProps: { show: false },
          })
        );
        history.push('/iControl/implementation');
        message.success('Tasks added successfully.');
      })
      .catch((err) => {
        console.log(err.response.data);
      });
    return;
  }

  https
    .post(`/newTaskImplementation.php`, obj)
    .then(() => {
      sendEmail(mailObj);
      dispatch(
        hideModal({
          type: 'HIDE_MODAL',
          modalType: 'SIGNATURE_FORM',
          modalProps: { show: false },
        })
      );
      history.push('/iControl/implementation');
      message.success('Tasks added successfully.');
    })
    .catch((err) => {
      console.log(err.response.data);
    });
};

export const addImplementationToVerifier =
  (formData, history) => (dispatch) => {
    const obj = JSON.stringify({
      ...formData,
      audit_stage: 'CAPA Plan Notification',
    });
    const module = localStorage.getItem('module');
    console.log(formData);

    ncoHttps
      .post(`/newImplementation.php`, obj)
      .then(() => {
        // sendEmail(mailObj);
        dispatch(
          hideModal({
            type: 'HIDE_MODAL',
            modalType: 'SIGNATURE_FORM',
            modalProps: { show: false },
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

export const updateImplementationTask = (formData, mailtype) => (dispatch) => {
  const obj = JSON.stringify(formData);
  const module = localStorage.getItem('module');
  if (module === MODULE.cmm) {
    cmmHttps
      .post(`/updateTask.php`, obj)
      .then((_) => {
        sendEmail({
          ...formData,
          [mailtype.type]: mailtype.action,
        });
        dispatch(
          hideModal({
            type: 'HIDE_MODAL',
            modalType: 'SIGNATURE_FORM',
            modalProps: { show: false },
          })
        );
        dispatch({
          type: UPDATE_IMPLEMENTATION_TASK,
          data: formData,
        });
        message.success('Successfully Updated');
      })
      .catch((err) => {
        console.log(err.response.data);
      });
    return;
  }
  https
    .post(`/updateTask.php`, obj)
    .then((_) => {
      sendEmail({
        ...formData,
        [mailtype.type]: mailtype.action,
      });
      dispatch(
        hideModal({
          type: 'HIDE_MODAL',
          modalType: 'SIGNATURE_FORM',
          modalProps: { show: false },
        })
      );
      dispatch({
        type: UPDATE_IMPLEMENTATION_TASK,
        data: formData,
      });
      message.success('Successfully Updated');
    })
    .catch((err) => {
      console.log(err.response.data);
    });
};

export const deleteImplementationTask = (formData, mailObj) => (dispatch) => {
  const obj = JSON.stringify(formData);
  // const module = localStorage.getItem('module');
  const module = localStorage.getItem('module');

  if (module === MODULE.rm) {
    rmHttps
      .post('/deleteTasks.php', obj)
      .then((_) => {
        sendEmail(mailObj);
        dispatch({
          type: DELETE_IMP_TASK,
          id: formData.id,
        });
      })
      .catch((err) => console.log(err));
    return;
  }

  if (module === MODULE.dnc) {
    dncHttps
      .post('/deleteTasks.php', obj)
      .then((_) => {
        sendEmail(mailObj);
        dispatch({
          type: DELETE_IMP_TASK,
          id: formData.id,
        });
      })
      .catch((err) => console.log(err));
    return;
  }

  if (module === MODULE.cmm) {
    cmmHttps
      .post('/deleteTasks.php', obj)
      .then((_) => {
        sendEmail(mailObj);
        dispatch({
          type: DELETE_IMP_TASK,
          id: formData.id,
        });
      })
      .catch((err) => console.log(err));
    return;
  }

  https
    .post('/deleteTasks.php', obj)
    .then((_) => {
      sendEmail(mailObj);
      dispatch({
        type: DELETE_IMP_TASK,
        id: formData.id,
      });
    })
    .catch((err) => console.log(err));
};

export const saveIMP = (formBody) => (dispatch) => {
  // const module = localStorage.getItem('module');
  const module = localStorage.getItem('module');
  dispatch({ type: SET_LOADER, data: true });

  if (module === MODULE.rm) {
    rmHttps
      .post('/saveImplementation.php', JSON.stringify(formBody), {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(async () => {
        message.success({
          content: 'Form saved Successful',
          key: 'updatable',
        });
        dispatch({ type: SET_LOADER, data: false });
        dispatch({ type: CLEAR_ATTACHMENTS });
        await dispatch(getImplementationDetails({ risk_id: formBody.risk_id }));
      })
      .catch((err) => {
        message.error('Error saving! Try again.');
        dispatch({ type: SET_LOADER, data: false });
        console.log(err);
      });
    return;
  }
  if (module === MODULE.nco) {
    ncoHttps
      .post('/saveImplementation.php', JSON.stringify(formBody), {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(async () => {
        message.success({
          content: 'Form saved Successful',
          key: 'updatable',
        });
        dispatch({ type: SET_LOADER, data: false });
        dispatch({ type: CLEAR_ATTACHMENTS });
        await dispatch(getImplementationDetails({ nco_no: formBody.nco_no }));
      })
      .catch((err) => {
        message.error('Error saving! Try again.');
        dispatch({ type: SET_LOADER, data: false });
        console.log(err);
      });
    return;
  }
  if (module === MODULE.dnc) {
    dncHttps
      .post('/saveImplementation.php', JSON.stringify(formBody), {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(async () => {
        message.success({
          content: 'Form saved Successful',
          key: 'updatable',
        });
        dispatch({ type: SET_LOADER, data: false });
        dispatch({ type: CLEAR_ATTACHMENTS });
        await dispatch(getImplementationDetails({ d_no: formBody.d_no }));
      })
      .catch((err) => {
        message.error('Error saving! Try again.');
        dispatch({ type: SET_LOADER, data: false });
        console.log(err);
      });
    return;
  }

  if (module === MODULE.cmm) {
    cmmHttps
      .post('/saveImplementation.php', JSON.stringify(formBody), {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(async () => {
        message.success({
          content: 'Form saved Successful',
          key: 'updatable',
        });
        dispatch({ type: SET_LOADER, data: false });
        dispatch({ type: CLEAR_ATTACHMENTS });
        await dispatch(getImplementationDetails({ cc_no: formBody.cc_no }));
      })
      .catch((err) => {
        message.error('Error saving! Try again.');
        dispatch({ type: SET_LOADER, data: false });
        console.log(err);
      });
    return;
  }

  https
    .post('/saveImplementation.php', JSON.stringify(formBody), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(async () => {
      message.success({
        content: 'Form saved Successful',
        key: 'updatable',
      });
      dispatch({ type: SET_LOADER, data: false });
      dispatch({ type: CLEAR_ATTACHMENTS });
      await dispatch(getImplementationDetails({ cc_no: formBody.cc_no }));
    })
    .catch((err) => {
      message.error('Error saving! Try again.');
      dispatch({ type: SET_LOADER, data: false });
      console.log(err);
    });
};
