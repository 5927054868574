import React, { useEffect, useState } from 'react';
import '../../../../styles/LayoutComponents/AuditPlanNotificationDetails.scss';
import { useDispatch } from 'react-redux';
import { Tabs, Steps, Spin, Empty } from 'antd';
import VendorDummyDetails from '../../LayoutComponents/Reports & Logs/vendorDummyDetails';

const { Step } = Steps;
const { TabPane } = Tabs;

const VendorQualificationDetails = () => {
  const dispatch = useDispatch();
  const [visibleTabs, setVisibleTabs] = useState([]);

  const details = {
    auditplan_status: 'Cancelled',
    vendor_no: 'ICOM-VDR-175',
    version_no: '01',
    request_type: 'true',
    organization_name: 'DHARAMPAL SATYAPAL FOODS LIMITED (GREATER NOIDA)',
    flow: 'vendor site audit',
    image:
      'https://img.freepik.com/free-vector/gradient-book-company-logo_23-2148809438.jpg',
    due_days: '8',
  };

  useEffect(() => {
    if (details.flow) {
      const flow = details.flow.toLowerCase();

      if (flow.includes('sample evaluation')) {
        setVisibleTabs([1, 2]);
      } else if (flow.includes('vendor site audit')) {
        setVisibleTabs([1, 2, 3]);
      } else if (flow.includes('commercial trial')) {
        setVisibleTabs([1, 2, 3, 4]);
      } else if (flow.includes('vendor evaluation')) {
        setVisibleTabs([]);
      } else {
        setVisibleTabs([1]);
      }
    }
  }, [details]);

  return (
    <div className="mainAP bg-dark">
      <div
        className="container mainBody p-4"
        style={{ backgroundColor: 'white' }}
      >
        {details.vendor_no ? (
          <div className="row d-flex px-3">
            <div className="col-2 d-flex flex-column align-items-start">
              <img src={details.image} height={75} alt="Company Logo" />
              <span style={{ fontSize: '1rem', fontWeight: 550 }}>
                {details.vendor_no}
              </span>
              <span
                style={{ fontSize: '1rem', fontWeight: 550}}
              >{`(Version ${details.version_no})`}</span>
            </div>

            <div className="col-10 d-flex flex-column align-items-center">
              <div className="text-center mt-3">
                <span style={{ fontSize: '1.25rem', fontWeight: 600 }}>
                  Vendor Qualification Form
                </span>
              </div>
              <div className="text-center">
                <span style={{ fontSize: '1.25rem', fontWeight: 700 }}>
                  {details.organization_name}
                </span>
              </div>
            </div>

            <div className="col-12 my-2 mb-4">
              <Steps current={1} size="small">
                {[
                  'Vendor Registration',
                  'Vendor Evaluation',
                  'Sample Evaluation',
                  'Vendor Site Audit',
                  'Commercial Trial',
                ].map((item, index) => (
                  <Step key={index} title={item} />
                ))}
              </Steps>
            </div>

            <div className="col-12 view-container-label">
              Vendor Qualification Details
            </div>

            {!details.flow.toLowerCase().includes('vendor evaluation') && (
              <div className="col-12">
                <div className="col-12 details-container">
                  <Tabs
                    defaultActiveKey="2"
                    size="small"
                    tabPosition="top"
                    className="w-100"
                  >
                    {visibleTabs.includes(2) && (
                      <TabPane key="1" tab="Vendor Registration Details">
                        <VendorDummyDetails />
                      </TabPane>
                    )}
                    {visibleTabs.includes(2) && (
                      <TabPane key="2" tab="Vendor Evaluation Details">
                        <VendorDummyDetails />
                      </TabPane>
                    )}
                    {visibleTabs.includes(3) && (
                      <TabPane key="3" tab="Sample Evaluation Details">
                        <VendorDummyDetails />
                      </TabPane>
                    )}
                    {visibleTabs.includes(4) && (
                      <TabPane key="4" tab="Vendor Site Audit Details">
                        <VendorDummyDetails />
                      </TabPane>
                    )}
                    {visibleTabs.includes(5) && (
                      <TabPane key="5" tab="Commercial Trial Details">
                        <VendorDummyDetails />
                      </TabPane>
                    )}
                  </Tabs>
                </div>
              </div>
            )}

            {details.flow.toLowerCase().includes('vendor evaluation') && (
              <div className="col-12">
                <VendorDummyDetails />
              </div>
            )}

            <div className="col-12 view-container-label">
              Vendor Activity Details
            </div>
            <div className="col-12">
              <Empty />
            </div>
          </div>
        ) : (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: '100vh' }}
          >
            <Spin tip="Loading...." />
          </div>
        )}
      </div>
    </div>
  );
};

export default VendorQualificationDetails;
