import React, { useState, useEffect } from 'react';
import { Card, Avatar, Input, InputNumber } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { Button, Upload } from 'antd';
import { withRouter } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import Attachment from './Attachment';
import { UpdateQuestion } from '../../../store/actions/vmm/vendorEvaluation';

const TextNumber = (props) => {
  const dispatch = useDispatch();
  const handleChange = (value_type, value) => {
    console.log(value_type, value);
    dispatch(
      UpdateQuestion(
        props.activeSectionNo,
        props.activeQuestionNo,
        value_type,
        value
      )
    );
  };
  return (
    <div className="p-3 row multipleChoice">
      <div className="col-12">
        <Card>
          <div
            className="font-weight-bold h6 py-1 mr-1"
            style={{ color: '#333', whiteSpace: 'nowrap' }}
          >
            {props.question_no}
          </div>
          {props.question_name}
          <div className="w-100 mt-4">
            <InputNumber
              placeholder="Enter Your Answer"
              size="large"
              className="w-100"
              value={props.activeQuestion.answer.answer}
              onChange={(value) => handleChange('answer', value)}
            />
          </div>
        </Card>
      </div>
      {props.comments && (
        <div className="col-12 d-flex mt-4">
          <Input.TextArea
            placeholder="Enter Your Comments (Non Mandatory)"
            autoSize={{ minRows: 4, maxRows: 6 }}
            value={props.activeQuestion.answer.comments}
            onChange={(e) => handleChange('comments', e.target.value)}
          />
        </div>
      )}

      {props.attachment && (
        <div className="col-12 mt-4">
          <Attachment {...props} />
        </div>
      )}
    </div>
  );
};
const mapStateToProps = ({ QPRReducer }) => ({
  activeSection: QPRReducer.activeSection,
  activeQuestion: QPRReducer.activeQuestion,
  activeQuestionNo: QPRReducer.activeQuestionNo,
  activeSectionNo: QPRReducer.activeSectionNo,
});

export default connect(mapStateToProps)(withRouter(TextNumber));
