import * as types from '../actions/vendorActions';

const initState = {
  name: null,
  username: null,
  email: null,
  organization: null,
  application: null,
  module: null,
  first_name: null,
  last_name: null,
  add_username: null,
  add_designation: null,
  created: null,
  add_name: null,
  validation: 'Waiting',
  vendor_organization: null,
  vendor_designation: null,
  vendor_mobile: null,
  btn_loader: false,
  vendor_users: null,
  vendor_data: null,
  vendor_user_data: null,
  vendor_signature_data: null,
  action_item: null,
  vendor_capa_table_data: null,
  otp_mail: 'Waiting',
};
const validateFields = (data) => {
  return (
    data &&
    data.vendor_first_name &&
    data.vendor_last_name &&
    data.username &&
    data.vendor_email &&
    data.vendor_organization &&
    data.vendor_designation &&
    data.vendor_mobile
  );
};

const vendor = (state = initState, action) => {
  switch (action.type) {
    case types.SET_VENDOR_USER_PROFILE:
      const dataValidate = validateFields(action.data);
      return {
        ...state,
        ...action.data,
        name: `${
          action.data.vendor_first_name ? action.data.vendor_first_name : ''
        } ${action.data.vendor_last_name ? action.data.vendor_last_name : ''}`,
        username: action.data.username,
        email: action.data.vendor_email,
        organization: action.data.organization,
        application: action.data.application,
        module: action.data.module,
        vendor_organization: action.data.vendor_organization,
        vendor_designation: action.data.vendor_designation,
        vendor_mobile: action.data.vendor_mobile,
        first_name: action.data.vendor_first_name,
        last_name: action.data.vendor_last_name,
        add_username: action.data.add_username,
        add_designation: action.data.add_designation,
        created: action.data.created,
        add_name: action.data.add_name,
        validation: dataValidate,
      };
    case types.TOGGLE_BUTTON_LOADER:
      return {
        ...state,
        btn_loader: action.loading,
      };
    case types.CAPA_ITEM_VENDOR_TABLE:
      return {
        ...state,
        vendor_capa_table_data: action.data,
      };
    case types.SET_VENDOR_DATA_LIST:
      return {
        ...state,
        vendor_data: action.data,
      };
    case types.SET_VENDOR_SIGNATURE_DATA:
      return {
        ...state,
        vendor_signature_data: action.data,
      };

    case types.ACTION_ITEM_VENDOR_TABLE:
      return {
        ...state,

        action_item: action.data,
      };

    case types.SET_VENDOR_USERS:
      return {
        ...state,
        vendor_users: action.data,
      };
    case types.SET_VENDOR_DATA:
      return {
        ...state,
        vendor_user_data: {
          ...action.data,
          name: `${action.data?.vendor_first_name} ${action.data?.vendor_last_name}`,
        },
      };
    case types.SET_OTP_MAIL_DATA:
      return {
        ...state,
        otp_mail: action.data,
      };

    default:
      return { ...state };
  }
};

export default vendor;
