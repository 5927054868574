import { message } from 'antd';
import { ncoHttps } from '../../../utils/config';
import { getAttachmentsList } from '../commonActions';
import { getTaskDetails } from '../commonActions';
import moment from 'moment';
import { hideModal } from '../modalActions';
import { sendEmail } from '../commonActions';
export const saveCAPAImplementation = (formBody) => (dispatch) => {
  ncoHttps
    .post('/saveCapaImplementation.php/', formBody)
    .then(({ data }) => {
      dispatch({ type: 'CLEAR_ATTACHMENTS' });
      dispatch(
        getTaskDetails({ id: formBody.nco_no, capa_no: formBody.capa_no })
      );
      message.success('Saved Successfully');
    })
    .catch((err) => console.log(err));
};

export const retireCAPAInitiaton = (formData, history) => (dispatch) => {
  const mailObj = {
    email_type: 'capa_retire_initiated',
    action_by: formData.username,
    nco_no: formData.nco_no,
    capa_items: [formData.capa_no],
  };
  ncoHttps
    .post('/capa/retireCapaToVerifier.php', formData)
    .then(({ data }) => {
      sendEmail(mailObj);
      dispatch(
        hideModal({
          type: 'HIDE_MODAL',
          modalType: 'SIGNATURE_FORM',
          modalProps: { show: false },
        })
      );
      message.success('CAPA Retirement has been Initiated');
      history.push('/nco/capamonitoring');
    })
    .catch((err) => {
      console.log(err);
    });
  return;
};

export const retireCAPAAccept = (formData, history) => (dispatch) => {
  const mailObj = {
    email_type: 'capa_retired',
    action_by: formData.username,
    nco_no: formData.nco_no,
    capa_items: [formData.capa_no],
  };
  ncoHttps
    .post('/capa/retireCapa.php', formData)
    .then(({ data }) => {
      sendEmail(mailObj);

      history.push('/nco/capamonitoring');
      dispatch(
        hideModal({
          type: 'HIDE_MODAL',
          modalType: 'SIGNATURE_FORM',
          modalProps: { show: false },
        })
      );
      message.success('CAPA Retirement has been Accepted');
    })
    .catch((err) => console.log(err));
  return;
};

export const retireCAPAReject = (formData, history) => (dispatch) => {
  const mailObj = {
    email_type: 'capa_retire_rejected',
    action_by: formData.username,
    nco_no: formData.nco_no,
    capa_items: [formData.capa_no],
  };
  ncoHttps
    .post('/capa/rejectRetireCAPA.php', formData)
    .then(({ data }) => {
      history.push('/nco/capamonitoring');
      sendEmail(mailObj);
      dispatch(
        hideModal({
          type: 'HIDE_MODAL',
          modalType: 'SIGNATURE_FORM',
          modalProps: { show: false },
        })
      );
      message.success('CAPA Retirement has been Rejected');
    })
    .catch((err) => console.log(err));
  return;
};

export const getCAPAMonitorList = (type) => (dispatch) => {
  const role = localStorage.getItem('role');
  // const obj = JSON.stringify({
  //   organization: localStorage.getItem('organization'),
  // });
  console.log(type);
  const obj = JSON.stringify({
    stage: role,
    request_type: type ? 'all_request' : "my_request",
  });
  ncoHttps
    .post('/getcheckview.php', obj)
    .then(({ data }) => {
      const res = data;
      const sorted_data = res.sort((a, b) => {
        const dateA = new Date(a.periodic_date);
        const dateB = new Date(b.periodic_date);
        return dateB - dateA;
      });

      dispatch({ type: 'GET_CAPA_MONITOR', data: sorted_data });
    })
    .catch((err) => console.log(err));

  return;
};
export const getCAPARetireList = (type) => (dispatch) => {
  const role = localStorage.getItem('role');
  const obj = {
    request_type: type ? 'my_request' : 'all_request',
  };
  ncoHttps
    .post('/capa/getRetireCapaView.php', obj)
    .then(({ data }) => {
      const res = data;
      // const sorted_data = res.sort((a, b) => {
      //   const dateA = new Date(a.periodic_date);
      //   const dateB = new Date(b.periodic_date);
      //   return dateB - dateA;
      // });

      dispatch({ type: 'GET_CAPA_RETIRE', data: res });
    })
    .catch((err) => console.log(err));

  return;
};

export const checkInitiation = (formData, history) => (dispatch) => {
  const mailObj = {
    email_type: 'check_initiator',
    action_by: formData.username,
    nco_no: formData.nco_no,
    capa_items: [formData.capa_no],
    effectiveness_items: [
      {
        check_no: formData.check_no,
        version: formData.version,
        periodic_date: formData.periodic_date,
      },
    ],
  };
  ncoHttps
    .post('/initiatorcheck.php', formData)
    .then(({ data }) => {
      sendEmail(mailObj);

      history.push('/nco/capamonitoring');
      dispatch(
        hideModal({
          type: 'HIDE_MODAL',
          modalType: 'SIGNATURE_FORM',
          modalProps: { show: false },
        })
      );
      message.success('Check Initiation Completed');
    })
    .catch((err) => console.log(err));

  return;
};

export const checkVerification = (formData, history) => (dispatch) => {
  const mailObj = {
    email_type: 'check_verifier',
    action_by: formData.username,
    nco_no: formData.nco_no,
    capa_items: [formData.capa_no],
    effectiveness_items: [
      {
        check_no: formData.check_no,
        version: formData.version,
        periodic_date: formData.periodic_date,
      },
    ],
  };
  ncoHttps
    .post('capa/check.php', formData)
    .then(({ data }) => {
      sendEmail(mailObj);
      history.push('/nco/CAPAMonitoring');
      dispatch(
        hideModal({
          type: 'HIDE_MODAL',
          modalType: 'SIGNATURE_FORM',
          modalProps: { show: false },
        })
      );
      message.success('Check Verification Completed');
    })
    .catch((err) => console.log(err));

  return;
};

export const saveCheckItem = (formBody) => (dispatch) => {
  dispatch({ type: 'SET_LOADER', data: true });

  ncoHttps
    .post('/capa/saveCheckItem.php', JSON.stringify(formBody), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(async () => {
      message.success({
        content: 'Form saved Successful',
        key: 'updatable',
      });
      dispatch({ type: 'SET_LOADER', data: false });
      dispatch({ type: 'CLEAR_ATTACHMENTS' });
    })
    .catch((err) => {
      message.error('Error saving! Try again.');
      dispatch({ type: 'SET_LOADER', data: false });
      console.log(err);
    });
};

export const getActivityCAPAList = (obj) => (dispatch) => {
  ncoHttps
    .post('/getCapaImplementationActivity.php', obj)
    .then(({ data }) => {
      dispatch({ type: 'ACTIVITY_LIST', data });
    })
    .catch((err) => console.log(err));
  return;
};

export const getActivityEffectivenessList = (obj) => (dispatch) => {
  ncoHttps
    .post('/getEffectivenessActivity.php', obj)
    .then(({ data }) => {
      dispatch({ type: 'ACTIVITY_LIST', data });
    })
    .catch((err) => console.log(err));
  return;
};

export const getCAPAImplementationList = (type) => (dispatch) => {
  const username = localStorage.getItem('username');
  const name = localStorage.getItem('name');
  const nco_organisation = localStorage.getItem('organization');
  const module = localStorage.getItem('module');

  const obj = JSON.stringify({
    action_owner: `${name} (${username})`,
    nco_organisation,
    request_type: type ? 'my_request' : 'all_request',
  });
  ncoHttps
    .post('/getTasksView.php', obj)
    .then(({ data }) => {
      const res = Array.isArray(data) ? data : [];
      const sortedItems = [...res]?.sort((a, b) =>
        moment(b.assign_enddate).diff(moment(a.assign_enddate))
      );
      dispatch({
        type: 'GET_IMP_TASK_LIST',
        data: sortedItems,
      });
    })
    .catch(({ response }) => {
      //message.error(response.data[0].message);
      dispatch({
        type: 'GET_IMP_TASK_LIST',
        data: [],
        total: 0,
      });
    });
  return;
};

export const getImplementationList = (type) => (dispatch) => {
  console.log(type);
  ncoHttps
    .get(
      `/getCapaImplementationList.php?request_type=${
        type ? 'my_request' : 'all_request'
      }`
    )
    .then(({ data }) => {
      dispatch({ type: 'GET_IMPLEMENTATION_LIST', data: data });
    })
    .catch((err) => console.log(err));
  return;
};

export const changeImplementationAssignee = (formData) => (dispatch) => {
  ncoHttps
    .post('/changeAssigneeCapaImplementation.php', formData)
    .then((_) => {
      dispatch(
        hideModal({
          type: 'HIDE_MODAL',
          modalType: 'ASSIGN_TO',
          modalProps: { show: false },
        })
      );
      dispatch(getImplementationList(formData.req));
    })
    .catch((err) => console.log(err));
  return;
};
