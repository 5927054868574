import * as types from '../actions/modalActions';

const initialState = {
  modals: [],
};

const modals = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_MODAL:
      return {
        ...state,
        modals: [...state.modals, action.data],
      };
    case types.HIDE_MODAL:
      return {
        ...state,
        modals: state.modals?.filter(
          (modal) => modal.modalType !== action.data?.modalType
        ),
      };
    default:
      return { ...state };
  }
};

export default modals;
