import React, { useState, useEffect } from 'react';
import {
  Tooltip,
  Card,
  Statistic,
  Button,
  Descriptions,
  Space,
  Checkbox,
  Input,
  Switch,
  Avatar,
  List,
} from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import { RightCircleOutlined, CarOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import Btn from '../UtilityComponents/Btn';
import { getVEQuestions } from '../../../store/actions/vmm/vendorEvaluation';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getVEQuestion } from '../../../store/actions/vmm/vendorEvaluation';
const QuestionList = (props) => {
  const dispatch = useDispatch();
  const { activeSection } = props;
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    const fetchQuestions = async () => {
      const data = await getVEQuestions(props.activeSectionNo);
      if (props.activeSection.section_type == 'Multi Level Data') {
        setQuestions([
          {
            question_name: props.activeSection.section_name,
            question_id: 1,
          },
        ]);
      } else {
        setQuestions(data);
      }
    };
    if (props.activeSectionNo && props.activeSectionNo != 0) {
      fetchQuestions();
    }
  }, [props.activeSectionNo]);

  return (
    <div className="QuestionsList px-5 py-2 pb-5">
      <Card>
        <div className="h4 row mb-5">
          <span className="col-4 ">{activeSection.section_no}</span>

          <span className="col-4 text-center">
            {activeSection.section_name}
          </span>
          <span className="col-4 text-right">
            <Switch
              checkedChildren="Hide Answer"
              unCheckedChildren="Show Answer"
            />
          </span>
        </div>

        <List
          itemLayout="horizontal"
          dataSource={questions}
          renderItem={(item, index) => (
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar className="bg-success"> {index + 1}</Avatar>}
                title={
                  <div className="d-flex justify-content-between">
                    <div>{item.question_name}</div>
                    <Button
                      shape="circle"
                      type="link"
                      icon={
                        <RightCircleOutlined style={{ fontSize: '25px' }} />
                      }
                      onClick={() =>
                        dispatch(
                          getVEQuestion(props.activeSectionNo, item.question_id)
                        )
                      }
                    />
                  </div>
                }
                description={null}
              />
            </List.Item>
          )}
        />
      </Card>
    </div>
  );
};

const mapStateToProps = ({ QPRReducer }) => ({
  description: QPRReducer.description,
  paper_name: QPRReducer.paper_name,
  organization: QPRReducer.organization,
  paperId: QPRReducer.paperId,
  version: QPRReducer.version,
  activeSection: QPRReducer.activeSection,
  activeQuestion: QPRReducer.activeQuestion,
  activeSectionNo: QPRReducer.activeSectionNo,
});

export default connect(mapStateToProps)(QuestionList);
