import { MODULE } from '../../utils/constants';
import * as types from '../actions/initiationActions';

const initState = {
  cc_no: null,
  change_priorities: [],
  change_types: [],
  departments: [],
  projects: [],
  purposes: [],
  change_items: [],
  link_reasons: [],
  overall_risk: [],
  overall_impact: [],
  assignees: [],
  document_category: [],
  data: null,
  excelData: [],
  totalCount: 0,
  draft_data: null,
  draft_excelData: [],
  draft_totalCount: 0,
  details: null,
  ini_form: null,
  activityList: null,
  activityImpList: null,
  ini_mod_details: null,
  ini_details_error: false,
  ini_search: [],
  document_type: [],
  doc_no_list: [],
  doc_no_detail: {},
  formLoader: false,
  d_source: [],
  d_type: [],
  d_classification: [],
  risk_source: [],
  risk_type: [],
  risk_classification: [],
  act_item_notifi: [],
  rca_data: [],
  capa_data: [],
  ncoDropdownValues: [],
  actionItemDetails: [],
  actionOwner: [],
  rc_data: [],
  retire_data: [],
  effectiveness_data: [],
};

const initiation = (state = initState, action) => {
  const module = localStorage.getItem('module');
  console.log(action);
  let key = null;
  switch (action.type) {
    case types.SET_LOADER:
      return {
        ...state,
        formLoader: action.data,
      };

    case types.SET_DROPDOWN_VALUES:
      return {
        ...state,
        ...action.data,
      };
    case types.SET_NCO_DROPDOWN_VALUES:
      return {
        ...state,
        ncoDropdownValues: action.data,
      };
    case types.SET_PROJECT_VALUES:
      return {
        ...state,
        projects: action.data,
      };
    case types.SET_NCO_ACTION_OWNER:
      return {
        ...state,
        actionOwner: action.data,
      };
    case types.ACTION_ITEM_DETAILS:
      return {
        ...state,
        actionItemDetails: action.data[0],
      };

    case types.SET_RCA:
      return {
        ...state,
        rca_data: action.data,
      };

    case types.SET_CAPA:
      return {
        ...state,
        capa_data: action.data,
      };
    case types.GET_ACTION_ITEM_NOTIFICATION:
      return {
        ...state,
        act_item_notifi: action.data,
      };

    case types.ADD_ACTION_ITEM_NOTIFICATION:
      return {
        ...state,
      };

    case types.GET_INITIATION_DATA:
      return {
        ...state,
        data: action.data,
        excelData: action.excelData,
        totalCount: action.data.length,
      };
    case types.GET_EFFECTIVENESS_DATA:
      return {
        ...state,
        effectiveness_data: action.data,
      };

    case types.GET_RETIRE_DETAILS:
      return {
        ...state,
        retire_data: action.data,
      };
    case types.RESET_INITIATION_DATA:
      return {
        ...state,
        data: null,
        excelData: null,
        totalCount: null,
      };
    case types.GET_DRAFT_DATA:
      return {
        ...state,
        draft_data: action.data,
        draft_excelData: action.excelData,
        draft_totalCount: action.data.length,
      };

    case types.REMOVE_DRAFT_ROW:
      let key;
      if (module === MODULE.dnc) {
        key = 'd_id';
      } else if (module === MODULE.rm) {
        key = 'r_id';
      } else if (module === MODULE.nco) {
        key = 'nco_id';
      } else {
        key = 'cc_id';
      }
      console.log(key);
      const newData = state.draft_data.filter(
        (_) => (_.id || _[key]) !== action.data
      );
      return {
        ...state,
        draft_data: newData,
        draft_totalCount: newData.length,
      };

    case types.INITIATION_DETAILS:
      return {
        ...state,
        details: action.data,
      };

    case types.CLEAR_INITIATION_DETAILS:
      return {
        ...state,
        details: null,
      };

    case types.SET_RCA_ALONG_WITH_CAPA:
      return {
        ...state,
        rc_data: action.data,
      };

    case types.CLEAR_RCA_ALONG_WITH_CAPA:
      return {
        ...state,
        rc_data: [],
      };

    case types.INITIATION_FORM:
      return {
        ...state,
        ini_form: action.data,
      };

    case types.CLEAR_INITIATION_FORM:
      return {
        ...state,
        ini_form: null,
      };

    case types.UPDATE_ASSOCIATE_CHANGE:
      key =
        module === MODULE.dnc
          ? 'deviation_details'
          : module === MODULE.rm
          ? 'associated_risk'
          : 'associated_change_list';
      return {
        ...state,
        ini_form: {
          ...state.ini_form,
          [key]: state.ini_form[key].map((associate) =>
            associate.id === action.data.id
              ? { ...associate, ...action.data }
              : associate
          ),
        },
      };
    case types.UPDATE_PRODUCT_CHANGE:
      let keys = 'product_details'; // Move the declaration and assignment here
      return {
        ...state,
        details: {
          ...state.details,
          [keys]: state.details[keys].map((associate) =>
            associate.prod_id === action.data.prod_id
              ? { ...associate, ...action.data }
              : associate
          ),
        },
        ini_form: {
          ...state.ini_form,
          [keys]: state.ini_form[keys].map((associate) =>
            associate.prod_id === action.data.prod_id
              ? { ...associate, ...action.data }
              : associate
          ),
        },
      };

    case types.DELETE_ASSOCIATE_CHANGE:
      key =
        module === MODULE.dnc
          ? 'deviation_details'
          : module === MODULE.rm
          ? 'associated_risk'
          : 'associated_change_list';
      return {
        ...state,
        ini_form: {
          ...state.ini_form,
          [key]: state.ini_form[key].filter(
            (associate) => associate.prod_id !== action.id
          ),
        },
      };

    case types.DELETE_PRODUCT_CHANGE:
      let delete_prod = 'product_details';
      return {
        ...state,
        details: {
          ...state.details,
          [delete_prod]: state.details[delete_prod].filter(
            (associate) => associate.prod_id !== action.id
          ),
        },
        ini_form: {
          ...state.ini_form,
          [delete_prod]: state.ini_form[delete_prod].filter(
            (associate) => associate.prod_id !== action.id
          ),
        },
      };

    case types.ACTIVITY_LIST:
      return {
        ...state,
        activityList: action.data,
      };
    case types.ACTIVITY_IMPLIST:
      return {
        ...state,
        activityImpList: action.data,
      };
    case types.EDIT_LINK_CHANGE_REQUEST:
      key =
        module === MODULE.dnc
          ? 'link_deviation_requests'
          : module === MODULE.rm
          ? 'link_rr_requests'
          : 'link_change_requests';
      return {
        ...state,
        ini_form: {
          ...state.ini_form,
          [key]: state.ini_form[key].map((linkChange) =>
            linkChange.id === action.data.id
              ? { ...linkChange, ...action.data }
              : linkChange
          ),
        },
      };

    case types.DELETE_LINK_CHANGE_REQUEST:
      key =
        module === MODULE.dnc
          ? 'link_deviation_requests'
          : module === MODULE.rm
          ? 'link_rr_requests'
          : 'link_change_requests';
      return {
        ...state,
        ini_form: {
          ...state.ini_form,
          [key]: state.ini_form[key].filter(
            (linkChange) => linkChange.id !== action.id
          ),
        },
      };

    case types.UPDATE_LINK_REQUEST:
      return {
        ...state,
        details: {
          ...state.details,
          link_change_requests: state.details.link_change_requests.map((req) =>
            req.id === action.data.id ? { ...req, ...action.data } : req
          ),
        },
      };

    case types.DELETE_LINK_REQUEST:
      return {
        ...state,
        details: {
          ...state.details,
          link_deviation_requests: state.details.link_deviation_requests.filter(
            (req) => req.id !== action.id
          ),
        },
      };

    case types.NCO_DELETE_LINK_REQUEST:
      key = 'link_deviation_requests';
      return {
        ...state,
        details: {
          ...state.details,
          [key]: state.details[key].filter(
            (associate) => associate.id !== action.id
          ),
        },
      };
    case types.NCO_DELETE_LINK_CHANGE_REQUEST:
      let link_change_key = 'link_deviation_requests';

      return {
        ...state,
        ini_form: {
          ...state.ini_form,
          [link_change_key]: state.ini_form[link_change_key].filter(
            (linkChange) => linkChange.id !== action.id
          ),
        },
      };
    case types.NCO_UPDATE_LINK_CHANGE:
      let update_link_change_key = 'link_deviation_requests';
      return {
        ...state,
        details: {
          ...state.details,
          [update_link_change_key]: state.details[update_link_change_key].map(
            (associate) =>
              associate.id === action.data.id
                ? { ...associate, ...action.data }
                : associate
          ),
        },
      };
    case types.INI_MODAL_DETAILS:
      return {
        ...state,
        ini_mod_details: action.data,
      };

    case types.INI_DETAILS_ERR:
      return {
        ...state,
        ini_details_error: action.error,
      };

    case types.INI_SEARCH:
      return {
        ...state,
        ini_search: action.data,
      };

    case types.UPDATE_FUNCTIONAL_REVIEW:
      key =
        module === MODULE.dnc
          ? 'd_action_item'
          : module === MODULE.rm
          ? 're_action_id'
          : 'functional_review';
      return {
        ...state,
        details: {
          ...state.details,
          [key]: state.details[key].map((review) =>
            review.id === action.data.id
              ? { ...review, ...action.data }
              : review
          ),
        },
      };

    case types.DELETE_FUNC_REVIEW:
      key =
        module === MODULE.dnc
          ? 'd_action_item'
          : module === MODULE.rm
          ? 're_action_id'
          : 'functional_review';
      return {
        ...state,
        details: {
          ...state.details,
          [key]: state.details[key].filter((review) => review.id !== action.id),
        },
      };

    case types.DOC_NO_LIST:
      return {
        ...state,
        doc_no_list: action.data,
      };

    case types.DOC_NO_DETAIL:
      return {
        ...state,
        doc_no_detail: action.data,
      };

    case types.CLEAR_DOC_NO_LIST:
      return {
        ...state,
        doc_no_list: [],
      };

    case types.CLEAR_DOC_NO_DETAIL:
      return {
        ...state,
        doc_no_detail: {},
      };

    default:
      return state;
  }
};

export default initiation;
