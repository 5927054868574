const initState = {
  dashboardGlobalCount: [],
  dashboardGlobalData: [],
};

const ncoDashboardReducer = (state = initState, action) => {
  switch (action.type) {
    case 'SET_GLOBAL_DASHBOARD_COUNT':
      return {
        ...state,
        dashboardGlobalCount: action.data,
      };
    case 'SET_GLOBAL_DASHBOARD_DATA':
      return {
        ...state,
        dashboardGlobalData: action.data,
      };

    default:
      return state;
  }
};

export default ncoDashboardReducer;
