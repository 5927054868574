import * as types from '../actions/darkmodeAction';
const initState = {
  darkMode: false,
};

const darkModeReducer = (state = initState, action) => {
  switch (action.type) {
    case types.TOGGLE_DARK_MODE:
      return {
        ...state,
        darkMode: !state.darkMode,
      };
    default:
      return state;
  }
};

export default darkModeReducer;
