import React, { useEffect, Suspense, lazy } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { MODULE } from '../utils/constants';
import { Spin } from 'antd';
import '../styles/app.scss';
import './iControlDB';
import Intercom from '@intercom/messenger-js-sdk';
import VendorEvaluationLayout from '../vmm/components/VendorEvaluation/VendorEvaluationLayout';
import VendorQualificationDetails from '../vmm/components/Forms/VendorQualification/VendorQualificationDetails';
import CryptoJS from 'crypto-js';
import SSOLogin from './SSOLogin';
const AppLayout = lazy(() =>
  import('../components/LayoutComponents/AppLayout')
);
const MTFAppLayout = lazy(() =>
  import('../memoToFile/components/LayoutComponents/AppLayout')
);
const DNCAppLayout = lazy(() =>
  import('../dnc/components/LayoutComponents/AppLayout')
);
const RMAppLayout = lazy(() =>
  import('../rm/components/LayoutComponents/AppLayout')
);
const ECMAppLayout = lazy(() =>
  import('../ecm/components/LayoutComponents/AppLayout')
);
const CMMAppLayout = lazy(() =>
  import('../cmm/components/LayoutComponents/AppLayout')
);
const NCOAppLayout = lazy(() =>
  import('../nco/components/LayoutComponents/AppLayout')
);
const AmmAppLayout = lazy(() =>
  import('../amm/components/LayoutComponents/AppLayout')
);
const VmmAppLayout = lazy(() =>
  import('../vmm/components/LayoutComponents/AppLayout')
);
const VendorAppLayout = lazy(() =>
  import('../nco/components/Vendor/AppLayout')
);
const VendorVmmAppLayout = lazy(() =>
  import('../vmm/components/Vendor/AppLayout')
);
const PdmAppLayout = lazy(() => import('../pdm/LayoutComponents/AppLayout'));
const NotificationAppLayout = lazy(() => import('./Notification/AppLayout'));
const NotificationLogin = lazy(() => import('./NotificationLogin'));
const VendorLogin = lazy(() => import('./VendorLogin'));

const App = () => {
  const username = localStorage.getItem('username');
  const application = localStorage.getItem('application');
  const module = localStorage.getItem('module');
  const organization = localStorage.getItem('organization');
  const email = localStorage.getItem('email');
  const name = localStorage.getItem('name');
  const secretKey = '4kI6y4DwiEXQvvGVXeAkmFQRttaeJ-b_B49RYONf';

  // Combine the username, application, module, and organization for the user_id
  const userIdString = `${username} (${application}-${module}-${organization})`;
  const hash = CryptoJS.HmacSHA256(userIdString, secretKey).toString(
    CryptoJS.enc.Hex
  );
  Intercom({
    app_id: 'y1x9iyuv',
    user_id: userIdString,
    name: `${name} (${username})`,
    email: email,
    created_at: parseInt(localStorage.getItem('loginTimestamp')),
    user_hash: hash,
    Application: application,
    Module: module,
    Organization: organization,
    Mobile: localStorage.getItem('mobile') || '0000000000',
  });

  const isAuthenticated = localStorage.getItem('role');
  const currModule = localStorage.getItem('module');
  const notification = sessionStorage.getItem('access_token');
  const authState = useSelector((state) => state.auth.is_auth);
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.altKey) {
        event.preventDefault();
        const bullet = getBulletCharacter(event.key);
        if (bullet != '') {
          insertBullet(event.target, bullet);
        }
      }
    };

    const insertBullet = (textarea, bullet) => {
      console.log(textarea);
      const { selectionStart, selectionEnd, value } = textarea;
      const textBefore = value.substring(0, selectionStart);
      const textAfter = value.substring(selectionEnd);
      textarea.value = textBefore + bullet + ' ' + textAfter;
      const newPosition = selectionStart + bullet.length + 1;
      textarea.selectionStart = textarea.selectionEnd = newPosition;
      textarea.dispatchEvent(new Event('input', { bubbles: true }));
    };

    const getBulletCharacter = (key) => {
      const bulletMap = {
        z: '•',
        x: '■',
        c: '➢',
        v: '➤',
        b: '♦',
      };
      return bulletMap[key] || '';
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const clearAll = async () => {
    const dbs = await indexedDB.databases();
    for (const db of dbs) {
      const request = indexedDB.deleteDatabase(db.name);
      request.onsuccess = () => {
        console.log(`Deleted database: ${db.name}`);
      };
      request.onerror = (event) => {
        console.error(`Error deleting database: ${db.name}`, event);
      };
    }
    document.cookie.split(';').forEach((c) => {
      document.cookie = c
        .replace(/^ +/, '')
        .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
    });
  };

  useEffect(() => {
    const checkForUpdates = () => {
      const oldAppVersion = localStorage.getItem('appVersion') || null;
      const newAppVersion = process.env.REACT_APP_VERSION;
      const buildTime = process.env.REACT_APP_BUILD_TIME;

      if (oldAppVersion && newAppVersion && oldAppVersion !== newAppVersion) {
        localStorage.setItem('appVersion', newAppVersion);
        clearAll();
        window.location.reload();
      } else if (!oldAppVersion) {
        localStorage.setItem('appVersion', newAppVersion);
        clearAll();
        window.location.reload();
      }
    };
    if (process.env.NODE_ENV === 'production') {
      checkForUpdates();
    }
  }, []);

  return (
    <React.Fragment>
      <BrowserRouter>
        <Suspense
          fallback={
            <div className="vh-100 vw-100  d-flex justify-content-center align-items-center">
              <Spin size="large" />
            </div>
          }
        >
          <Switch>
            <Route path="/login" component={lazy(() => import('./Login'))} />
            <Route
              exact
              path="/"
              render={() =>
                authState ? (
                  currModule === MODULE.mtf ? (
                    <Redirect to="/mtf" />
                  ) : currModule === MODULE.dnc ? (
                    <Redirect to="/dnc" />
                  ) : currModule === MODULE.rm ? (
                    <Redirect to="/rm" />
                  ) : currModule === MODULE.ecm ? (
                    <Redirect to="/ecm" />
                  ) : currModule === MODULE.nco ? (
                    <Redirect to="/nco" />
                  ) : currModule === MODULE.amm ? (
                    <Redirect to="/amm" />
                  ) : currModule === MODULE.vmm ? (
                    <Redirect to="/vmm" />
                  ) : currModule === MODULE.cmm ? (
                    <Redirect to="/iControl" />
                  ) : isAuthenticated === 'vendor' ? (
                    <Redirect to="/vendor" />
                  ) : notification ? (
                    <Redirect to="/Notification" />
                  ) : (
                    <Redirect to="/iControl" />
                  )
                ) : (
                  <Redirect to="/login" />
                )
              }
            />
            <Route path="/NotificationLogin" component={NotificationLogin} />
            <Route path="/vendorLogin" component={VendorLogin} />
            <Route path="/ecm" component={ECMAppLayout} />
            <Route path="/nco" component={NCOAppLayout} />
            <Route path="/dnc" component={DNCAppLayout} />
            <Route path="/mtf" component={MTFAppLayout} />
            <Route path="/rm" component={RMAppLayout} />
            <Route path="/cmm" component={CMMAppLayout} />
            <Route path="/ccm" component={CMMAppLayout} />
            <Route path="/amm" component={AmmAppLayout} />
            <Route path="/vmm" component={VmmAppLayout} />
            <Route path="/pdm" component={PdmAppLayout} />

            <Route path="/iControl" component={AppLayout} />
            <Route path="/Notification" component={NotificationAppLayout} />
            <Route path="/Notification2" component={VendorEvaluationLayout} />
            <Route path="/sk" component={VendorVmmAppLayout} />
            <Route
              path="/vendorEvaluation"
              component={VendorEvaluationLayout}
            />
            <Route
              path="/VendorQualification"
              component={VendorQualificationDetails}
            />
            <Route path="/vendor" component={VendorAppLayout} />

            <Route path="/sso/:rt" component={SSOLogin} />

            <Redirect from="*" to="/login" />
          </Switch>
        </Suspense>
      </BrowserRouter>
    </React.Fragment>
  );
};

export default App;
