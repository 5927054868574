import axios from 'axios';
import { iaccessHttps2, ammHttps } from '../../../utils/config';

import { message } from 'antd';
export const SET_AMM_DROPDOWN_VALUES = 'SET_AMM_DROPDOWN_VALUES';
export const SET_AP_TABLE_INITIATOR = 'SET_AP_TABLE_INITIATOR';
export const SET_AP_TABLE_VERIFIER = 'SET_AP_TABLE_VERIFIER';
export const SET_AP_TABLE_APPROVER = 'SET_AP_TABLE_APPROVER';
export const CCM_CONFIG = 'CCM_CONFIG';

export const CCMConfig = () => (dispatch) => {
  try {
    const userToken = localStorage.getItem('access_token');

    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
    };
    const data = {
      type: 'global_config',
    };
    axios
      .post(`${process.env.REACT_APP_IACCESS_URL}/globalConfig/`, data, config)
      .then(({ data }) => {
        dispatch({ type: CCM_CONFIG, data });
      })
      .catch((error) => {
        console.error(error);
      });
  } catch (error) {
    console.error(error);
  }
};

export const getAmmDropdownValues = () => (dispatch) => {
  try {
    const userToken = localStorage.getItem('access_token');

    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
    };

    axios
      .post(
        `${process.env.REACT_APP_IACCESS_URL}/GetAMMFormDropdown/`,
        null,
        config
      )
      .then(({ data }) => {
        dispatch({ type: SET_AMM_DROPDOWN_VALUES, data });
      })
      .catch((error) => {
        console.error(error);
      });
  } catch (error) {
    console.error(error);
  }
};

export const setAuditPlanTableRequest = (data) => (dispatch) => {
  try {
    const role = localStorage.getItem('role');
    if (role === 'amm_initiator') {
      dispatch({ type: SET_AP_TABLE_INITIATOR, data: data });
    } else if (role === 'amm_verifier') {
      dispatch({ type: SET_AP_TABLE_VERIFIER, data: data });
    } else {
      dispatch({ type: SET_AP_TABLE_APPROVER, data: data });
    }
  } catch (error) {
    console.error(error);
  }
};

export const sendAMMEmail = (data) => (dispatch) => {
  try {
    const userToken = localStorage.getItem('access_token');
    const name = localStorage.getItem('name');
    const username = localStorage.getItem('username');
    const organization = localStorage.getItem('organization');

    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
    };
    const formBody = {
      ...data,
      action_by: `${name} (${username})`,
      organization: organization,
    };

    axios
      .post(
        `${process.env.REACT_APP_IACCESS_URL}/sendAMMEmail/`,
        formBody,
        config
      )
      .then(() => {})
      .catch((error) => {
        console.error(error);
      });
  } catch (error) {
    console.error(error);
  }
};
export const UploadAmmAttachment = (file, data) => (dispatch) => {
  console.log(file);

  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => formData.append(key, value));
  formData.append('uploaded_file', file);

  const userToken = localStorage.getItem('access_token');

  const config = {
    headers: {
      'content-type': 'multipart/form-data',
      Authorization: `Bearer ${userToken}`,
    },
  };

  axios
    .post(`${process.env.REACT_APP_AMM_URL}/UploadAttachment`, formData, config)
    .then(({ data }) => {
      message.success('Attachments Upload Successful');
    })
    .catch((err) => {
      console.log(err);
      message.error('Attachments Upload Failed! Try again.');
    });
};
