import { message } from 'antd';

import { hideModal } from './modalActions';
import { sendEmail } from './commonActions';
import { SET_NCO_DROPDOWN_VALUES } from './initiationActions';
import { getInitiationDetails } from './initiationActions';
import { getActionItemDetails } from './initiationActions';
import { getTaskDetails } from './commonActions';
import { fetch, ncoHttps } from '../../utils/config';
import axios from 'axios';
import moment from 'moment';
import { setVendorConfigurations } from '../../icontrolLogin/iControlDB';
export const SET_VENDOR_USER_PROFILE = 'SET_VENDOR_USER_PROFILE';
export const TOGGLE_BUTTON_LOADER = 'TOGGLE_BUTTON_LOADER';
export const DELETE_VENDOR_SUCCESS = 'DELETE_VENDOR_SUCCESS';
export const DELETE_VENDOR_FAILURE = 'DELETE_VENDOR_FAILURE';
export const SET_VENDOR_DATA_LIST = 'SET_VENDOR_DATA_LIST';
export const SET_VENDOR_USERS = 'SET_VENDOR_USERS';
export const SET_VENDOR_DATA = 'SET_VENDOR_DATA';
export const ACTION_ITEM_VENDOR_TABLE = 'ACTION_ITEM_VENDOR_TABLE';
export const SET_OTP_MAIL_DATA = 'SET_OTP_MAIL_DATA';
export const SET_VENDOR_SIGNATURE_DATA = 'SET_VENDOR_SIGNATURE_DATA';
export const CAPA_ITEM_VENDOR_TABLE = 'CAPA_ITEM_VENDOR_TABLE';

export const vendorAuth = (formbody, browserHistory) => async (dispatch) => {
  console.log('test');
  dispatch({ type: TOGGLE_BUTTON_LOADER, loading: true });
  try {
    const response = await fetch.post(`/vendor_login/`, formbody);
    const { data } = response;
    await dispatch({ type: TOGGLE_BUTTON_LOADER, loading: false });

    await localStorage.setItem('rt', data.refresh);
    await localStorage.setItem('access_token', data.access);
    await localStorage.setItem('role', 'vendor');
    await localStorage.setItem('username', data.username);
    await localStorage.setItem('email', data.email);
    await localStorage.setItem('module', data.module);
    await localStorage.setItem('application', 'iControl');
    await localStorage.setItem('organization', data.vendor_organization);
    await localStorage.setItem('name', data.name);
    await localStorage.setItem('mobile', data.vendor_mobile);
    await localStorage.setItem('username', data.username);
    await localStorage.setItem('designation', data.vendor_designation);
    await localStorage.setItem('loginTimestamp', moment().unix());
    await dispatch(getVendorProfile());
    await dispatch(
      setVendorConfigurations(
        data.username,
        data.module,
        data.organization,
        data.application,
        false
      )
    );
    message.success('Login Successful..');
    browserHistory.push('/vendor/basic');
  } catch (err) {
    message.error('Invalid Password');
    dispatch({ type: TOGGLE_BUTTON_LOADER, loading: false });
  }
};

export const getVendorProfile = () => (dispatch) => {
  fetch
    .post('/vendor_profile/')
    .then(({ data }) => {
      dispatch({
        type: SET_VENDOR_USER_PROFILE,
        data: { ...data },
      });

      localStorage.setItem('role', 'vendor');
      localStorage.setItem('username', data.username);
      localStorage.setItem('email', data.vendor_email);
      localStorage.setItem('module', data.module);
      localStorage.setItem('application', 'iControl');
      localStorage.setItem('organization', data.vendor_organization);
      localStorage.setItem(
        'name',
        `${data.vendor_first_name} ${data.vendor_last_name}`
      );
      localStorage.setItem('mobile', data.vendor_mobile);
      localStorage.setItem('username', data.username);

      localStorage.setItem('designation', data.vendor_designation);
      dispatch(
        getNcoVendorDropdownValues({
          application: data.application,
          module: data.module,
          organization: data.organization,
        })
      );
    })
    .catch(({ response }) => {
      // console.log(response);
      if (
        (response && response.statusText === 'Unauthorized') ||
        response.data.code === 'token_not_valid'
      ) {
        dispatch(vendor_logout());
      }
    });
};

export const getNcoVendorDropdownValues = (formBody) => (dispatch) => {
  try {
    const userToken = localStorage.getItem('access_token');

    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
    };

    axios
      .post(
        `${process.env.REACT_APP_IACCESS_URL}/GetVendorCAPAFormDropdown/`,
        formBody,
        config
      )
      .then(({ data }) => {
        dispatch({ type: SET_NCO_DROPDOWN_VALUES, data });
      })
      .catch((error) => {
        console.error(error);
      });
  } catch (error) {
    console.error(error);
  }
};

export const signature = (formbody, callback) => async () => {
  message.loading({ content: 'Loading...', key: 'updatable', duration: 60 });
  delete formbody.role;
  await fetch
    .post('/authenticate/', formbody)
    .then((res) => {
      console.log(res);
      if (res.data.authenticated) {
        message.success({ content: 'Signature Authorized!', key: 'updatable' });
        callback();
      } else {
        message.error({
          content: 'Signature Not Authorized!',
          key: 'updatable',
        });
        // callback();
      }
    })
    .catch((err) => {
      message.error(err.message);
    });
};

export const vendor_logout = () => () => {
  fetch
    .post(`${process.env.REACT_APP_IACCESS_URL}/vendor_logout/`)
    .then(({ data }) => {
      localStorage.clear();
      const keyToKeep = 'appVersion'; // Replace with the key you want to keep
      Object.keys(localStorage).forEach(
        (key) => key !== keyToKeep && localStorage.removeItem(key)
      );
      window.location.replace(process.env.REACT_APP_IACCESS_URL);
    })
    .catch((error) => {
      message.error('Error in updating Password');
    });
};

//import { SET_VENDOR_DATA } from '../actions/vendorActions';

export const addVendor = (formData, editVendor) => async (dispatch) => {
  try {
    const userToken = localStorage.getItem('access_token');
    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
    };

    // Make the API call to add the vendor
    const response = await axios.post(
      `${process.env.REACT_APP_IACCESS_URL}/addVendor/`,
      formData,
      config
    );

    // Dispatch actions synchronously
    dispatch({
      type: SET_VENDOR_DATA,
      data: response.data, // Assuming the API response contains the newly added vendor
    });

    dispatch(allVendorUsers());
    dispatch(allVendor());
    dispatch(
      hideModal({
        type: 'HIDE_MODAL',
        modalType: 'VENDOR_FORM',
        modalProps: { show: false },
      })
    );
  } catch (error) {
    console.error(error);
  }
};

export const viewVendor = (formBodyData, dataAction) => (dispatch) => {
  const userToken = localStorage.getItem('access_token');
  console.log(dataAction);

  const config = {
    headers: { Authorization: `Bearer ${userToken}` },
  };
  const formData = {
    organization: localStorage.getItem('organization'),
    application: localStorage.getItem('application'),
    module: localStorage.getItem('module'),
    username: formBodyData,
  };
  axios
    .post(`${process.env.REACT_APP_IACCESS_URL}/viewVendor/`, formData, config)
    .then(({ data }) => {
      if (dataAction == 'signature') {
        dispatch({
          type: SET_VENDOR_SIGNATURE_DATA,
          data: data,
        });
        console.log('yes');
      } else {
        dispatch({
          type: SET_VENDOR_DATA,
          data: data,
        });
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export const allVendor = () => (dispatch) => {
  const formData = {
    organization: localStorage.getItem('organization'),
    application: localStorage.getItem('application'),
    module: localStorage.getItem('module'),
  };

  const userToken = localStorage.getItem('access_token');
  const config = {
    headers: { Authorization: `Bearer ${userToken}` },
  };

  axios
    .post(
      `${process.env.REACT_APP_IACCESS_URL}/viewAllVendor/`,
      formData,
      config
    )
    .then(({ data }) => {
      dispatch({
        type: SET_VENDOR_DATA_LIST,
        data: data,
      });
    })
    .catch((error) => {
      console.error(error);
    });
};

export const updateVendor = (formBodyData) => async (dispatch) => {
  const userToken = localStorage.getItem('access_token');
  const config = {
    headers: { Authorization: `Bearer ${userToken}` },
  };
  const formData = {
    organization: localStorage.getItem('organization'),
    application: localStorage.getItem('application'),
    module: localStorage.getItem('module'),
    username: formBodyData.username,
  };
  console.log(formData);

  const response = fetch
    .put(
      `${process.env.REACT_APP_IACCESS_URL}/updateVendor/`,
      formBodyData,
      config
    )
    .then(({ data }) => {
      if (formBodyData.complete === 'complete') {
        dispatch(getVendorProfile());
        message.success('Welcome to iControl');
      }
      if (formBodyData.vendorProfile) {
        dispatch(getVendorProfile());
      }
      dispatch({
        type: SET_VENDOR_DATA,
        data: data,
      });
      dispatch(allVendor());
      dispatch(
        hideModal({
          type: 'HIDE_MODAL',
          modalType: 'VENDOR_FORM',
          modalProps: { show: false },
        })
      );
    })
    .catch((error) => {
      console.log(error);
    });

  //
};

export const deleteVendor = (formBodyData) => async (dispatch) => {
  const userToken = localStorage.getItem('access_token');
  const config = {
    headers: { Authorization: `Bearer ${userToken}` },
  };
  const formData = {
    organization: localStorage.getItem('organization'),
    application: localStorage.getItem('application'),
    module: localStorage.getItem('module'),
    username: formBodyData,
  };
  console.log(formData);

  // Make the DELETE request
  fetch
    .delete(
      `${process.env.REACT_APP_IACCESS_URL}/deleteVendor/`,
      { data: formData },
      config
    )
    .then(({ data }) => {
      dispatch({
        type: SET_VENDOR_DATA,
        data: data,
      });
      dispatch(allVendor());
    })
    .catch((error) => {
      console.log(error);
    });
};

export const allVendorUsers = () => (dispatch) => {
  const formData = {
    organization: localStorage.getItem('organization'),
    application: localStorage.getItem('application'),
    module: localStorage.getItem('module'),
  };

  const userToken = localStorage.getItem('access_token');
  const config = {
    headers: { Authorization: `Bearer ${userToken}` },
  };

  axios
    .post(`${process.env.REACT_APP_IACCESS_URL}/vendorUsers/`, formData, config)
    .then(({ data }) => {
      dispatch({
        type: SET_VENDOR_USERS,
        data: data,
      });
    })
    .catch((error) => {
      console.error(error);
    });
};

export const GenerateOTP = (vlswso) => (dispatch) => {
  console.log(vlswso);
  const formData = {
    vlswso: vlswso,
  };

  axios
    .post(`${process.env.REACT_APP_IACCESS_URL}/generate_otp/`, formData)
    .then(({ data }) => {
      dispatch({
        type: SET_OTP_MAIL_DATA,
        data: data.email_data,
      });
    })
    .catch((error) => {
      console.error(error);
    });
};

export const VendorUpdatePassword = (formData, closeModal) => (dispatch) => {
  fetch
    .post(
      `${process.env.REACT_APP_IACCESS_URL}/vendor_change_password/`,
      formData
    )
    .then(({ data }) => {
      message.success('Password Updated Successfully');
      closeModal();
    })
    .catch((error) => {
      message.error('Error in updating Password');
    });
};

export const action_owner_to_vendor = (formData, history) => (dispatch) => {
  const username = localStorage.getItem('username');

  const actionItem = [formData.action_id];
  const mailObj = {
    email_type: 'vendor_action_assignment',
    stage: 'Action Item Notification',
    stage_index: 1,
    action_items: [formData.action_id],
    action_by: username,
    nco_no: formData.nco_no,
  };
  const mailObj2 = {
    email_type: 'vendor_action_submission',
    stage: 'CAPA Plan Verification',
    stage_index: 2,
    action_items: [formData.action_id],
    action_by: username,
    nco_no: formData.nco_no,
    indicator: 'action_owner',
  };
  console.log(formData);
  ncoHttps
    .post(`/saveVendor.php`, formData)
    .then(() => {
      dispatch(
        hideModal({
          type: 'HIDE_MODAL',
          modalType: 'SIGNATURE_FORM',
          modalProps: { show: false },
        })
      );
      dispatch(
        getActionItemDetails({
          act_id: formData.action_id,
          nco_no: formData.nco_no,
        })
      );
      // if (formData.master) {
      //   // sendEmail(mailObj2);
      //   // history.push(`/vendor/action`);
      // } else {
      //   dispatch(
      //     getInitiationDetails({ nco_no: formData.nco_no, type: 'main' })
      //   );
      //   dispatch(
      //     getActionItemDetails({
      //       act_id: formData.action_id,
      //       nco_no: formData.nco_no,
      //     })
      //   );
      // }

      if (formData.master) {
        sendEmail(mailObj2);
        dispatch(
          getActionItemDetails({
            act_id: formData.action_id,
            nco_no: formData.nco_no,
          })
        );
      } else {
        sendEmail(mailObj);
        history.push(`/nco/RCAInvestigation/edit/${formData.nco_no}`);
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch(
        hideModal({
          type: 'HIDE_MODAL',
          modalType: 'SIGNATURE_FORM',
          modalProps: { show: false },
        })
      );
    });
};

export const action_item_table = (formData) => (dispatch) => {
  // const actionItem = [formData.action_id];
  // const mailObj = {
  //   email_type: 'nco_action_item_submit',
  //   stage: 'Action Item Notification',
  //   stage_index: 1,
  //   action_items: actionItem,
  //   action_by: localStorage.getItem('username'),
  //   nco_no: formData.nco_no,
  // };

  console.log(formData);
  ncoHttps
    .post(`/vendorActionItem.php`, formData)
    .then(({ data }) => {
      dispatch({
        type: ACTION_ITEM_VENDOR_TABLE,
        data: data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch(
        hideModal({
          type: 'HIDE_MODAL',
          modalType: 'SIGNATURE_FORM',
          modalProps: { show: false },
        })
      );
    });
};

export const capa_to_vendor = (formData, history) => (dispatch) => {
  const username = localStorage.getItem('username');
  const name = localStorage.getItem('name');
  console.log(formData);
  const actionItem = [formData.action_id];
  const mailObj = {
    email_type: 'vendor_capa_assignment',
    stage: 'Action Item Notification',
    stage_index: 1,
    capa_items: [formData.capa_no],
    action_by: username,
    nco_no: formData.nco_no,
    indicator: formData.indicator,
  };
  const mailObj2 = {
    email_type: 'vendor_capa_submission',
    stage: 'Action Item Notification',
    stage_index: 1,
    capa_items: [formData.capa_no],
    action_by: username,
    nco_no: formData.nco_no,
    indicator: formData.indicator,
  };
  console.log(formData);
  ncoHttps
    .post(`/vendorImplementation.php`, formData)
    .then(() => {
      dispatch(
        hideModal({
          type: 'HIDE_MODAL',
          modalType: 'SIGNATURE_FORM',
          modalProps: { show: false },
        })
      );

      dispatch(
        getTaskDetails({
          id: formData.nco_no,
          capa_no: formData.capa_no,
        })
      );

      // if (formData.mail) {
      // } else {
      if (formData.master) {
        sendEmail(mailObj2);
        // history.push(`/vendor/capa`);
      } else {
        sendEmail(mailObj);
        history.push(`/nco/implementation`);
      }
      // }
    })
    .catch((err) => {
      console.log(err);
      dispatch(
        hideModal({
          type: 'HIDE_MODAL',
          modalType: 'SIGNATURE_FORM',
          modalProps: { show: false },
        })
      );
    });
};

export const capa_item_table = (formData) => (dispatch) => {
  // const actionItem = [formData.action_id];
  // const mailObj = {
  //   email_type: 'nco_action_item_submit',
  //   stage: 'Action Item Notification',
  //   stage_index: 1,
  //   action_items: actionItem,
  //   action_by: localStorage.getItem('username'),
  //   nco_no: formData.nco_no,
  // };

  console.log(formData);
  ncoHttps
    .post(`capa/vendorCapaDetails.php`, formData)
    .then(({ data }) => {
      dispatch({
        type: CAPA_ITEM_VENDOR_TABLE,
        data: data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch(
        hideModal({
          type: 'HIDE_MODAL',
          modalType: 'SIGNATURE_FORM',
          modalProps: { show: false },
        })
      );
    });
};
