import * as types from '../actions/authActions';

const initState = {
  username: '',
  name: '',
  email: '',
  role: '',
  roles: [],
  application: '',
  organization: '',
  module: '',
  designation: null,
  display_picture: null,
  btn_loader: false,
  switch_application: [],
  DropdownData: [],
  Configuration: [],
  departmentList: [],
  OrganizationImage: null,
  is_auth: false,
  dueDates: null,
};

const auth = (state = initState, action) => {
  switch (action.type) {
    case types.SET_USER_PROFILE:
      return {
        ...state,
        ...action.data,
        role: action.data.role,
        username: action.data.username,
        name: action.data.name,
        roles: action.data.roles,
        organization: action.data.organization,
        module: action.data.module,
        switch_application: action.data.switch_application,
        is_auth: action.data.is_auth,
      };

    case types.TOGGLE_BUTTON_LOADER:
      return {
        ...state,
        btn_loader: action.loading,
      };
    case types.SET_USER_DATA:
      console.log(action.dueDates , "action.due dates da sk....");
      
      return {
        ...state,
        DropdownData: action.dropDown,
        Configuration: action.globalConfig,
        switch_application: action.switch_application,
        departmentList: action.department,
        dueDates: action.dueDates,
      };

    case types.SET_IMAGE_DATA:
      return {
        ...state,
        OrganizationImage: action.data,
      };

    default:
      return state;
  }
};

export default auth;
