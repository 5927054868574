import React, { useState, useEffect } from 'react';
import { Spin } from 'antd';
import { authLogin } from '../store/actions/authActions';
import { useDispatch } from 'react-redux';
const SSOLogin = (props) => {
  const dispatch = useDispatch();
  const id = props.match.params.rt;
  useEffect(() => {
    const data = { refresh: id };
    dispatch(authLogin(data, props.history));
  }, [id]);

  return (
    <div className="d-flex justify-content-center align-items-center vh-100 vw-100">
      <Spin />
    </div>
  );
};

export default SSOLogin;
