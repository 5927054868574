import React, { useState, useEffect } from 'react';
import { Card, Avatar, Space, Input } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { Button, Upload } from 'antd';
import Attachment from './Attachment';
import RenderSubQuestion from './RenderSubQuestion';
const SubInputs = (props) => {
  console.log(props);
  return (
    <div className="p-3 row multipleChoice">
      <div className="col-12">
        <Card>
          <div
            className="font-weight-bold h6 py-1 mr-1"
            style={{ color: '#333', whiteSpace: 'nowrap' }}
          >
            {props.question_no}
          </div>{' '}
          {props.question_name}
        </Card>
      </div>
      <div className="col-12">
        <Card className="mt-3">
          <div className="row">
            {props.sub_questions?.map((items, index) => (
              <RenderSubQuestion index={index} {...items} />
            ))}
          </div>
        </Card>
      </div>

      {props.comments && (
        <div className="col-12 d-flex mt-4">
          <Input.TextArea
            placeholder="Enter Your Comments (Non Mandatory)"
            autoSize={{ minRows: 4, maxRows: 6 }}
          />
        </div>
      )}
      {props.attachment && (
        <div className="col-12 mt-4">
          <Attachment {...props} />
        </div>
      )}
      {/* {props.sub_questions.map((item, index) => (
        // <RenderQuestion {...item} />
      ))} */}
    </div>
  );
};

export default SubInputs;
